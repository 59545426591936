import { getCommunity } from "api/communities"
import { useQuery } from "react-query"

export const useFetchCommunityWithId = ({ key, params, config }) => {
  const { data: Community, rest } = useQuery(
    ["communityByCreator", key ? key : params],
    () => getCommunity(params),
    {
      enabled: !!params,
      ...config,
    }
  )

  const accountStId =
    Community?.community.communityMerchantAccounts[0]?.accountId
  return {
    Community,
    accountStId,
    ...rest,
  }
}
