import { Link, NavLink, useLocation } from "react-router-dom"
import { MyHeader, SideBarContainer } from "./header.styles"
import * as paths from "paths"
import { BurgerMenu } from "components/icons/BurgerIcon"
import { useUser } from "context/user"
import React, { useState } from "react"
import { adminDropDownList, devices } from "constant"
import { useSettings } from "constant/DropDownList"
import { useMutation } from "react-query"
import { signOut } from "api/auth"
import { AccountInfoRightSide } from "pages/settings/accountSettings/RightSide"
import { useFetchUserProfile } from "api/users/useFetchUserProfile"
import roomLogoPlaceholder1 from "assets/pictures/roomLogoPlaceholder1.png"
import { RanttWhiteLogo } from "components/icons/RanttWhiteLogo"
import { useMedia } from "hooks/useMedia"

export default function Header() {
  const isMobile = useMedia(`${devices.mobileXl}`)

  const { user } = useUser()
  const [openSideBar, toggleSideBar] = useState(false)

  return (
    <>
      <MyHeader userId={user?.id}>
        <Link to={paths.welcomePage} className="flex flex-row">
          <RanttWhiteLogo width={isMobile ? 190 : 250} />
        </Link>
        {user && (
          <div className="right_side">
            <Link to={paths.profileSetting}>
              {user?.avatar?.uri?.length >= 1 ? (
                <img src={user?.avatar?.uri} alt={user?.displayName} />
              ) : (
                <img src={roomLogoPlaceholder1} alt="" />
              )}
            </Link>

            <BurgerMenu
              className="burger_menu_icon"
              fill="white"
              onClick={() => toggleSideBar(!openSideBar)}
            />
          </div>
        )}
      </MyHeader>
      {openSideBar && <SideBar closeSideBar={() => toggleSideBar(false)} />}
    </>
  )
}

const SideBar = (closeSideBar) => {
  const { setStatus, setUser } = useUser()
  const location = useLocation()
  const [accountSettingsOpen, toggleSettings] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"))

  const settingsOptions =
    user?.userRoleName === "rantt.creator" ||
    user?.userRoleName === "rantt.admin"
      ? adminDropDownList
      : useSettings

  useFetchUserProfile({
    config: {
      onSuccess: (data) => {
        setUser(data?.user)
      },
    },
  })

  const { mutate: singOutFn } = useMutation(signOut)

  return (
    <SideBarContainer>
      {!accountSettingsOpen ? (
        <ul>
          {settingsOptions?.map((setting) => (
            <li>
              {setting.title === "Account info" ? (
                <div className="account-info">
                  <NavLink
                    to={setting.value}
                    className={({ isActive }) =>
                      isActive ||
                      setting?.subUrls?.find(
                        (url) => url === location?.pathname
                      )
                        ? "active_link"
                        : ""
                    }
                    onClick={closeSideBar}
                  >
                    <span>Account info </span>
                  </NavLink>
                  <div
                    className="right-icon"
                    onClick={() => toggleSettings(true)}
                  >
                    &gt;
                  </div>
                </div>
              ) : (
                <NavLink
                  to={setting.value}
                  className={({ isActive }) =>
                    isActive ||
                    setting?.subUrls?.find((url) => url === location?.pathname)
                      ? "active_link"
                      : ""
                  }
                  onClick={closeSideBar}
                >
                  {setting.title}
                </NavLink>
              )}
            </li>
          ))}
          <button
            className="logout_button"
            onClick={() => {
              singOutFn()
              localStorage.removeItem("auth")
              localStorage.removeItem("user")
              setStatus("auth")
              window.location.href = "/"
            }}
          >
            Log out
          </button>
        </ul>
      ) : (
        <AccountInfoRightSide
          show={true}
          backFn={() => toggleSettings(false)}
        />
      )}
    </SideBarContainer>
  )
}
