import Footer from "components/common/Footer"
import { Link } from "react-router-dom"
import ranttLogo from "assets/pictures/rantt-black-logo.png"
import { ContactUsContainer } from "./ContactUsContainer.style"
import { Helmet } from "react-helmet-async"

export const Contact = () => {
  return (
    <ContactUsContainer>
      <Helmet>
        <title>Get in touch! | Rantt</title>
      </Helmet>
      {/* <Navbar /> */}
      {/* <!-- Nav bar --> */}
      <div className="navbar">
        <div className="container flex-nav">
          <Link to="/">
            <img className="logo" alt="" src={ranttLogo} />
          </Link>
          <nav>
            <ul className="nav-list">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </nav>
          <h1 className="page-header font-bold  ">Contact Us</h1>
        </div>
      </div>
      {/* Content */}
      <div className="container">
        <h2 className="page-subheader">Get in touch.</h2>
        <p>For press, support, or more info on Rantt.</p>
        <p>
          <b>Email:</b> <a href="mailto:beheard@rantt.com">beheard@rantt.com</a>
        </p>
      </div>
      {/* <Footer /> */}

      <Footer className="absolute bottom-0 left-0 right-0 justify-center" />
    </ContactUsContainer>
  )
}
