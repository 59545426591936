import { request, requestWithImage } from "./client"

const prefix = "/user"

export const getUserProfile = (data) =>
  request("post", `${prefix}/profile`, data)

export const getUserContent = (data) =>
  request("post", `${prefix}/profile/content`, data)

export const getFollowingList = (data) =>
  request("post", `${prefix}/profile/following`, data)

export const getFollowersList = (data) =>
  request("post", `${prefix}/profile/followers`, data)

export const updateUserPhoneNumber = (data) =>
  request("post", `${prefix}/profile/update-phone`, data)

export const verifyUserPhoneNumber = (data) =>
  request("post", `${prefix}/profile/verify-number`, data)

export const updateUserEmail = (data) =>
  request("post", `${prefix}/profile/update-email`, data)

export const updateUser = (data) =>
  request("post", `${prefix}/profile/update`, data)

export const updateUserAvatar = (data) =>
  requestWithImage("post", `${prefix}/profile/update-avatar`, data, true)

export const updateUserPassword = (data) =>
  request("post", `${prefix}/profile/update-password`, data)

export const promptUserToCreator = (data) =>
  request("post", `${prefix}/profile/prompt-user`, data)

export const sendEmailValidation = (data) =>
  request("post", `${prefix}/profile/send-email-validation`, data)

export const verifyUserEmailCode = (data) =>
  request("post", `${prefix}/profile/validate_email`, data)

export const resetUserPassword = (data) =>
  request("post", `${prefix}/profile/reset-password`, data)
