import * as React from "react"

export function Github(props) {
  return (
    <svg
      width={25}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.233 19.325c0 .099-.114.178-.258.178-.164.015-.278-.064-.278-.178 0-.1.114-.179.258-.179.15-.015.278.065.278.179zM6.69 19.1c-.035.1.064.214.213.244.13.05.278 0 .308-.1.03-.099-.065-.213-.214-.258-.129-.034-.273.015-.307.114zm2.193-.084c-.144.035-.243.13-.228.243.015.1.144.164.293.13.144-.035.243-.13.228-.229-.015-.094-.149-.159-.293-.144zM12.15 0C5.265 0 0 5.226 0 12.109c0 5.504 3.464 10.213 8.412 11.87.635.115.858-.277.858-.6 0-.307-.015-2.005-.015-3.047 0 0-3.473.745-4.203-1.479 0 0-.566-1.444-1.38-1.816 0 0-1.136-.78.08-.764 0 0 1.236.099 1.915 1.28 1.087 1.916 2.909 1.365 3.618 1.037.114-.794.437-1.345.794-1.672-2.774-.308-5.573-.71-5.573-5.484 0-1.365.377-2.05 1.171-2.923-.129-.323-.55-1.653.13-3.37 1.037-.322 3.424 1.34 3.424 1.34a11.66 11.66 0 013.116-.422c1.057 0 2.124.144 3.117.422 0 0 2.387-1.667 3.424-1.34.68 1.722.258 3.047.13 3.37.793.878 1.28 1.563 1.28 2.923 0 4.79-2.924 5.171-5.698 5.484.457.392.844 1.136.844 2.303 0 1.672-.015 3.741-.015 4.148 0 .323.228.715.859.6 4.962-1.647 8.327-6.356 8.327-11.86C24.615 5.226 19.032 0 12.149 0zM4.824 17.116c-.065.05-.05.164.034.258.08.08.194.115.259.05.064-.05.05-.164-.035-.258-.08-.08-.194-.114-.258-.05zm-.536-.402c-.035.065.015.144.114.194.08.05.179.035.213-.035.035-.064-.015-.144-.114-.193-.1-.03-.178-.015-.213.034zm1.608 1.767c-.08.065-.05.213.064.308.114.114.258.129.323.05.064-.065.035-.214-.065-.308-.109-.114-.258-.13-.322-.05zm-.566-.73c-.08.05-.08.18 0 .293.08.114.213.164.278.114.08-.064.08-.193 0-.307-.07-.114-.199-.164-.278-.1z"
        fill="#828282"
      />
    </svg>
  )
}
