import React from "react"
import ranttLogo from "assets/pictures/rantt-black-logo.png"
import { AboutPageContainer } from "./about.style"
import { Link } from "react-router-dom"
import Footer from "components/common/Footer"
import { Helmet } from "react-helmet-async"

function AboutPage() {
  return (
    <AboutPageContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Rantt</title>
        <meta name="twitter:title" content="About | Rantt" />
        <meta property="og:title" content="About | Rantt" />
      </Helmet>
      <div className="navbar">
        <div className="container flex-nav">
          {/* <div className="flex flex-row justify-between"> */}
          <Link to="/" className="mr-auto">
            <img className="logo" src={ranttLogo} alt="Rantt logo" />
          </Link>
          <nav>
            <ul className="nav-list">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
          {/* </div> */}

          <h1 className="page-header font-bold">About Rantt</h1>
        </div>
      </div>

      {/* Showcase banner */}
      <div className="container">
        <div className="page-body">
          <h2 className="page-subheader">Own your experience.</h2>
          <p>
            Rantt is a social media platform for private communities. Our tech
            empowers you to build communities of your own or join and support
            communities run by people you admire. With the familiar look and
            feel of a social network and the real-time vibes of a messaging app,
            Rantt offers a unique community experience that fosters meaningful
            conversation and authentic connection.
          </p>

          <p>
            We built Rantt to empower people to own their experience. Your
            social media experience doesn’t have to be plagued with division and
            chaos. It should be thriving with connection and community. You
            deserve a choice.
          </p>

          <p>
            At Rantt, you have the power to choose who you engage with, what you
            see, and how you support the platform. Choose the content you see in
            your feed. Choose what conversations and which people you want to
            follow. Choose who you support. Choose your community.
          </p>

          <p>
            If you choose to run a community of your own, you will have power
            over the culture of your community. Most social media platforms
            centralize all the key decisions with one all-powerful figure. While
            Rantt does have core guidelines to ensure the platform helps
            cultivate healthy conversations, the day-to-day moderation decisions
            are democratized to our community leaders.
          </p>

          <p>
            Whether you build your own community and use our moderation tools to
            create a safe space for your members or you join a community and
            customize your feeds, Rantt offers you ownership over your
            experience.
          </p>

          <p>
            <strong>
              Join Rantt and let’s build a better social network together.
            </strong>
          </p>
        </div>
      </div>

      {/* Footer */}

      <Footer />
    </AboutPageContainer>
  )
}

export default AboutPage
