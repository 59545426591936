import { devices } from "constant"
import styled, { css } from "styled-components"

const openedMobileRightPanel = css`
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 29;
`
export const RightSideContainer = styled.div`
  height: 100%;
  width: 35%;
  border-left: 5px solid ${({ theme }) => theme.backgroundGrey};
  padding-top: 3rem;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: absolute;
      left: 10px;
    }
  }
  .right_side_container {
    width: 90%;
    margin: 0 auto;
    h1 {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }
  @media ${devices.laptop} {
    min-width: 350px;
  }

  @media ${devices.tablet} {
    display: ${({ show }) => (show ? "" : "none")};
    ${({ show }) => show && openedMobileRightPanel}
  }
`
