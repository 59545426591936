import { devices } from "constant";
import styled from "styled-components";

export const BodyImageWrapper = styled.div`
  width: 50%;
  img {
    width: 100%;
  }
  @media ${devices.tablet} {
    width: 50%;
  }
  @media ${devices.tablet} {
    display: none;
  }
`;
