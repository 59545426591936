import React, { useState } from "react"
import moment from "moment"
import { useMutation, useQuery } from "react-query"
import { Button } from "components/common/Kit"
import { deleteSubscription, subscribe } from "api/subscriptions"
import { convertCentsToDollar } from "utils/convertCentsToDollar"
import { UpdatePlanContainer } from "../mangerSubscription.style"
import { getUserProfile } from "api/users"
import { SectionContainer } from "../sectionContainer"
import { useSelectPlan } from "./useSelectPlan"
import { useUpdateCard } from "../updateCard/useUpdateCard"
import { formatDate } from "utils/convertDate"
import { queryClient } from "app"

export const UpdatePlanSection = ({ selectedCommunity }) => {
  const [successMessage, setSuccessMessage] = useState()
  const { selectedPlan, serSelectedPlan, Community, accountStId } =
    useSelectPlan({ selectedCommunity })
  const user = JSON.parse(localStorage.getItem("user"))

  const { mutateAsync: deleteSubscriptionFn, isLoading: canclingLoading } =
    useMutation(deleteSubscription)

  const { mutateAsync: subscribeFn, isLoading: loadingSubscribe } = useMutation(
    subscribe,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listUserSubscriptions")
        setSuccessMessage("Your subscription has been updated successfully.")
        setTimeout(() => {
          setSuccessMessage(null)
        }, 4000)
      },
    }
  )
  const { lastFourDigitsCard } = useUpdateCard({
    selectedCommunity,
  })
  const isLoading = loadingSubscribe || canclingLoading

  const { data: profileDataResponse } = useQuery("userProfile", () =>
    getUserProfile({
      requestedUser: user?.userName,
    })
  )

  const updatePlane = async () => {
    const subscriptions = Community?.community?.subscriptions
    const customerId = selectedCommunity?.customerId
    const userEmail = profileDataResponse?.user?.contactInfo?.email

    deleteSubscriptionFn({
      communityId: selectedCommunity?.communityId,
      communityName: selectedCommunity?.communityName,
      isoCode: "en-US",
      userToCancel: JSON.parse(localStorage.getItem("user"))?.userName,
      stripeId: accountStId,
      customerId: selectedCommunity?.customerId,
      cancelImmediately: true,
      subscriptionId: selectedCommunity?.subscriptionReceipt,
    }).then(async (res) => {
      if (res) {
        await subscribeFn({
          customerId: customerId,
          emailAddress: userEmail,
          stripeId: accountStId,
          stripePriceId: selectedPlan?.subscriptionMetadata[0]?.priceId,
          subscriptionId: selectedPlan?.id,
          paymentMethodId: lastFourDigitsCard?.card.data[0]?.id,
          communityId: selectedCommunity?.communityId,
          isoCode: "en-US",
          isTrial: subscriptions?.find((item) => item.enableTrial === true)
            ? true
            : false,
          trialEnd: moment().add(1, "months").unix(),
          price: selectedPlan?.price,
        })
      }
    })
  }

  return (
    <SectionContainer title="Update your plan">
      <UpdatePlanContainer>
        <p>
          Your current plan will renew on{" "}
          {formatDate(selectedCommunity?.renews.seconds)}
        </p>
        <div className="plans_wrapper !flex-col ">
          {Community?.community?.subscriptions?.map((subscription) => (
            <div className="plan">
              <input
                type="checkbox"
                checked={selectedPlan === subscription}
                onChange={() => {
                  serSelectedPlan(subscription)
                }}
              />
              <p>
                {subscription?.name === "month" ? "Monthly" : "Annual"}
                <span>
                  {`$${convertCentsToDollar(subscription?.price)}/${
                    subscription?.name
                  }`}
                </span>
              </p>
            </div>
          ))}
        </div>
        <p className="update_plane_note">
          Cancel anytime before your renewal date to avoid being charged.
        </p>
      </UpdatePlanContainer>
      {successMessage && (
        <span className="block mt-4 text-green-600">{successMessage}</span>
      )}
      <Button
        className="save_changes_button"
        isLoading={isLoading}
        disabled={
          selectedCommunity?.subscriptionPeriod?.name === selectedPlan?.name
        }
        onClick={() => updatePlane()}
      >
        Save Changes
      </Button>
    </SectionContainer>
  )
}
