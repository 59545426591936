import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import BodyImage from "components/common/BodyImage"
import { MySignUp } from "./signup.styles.js"
import SignInInfo from "components/common/SigninInfo"
import { Spinner } from "components/common/Kit"
import * as authAPi from "api/auth"
import { useUser } from "context/user.jsx"
import { getDeviceName } from "utils/getDeviceName.js"
import useLocalStorage from "hooks/useLocalStorage.jsx"
import { Helmet } from "react-helmet-async"
import { FirstFormCreatorInformation } from "components/common/Auth/FirstFormCreatorInformation/index.jsx"
import { PhoneNumber } from "components/common/Auth/SendPhoneNumber/index.jsx"
import { VerifyPhoneNumber } from "components/common/Auth/VerifyPhoneNumber/index.jsx"

export default function SignUpForSubscribers({ back }) {
  const [step, setStep] = useState(1)

  const location = useLocation()

  const emailFromState = decodeURIComponent(
    location?.search?.split("=")[1].replace("%40", "@")
  )

  const methods = useForm({
    defaultValues: { userEmail: emailFromState, userName: undefined },
  })
  const { setValue, getValues } = methods

  const { userName } = getValues()

  const navigate = useNavigate()

  const { signIn, setUser, isAuthenticated } = useUser()
  const [phoneNumber, setPhoneNumber] = useState()
  const [, setUserLocalStorage] = useLocalStorage("user", null)

  const { mutateAsync: createUser, isLoading } = useMutation(
    authAPi.createUser,
    {
      onSuccess: (data) => {
        if (data?.errors?.length === 0) {
          setUser({ ...data, userName })
          setUserLocalStorage({ ...data, userName })
          signIn(data.validationToken)
          window.location.href = "/"
        }
      },
    }
  )

  var offset = new Date().getTimezoneOffset()

  const onSubmit = async (data) => {
    const { userName, userEmail, phoneNumber, userDisplayName, password } =
      getValues()

    if (step === 3) {
      const combinedData = {
        userCreate: {
          userName: userName,
          userEmail: userEmail,
          userPhoneNumber: phoneNumber,
          userDisplayName,
          userIsoCode: "en-US",
          userUtcOffset: offset,
        },
        password,
        signupTokenValue: data?.signupTokenValue,
        deviceName: getDeviceName(),
        deviceToken: "",
        deviceType: "web",
      }

      const results = await createUser(combinedData)

      if (results?.errors.length >= 1) {
        return methods.setError("signupTokenValue", {
          type: "manual",
          message: "validation token Invalid",
        })
      }
    }
  }

  useEffect(() => {
    methods.setValue("phoneNumber", phoneNumber)
  }, [methods, phoneNumber, setValue])

  if (isAuthenticated) navigate("/", { replace: true })

  return (
    <MySignUp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up for Rantt</title>
        <meta
          name="description"
          content="Sign up and join a rantt community."
        />
      </Helmet>
      <BodyImage />
      <div className="form-wrapper">
        <div
          style={{
            height: "10rem",
            width: "100%",
          }}
        >
          {methods.isSubmitting && <Spinner />}
        </div>
        <FormProvider {...methods}>
          {step === 1 && (
            <FirstFormCreatorInformation step={step} setStep={setStep} />
          )}
          {step === 2 && (
            <PhoneNumber
              step={step}
              setStep={setStep}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          )}
          {step === 3 && (
            <VerifyPhoneNumber
              step={step}
              phoneNumber={phoneNumber}
              setStep={setStep}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          )}
        </FormProvider>

        <SignInInfo
          toSignIn
          style={{
            width: "100%",
          }}
        />
      </div>
    </MySignUp>
  )
}
