import CircleSpinner from "../CircleSpinner"
import { CustomButton } from "./index.style"

const Button = ({ isLoading, children, ...props }) => {
  return (
    <CustomButton {...props}>
      {isLoading ? <CircleSpinner /> : children}
    </CustomButton>
  )
}

export default Button
