import { devices } from "constant";
import styled from "styled-components";

export const MyPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${devices.mobileXl} {
    display: none;
  }

  .page-title {
    font-weight: 600;
    font-size: 2, 5rem;
  }
  .subtitle {
    font-weight: normal;
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: ${({ theme }) => theme.addPostBorder};
  }
`;
