import { devices } from "constant"
import styled from "styled-components"

export const MyHeader = styled.div`
  padding: 0 2rem;
  padding-left: 10rem;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, userId }) => theme.linearGradient};
  border-bottom: ${({ theme }) => theme.addPostBorder};
  .logo {
    /* height: 6rem;
    width: 21rem; */

    width: 200px;
    height: 65.43px;
  }
  .right_side {
    display: none;
  }
  .side_bar {
    display: none;
  }
  .burger_menu_icon {
    display: none;
  }
  @media ${devices.mobileXl} {
    padding-left: 1rem;

    .right_side {
      display: flex;
      align-items: center;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    .logo {
      width: 17rem;
      height: 4.2rem;
    }
    .burger_menu_icon {
      display: block;
      fill: "white";
    }
    .side_bar {
      display: flex;
      background-color: red;
    }
  }
`

export const SideBarContainer = styled.div`
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 34;
  ul {
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    .logout_button {
      border: none;
      background: transparent;
      color: ${({ theme }) => theme.secondaryColor};
      padding: 14px 0;
      padding-left: 2rem;
      border: 0.5px solid #f6f6f6;
      width: 100%;
      text-align: left;
      font-size: 18px;
    }
  }
  li {
    font-size: 18px;
    list-style: none;
    border: 0.5px solid #f6f6f6;
    // padding: 18px 0;

    a {
      height: 100%;
      height: 100%;
      padding: 18px 0;
      padding-left: 2rem;
      display: block;
    }
    .active_link {
      border-left: 7px solid ${({ theme }) => theme.secondaryColor};
    }
    .account-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0;
      a {
        padding: 18px 0;
        padding-left: 2rem;
      }
      .right-icon {
        cursor: pointer;
        font-size: 24px;
        margin-right: 16px;
      }
    }
  }
`
