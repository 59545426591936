/* eslint-disable no-undef */
import axios from "axios"
import { getDeviceName } from "utils"

const API_KEY = process.env.REACT_APP_API_HOST

export const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: API_KEY,
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "include",
})

client.interceptors.response.use(
  function (response) {
    if (response.data) {
      // return success
      if (response.status === 200 || response.status === 201) {
        return response
      }
      // reject errors & warnings
      return Promise.reject(response)
    }

    // default fallback
    return Promise.reject(response)
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("auth")
      localStorage.removeItem("user")
    }
    // if the server throws an error (404, 500 etc.)
    return Promise.reject(error.response)
  }
)

export const request = async (method, url, inputData) => {
  const token = JSON.parse(localStorage.getItem("auth")) || ""
  const userAuthObj = {
    userAuthentication: {
      userName: JSON.parse(localStorage.getItem("user"))?.userName
        ? JSON.parse(localStorage.getItem("user"))?.userName
        : "",
      deviceName: getDeviceName(),
      validationToken: token ? token.access : "",
    },
  }
  if (method === "get") {
    const res = await client.get(url, { ...userAuthObj })
    return res?.data
  }
  if (method === "post") {
    const res = await client.post(url, { ...userAuthObj, ...inputData })
    return res?.data
  }
  if (method === "delete") {
    return client.delete()
  }
}

export const requestWithImage = async (method, url, data, image) => {
  if (method === "post") {
    const res = await client.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res?.data
  }
  if (method === "delete") {
    return client.delete()
  }
}

// Set config defaults when creating the instance

export const setHeaderToken = (token) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const setBusinessHeader = (id) => {
  client.defaults.headers.common["X-Business-Id"] = id
}

export const setAcceptLanguage = (language) => {
  client.defaults.headers.common["Accept-Language"] = language
}

export const removeHeader = () => {
  client.defaults.headers.common.Authorization = null
}

// let isRefreshing = false
// let failedQueue = []

// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error)
//     } else {
//       prom.resolve(token)
//     }
//   })

//   failedQueue = []
// }

// client.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     // Handle error
//     throw error
//   }
// )

// client.interceptors.response.use(
//   function (response) {
//     return response
//   },
//   function (error) {
//     return error

//     const originalRequest = error.config
//     if (error.response.status === 401 && !originalRequest._retry) {
//       localStorage.clear()
//     }
//   }
// )
