import { Outlet } from "react-router-dom"
import styled from "styled-components"
import Header from "components/common/Header"
import SideBar from "components/common/SideBar"
import { devices } from "constant"
import { Modal } from "context/modal"
import LogOutModal from "../LogoutModal"

export default function Layout() {
  return (
    <LayoutContainer>
      <Header />
      <main
        style={{
          display: "flex",
          height: "100%",
          overflow: "hidden",
        }}
        className="flexxxx"
      >
        {/* {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : ( */}
        <>
          <SideBarContainer>
            <SideBar />
          </SideBarContainer>
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
        </>
        {/* )} */}
      </main>
      <Modal id="logOutModal" component={LogOutModal} />
    </LayoutContainer>
  )
}

const OutletWrapper = styled.div`
  flex: 4;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  @media ${devices.tablet} {
    width: 100%;
  }
`

const SideBarContainer = styled.aside`
  border-right: 4px solid ${({ theme }) => theme.backgroundGrey};
`

const LayoutContainer = styled.div`
  height: calc(100vh - 10rem);
`
