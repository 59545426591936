import { SectionWrapper } from "../mangerSubscription.style"

export const SectionContainer = ({ children, title }) => {
  return (
    <SectionWrapper>
      <span className=" title">{title}</span>
      {children}
    </SectionWrapper>
  )
}
