import { request } from "./client"

const prefix = "/subscription"

export const getCreatorSubscription = (data) =>
  request("post", `${prefix}/all-subscriptions`, data)

export const createSubscription = (data) =>
  request("post", `${prefix}/create`, data)

export const updateSubscription = (data) =>
  request("post", `${prefix}/update`, data)

export const subscribe = (data) => request("post", `${prefix}/subscribe`, data)

export const getListUserSubscriptions = (data) =>
  request("post", `${prefix}/list-user-subscriptions`, data)

export const deleteSubscription = (data) =>
  request("post", `${prefix}/delete`, data)

export const updateuserCreditCard = (data) => {
  return request("post", `${prefix}/credit-card`, data)
}

export const cancelSubscription = async (data) =>
  request("post", `${prefix}/cancel`, data)
