import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

import {
  CommunitySettingContainer,
  ContentNav,
  ExistingMembersContainer,
  ListUserContainer,
  SearchContainer,
} from "./memberManagement.style"

import { Modal } from "context/modal"
import {
  CustomHeader,
  SettingWrapper as SettingPageWrapper,
} from "components/common"

import { Search } from "components/icons/Search"
import { Button, Spinner } from "components/common/Kit"

import { useMemberManagement } from "./hooks/useMemberManagement"
import { RemoveUserModal } from "./RemoveUserModal"
import { UserSettingCard } from "./userCard"
import useVisible from "hooks/useVisible"

const MemberManagement = () => {
  const [ref, isVisible] = useVisible()

  const [activeDropDown, setActiveDropDown] = useState(null)
  const [emails, setEmails] = useState()

  const {
    SubmitInvites,
    error,
    filterBySearch,
    successMessage,
    setError,
    setSuccessMessage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    listUsers,
    filteredList,
    isLoadingSendInvite,
  } = useMemberManagement({ emails })

  useEffect(() => {
    if (isVisible && hasNextPage) {
      fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, hasNextPage])

  return (
    <SettingPageWrapper pageTitle="Manage members">
      <Helmet>
        <title> Manage members | Rantt</title>
      </Helmet>
      <CustomHeader
        title="Manage members"
        description="Review and manage members of your community."
      />
      <CommunitySettingContainer>
        <div className="invite_section">
          <span className="title">Invite members for free</span>
          <p>Add by email (separate with commas)</p>
          <input
            type="text"
            placeholder="yourfriend@email.com"
            onChange={(e) => {
              setEmails(e.target.value)
              setError([])
              successMessage && setSuccessMessage(false)
            }}
          />
          {error?.length >= 1 &&
            error?.map((item) => (
              <span className="error_message">{item.error}</span>
            ))}
          {successMessage && (
            <span style={{ color: "green" }}>{successMessage}</span>
          )}
          <div>
            <Button
              onClick={() => SubmitInvites()}
              isLoading={isLoadingSendInvite}
            >
              Send invite
            </Button>
          </div>
        </div>
        <span className="title">Manage existing members</span>

        <ExistingMembersContainer>
          {/* for search for users */}
          <SearchContainer>
            <Search />
            <input
              placeholder="Search members..."
              onChange={(e) => filterBySearch(e)}
            />
          </SearchContainer>

          {/* table container */}
          <ContentNav>
            <span>Member</span>
            <span>Status</span>
            <span>Joined</span>
          </ContentNav>
          <ListUserContainer>
            {isLoading ? (
              <div className="flex items-center justify-center min-h-[250px]">
                <Spinner />
              </div>
            ) : (
              <>
                {listUsers &&
                  filteredList?.length > 0 &&
                  filteredList?.map((user, index) => {
                    return (
                      <>
                        <UserSettingCard
                          key={user?.userSummary?.userId + "ProfileSetting"}
                          activeDropDown={activeDropDown}
                          index={index}
                          setActiveDropDown={setActiveDropDown}
                          user={user}
                        />
                      </>
                    )
                  })}
              </>
            )}
            {/* content */}
          </ListUserContainer>
        </ExistingMembersContainer>

        <Modal id="remove_user" width={"500px"} component={RemoveUserModal} />
      </CommunitySettingContainer>
      <div ref={ref} />

      {isFetchingNextPage ? (
        <div className="flex items-center justify-center h-24">
          <Spinner />
        </div>
      ) : null}
    </SettingPageWrapper>
  )
}

export default MemberManagement
