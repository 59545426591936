import { useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"

import { Button, Input, Text } from "components/common/Kit"
import * as authAPi from "api/auth"
import { LeftArrow } from "components/icons"

const useFormValues = () => useFormContext()

export const VerifyPhoneNumber = ({
  onSubmit: onSubmitForm,
  phoneNumber,
  step,
  setStep,
  isLoading: submitLoading,
}) => {
  const navigate = useNavigate()

  const {
    mutateAsync: generateSignupToken,
    isLoading: loadingGenerateSignupToken,
  } = useMutation(authAPi.generateSignupToken)

  const isLoading = submitLoading || loadingGenerateSignupToken

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useFormValues()

  const PHONE_NUMBER = phoneNumber

  const requestNewValidationToken = () => {
    generateSignupToken({
      phoneNumber: PHONE_NUMBER,
      isoCode: "en-US",
    })
  }

  const onSubmit = () => {
    onSubmitForm({
      signupTokenValue: getValues("signupTokenValue"),
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="form-title">
          <LeftArrow
            className="left-arrow"
            onClick={() =>
              step === 1 ? navigate(-1) : setStep((step) => step - 1)
            }
          />
          Enter Confirmation Code
        </h3>
        <label htmlFor="signupTokenValue">
          Enter the 6-digit code we sent to the phone number ending in{" "}
          {PHONE_NUMBER?.slice(PHONE_NUMBER?.length - 4)}.
          <span
            className="ml-2 request-code"
            onClick={requestNewValidationToken}
          >
            Request a new one.
          </span>
        </label>
        <Input
          {...register("signupTokenValue", {
            required: {
              value: true,
              message: "confirmation code is required",
            },
          })}
          placeholder="XXXX-XXXX"
          style={{ marginTop: 13, textAlign: "center" }}
        />
        {errors?.signupTokenValue && (
          <Text>{errors?.signupTokenValue.message}</Text>
        )}
        <Button
          isLoading={isLoading}
          style={{
            marginTop: "2rem",
          }}
          type="submit"
        >
          Confirm
        </Button>
      </form>
    </>
  )
}
