export function createFormData(data) {
  const formData = new FormData()

  for (let key in data) {
    if (data[key].constructor === Array) {
      for (let i = 0; i < data[key].length; i++) {
        formData.append(key, data[key][i])
      }
    } else {
      formData.append(key, data[key])
    }
  }

  return formData
}
