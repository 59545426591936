import { createContext, useState, useContext } from "react";

const panelContext = createContext(null);

export function Provider({ children }) {
  const [activeTab, setActiveTab] = useState("profile");
  const [rightPanelRoutes, addRightPanelRoutes] = useState([]);
  const [props, setProps] = useState();

  const activeTabWithProps =
    rightPanelRoutes.length >= 1
      ? rightPanelRoutes[rightPanelRoutes.length - 1]
      : [];

  const switchTab = (tabName, props) => {
    setProps(props);
    addRightPanelRoutes([
      ...rightPanelRoutes,
      {
        tabName,
        id: rightPanelRoutes[rightPanelRoutes.length - 1]
          ? rightPanelRoutes[rightPanelRoutes.length - 1].id + 1
          : 1,
        props: props,
      },
    ]);
    setActiveTab(tabName);
  };

  const backInRightPanelFunc = (props) => {
    if (rightPanelRoutes.length >= 1) {
      rightPanelRoutes.pop();
      if (rightPanelRoutes.length >= 1) {
        setActiveTab(rightPanelRoutes[rightPanelRoutes.length - 1]?.tabName);
        addRightPanelRoutes(rightPanelRoutes);
        return setProps(rightPanelRoutes[rightPanelRoutes.length - 1]);
      }
      setActiveTab("profile");
    } else {
      setActiveTab("profile");
    }
  };

  return (
    <panelContext.Provider
      value={{
        activeTab,
        switchTab,
        props,
        backInRightPanelFunc,
        activeTabWithProps,
      }}
    >
      {children}
    </panelContext.Provider>
  );
}
export const useRightPanel = () => useContext(panelContext);
