import React, { useState } from "react"
import { useMutation } from "react-query"
import { useForm } from "react-hook-form"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { subscribe } from "api/subscriptions"
import { FIELD_ERROR } from "constant/formText"
import { EMAIL_REGEX_PATTERN } from "utils"
import { Button, Text } from "components/common/Kit"
import * as paths from "paths"
import { validateUserInfo } from "api/auth"
import subscribePaymentImage from "assets/pictures/subscribe-payment.png"
import { FormElementsStyle, InputsWrapper } from "./formElements.style"
import { convertCentsToDollar } from "utils/convertCentsToDollar"

export function FormElements({
  selectedPlan,
  subscriptions,
  communityId,
  accountStId,
}) {
  const navigate = useNavigate()
  const [beError, setBeError] = useState()
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm()
  // const stripe = useStripe()
  const elements = useElements()

  const { mutateAsync: verifyUserInfo, isLoading: isLoadingVerification } =
    useMutation(validateUserInfo, { onSuccess: () => {} })

  const { mutateAsync, isLoading: isLoadingSubscribeToCommunity } = useMutation(
    subscribe,
    {
      onSuccess: () => {},
    }
  )
  const stripe = useStripe()
  const isLoading = isLoadingSubscribeToCommunity || isLoadingVerification

  const isTrial = subscriptions?.find((item) => item.enableTrial === true)
    ? true
    : false
  const monthPrice = convertCentsToDollar(
    subscriptions?.find((item) => item?.name === "month")?.price
  )
  const yearlyPrice = convertCentsToDollar(
    subscriptions?.find((item) => item?.name === "year")?.price
  )

  const onSubmit = async (data) => {
    const isValidEmail = await verifyUserInfo({
      userEmail: data?.email,
    })
    const isEmailInUse = isValidEmail?.userEmailInUse
    const isEmailValid = isValidEmail?.userEmailValid

    // if (!isEmailInUse) {
    //   return setError("email", { message: "Email is already in use" })
    // }
    if (!isEmailValid && !isEmailInUse) {
      return setError("email", { message: "Email is not valid" })
    }

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      const sepaPayment = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: data?.email, //collect this from user
        },
      })

      setBeError(sepaPayment?.error?.message)

      const subscribeResponse = await mutateAsync({
        stripeId: accountStId,
        price: selectedPlan?.price,
        stripePriceId: selectedPlan?.subscriptionMetadata[0]?.priceId,
        communityId: communityId,
        subscriptionId: selectedPlan?.id,
        paymentMethodId: sepaPayment.paymentMethod.id,
        emailAddress: data?.email,
        isoCode: "en-US",
        isTrial,
        trialEnd: moment().add(1, "months").unix(),
      })

      const emailToUrlSplited = data?.email?.split("@")

      if (subscribeResponse?.success) {
        if (subscribeResponse?.userExists === false) {
          navigate(
            `${paths.SignUpForSubscribers}/?e=${
              emailToUrlSplited[0] + "%40" + emailToUrlSplited[1]
            }`
          )
        } else {
          navigate("/")
        }
      }
    } catch (err) {
      const errorMessage = err?.data?.message
      setBeError(errorMessage ? errorMessage : "something went wrong")
    }
  }

  // TODO:
  //validate the  email with Greg APi

  return (
    <div>
      <FormElementsStyle onSubmit={handleSubmit(onSubmit)} className=" w-full">
        <InputsWrapper className="form-inputs-warper border rounded-md">
          <fieldset className="border ">
            <input
              name="email"
              placeholder="example@email.com"
              className="email border-b"
              {...register("email", {
                required: { message: FIELD_ERROR, value: true },
                pattern: {
                  value: EMAIL_REGEX_PATTERN,
                  message: "Email format is invalid",
                },
              })}
            />
            {errors?.email && <Text>{errors?.email?.message} </Text>}
          </fieldset>

          <fieldset className="mt-4 card_number border block">
            <CardElement style={{ height: 20 }} className="card" />
          </fieldset>
          {beError && (
            <Text className="mt-2 text-red-500 error-message">{beError} </Text>
          )}
        </InputsWrapper>
        <p
          id="redirect_message"
          className="redirect_message font-normal  my-5 leading-[20px] text-2xl"
        >
          After subscribing, you’ll be taken to the account creation screen and
          gain access to this community.
        </p>
        <Button
          isLoading={isLoading}
          type="submit"
          className="submit_button mb-1"
        >
          {isTrial ? "Start Free Trial" : "Subscribe"}
        </Button>
      </FormElementsStyle>
      <img
        src={subscribePaymentImage}
        alt="you can pay Rantt with  safe and secure payments"
        className="my-7 w-[380px] mx-auto"
      />
      {isTrial ? (
        <p className="note_about_subscription text-center  ">
          Your trial period will end on{" "}
          {moment().add(1, "months").format("MMMM  DD  yyyy")}. After which, you
          will be charged $
          {selectedPlan?.name === "month" ? monthPrice : yearlyPrice} a{" "}
          {selectedPlan?.name === "month" ? "month" : "year"}. If you cancel
          before your trial period ends you won’t be charged.
        </p>
      ) : null}
    </div>
  )
}
