import styled from "styled-components"

export const ImageUploadContainer = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  input[type="file"] {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
  svg {
    position: absolute;
    z-index: 33;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
