import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { FormElements } from "../FormElements"

export const StripeComponent = ({
  selectedPlan,
  subscriptions,
  communityId,
  community,
}) => {
  const accountStId = community?.communityMerchantAccounts[0]?.accountId

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUPLIC_KEY, {
    stripeAccount: accountStId,
  })

  return (
    <>
      {accountStId && (
        <Elements stripe={stripePromise}>
          <FormElements
            selectedPlan={selectedPlan}
            communityId={communityId}
            subscriptions={subscriptions}
            accountStId={accountStId}
          />
        </Elements>
      )}
    </>
  )
}
