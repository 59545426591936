import { createContext, useState, useContext } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const context = createContext()
const modalNode = document.getElementById("modal")

export function Provider({ children }) {
  const [modal, setModal] = useState(null)

  return (
    <context.Provider value={{ modal, setModal }}>{children}</context.Provider>
  )
}

export const useModal = () => {
  const { setModal } = useContext(context)

  return {
    open: (id, params = {}) => setModal({ id, params }),
    close: () => setModal(null),
  }
}

export function Modal({ id, size = "lg", width = null, component: Component }) {
  const { modal, setModal } = useContext(context)
  const { close } = useModal()

  return (
    modal?.id === id &&
    createPortal(
      <ModalWrapper onClick={() => setModal(null)} width={width}>
        <div className="overlay"></div>
        <div className="component-wrapper">
          <div className="component" onClick={(e) => e.stopPropagation()}>
            <Component close={close} params={modal.params} />
          </div>
        </div>
      </ModalWrapper>,
      modalNode
    )
  )
}

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;

  .overlay {
    position: fixed;
    background-color: #00000040;
    min-height: 100vh;
    width: 100%;
  }

  .component-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
    text-align: center;

    .component {
      background-color: white;
      border-radius: 1.2rem;
      text-align: left;
      width: 100%;
      max-width: ${({ width }) => (width ? width : "50%")};
      overflow: hidden;
    }
  }
`
