/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { EarningCalculatorWrapper } from "../subscriptionSettings.style"
import { RightSideContainer } from "components/common/shared/RightSideWrapper/rightSide.style"

export const RightSide = () => {
  const [price, setPrice] = useState()
  const [count, setCount] = useState()
  const [percentage, setPercentage] = useState(0)

  const [res, setRes] = useState(0)

  const updateWithRange = (percentageFromProps) => {
    const ranttFee = 0.1
    const stripeFeePercentage = 0.029
    const stripeFeeFlat = 0.3
    const perc = percentageFromProps ? percentageFromProps : percentage

    const monthlyEarnings =
      (count *
        (price -
          (price * ranttFee + (price * stripeFeePercentage + stripeFeeFlat))) *
        perc) /
      100

    setRes(monthlyEarnings.toFixed(2))
  }

  useEffect(() => {
    updateWithRange()
  }, [count, price])

  return (
    <RightSideContainer>
      <div className="right_side_container"></div>
      <h1>Earnings calculator</h1>
      <EarningCalculatorWrapper className="max-w-[400px]">
        <h3 className="title , text-2xl text-center  mx-auto">
          Estimate your potential earnings
        </h3>
        <div className="earning_calculator_price">
          <span className="text-2xl font-medium price">
            ${isNaN(res) ? "0" : financial(res)}
          </span>
          <p>per month</p>
        </div>
        <h3 className="title">Enter your total amount of followers: </h3>
        <div className="price_inputs">
          <fieldset>
            <label>Follower count</label>
            <div className="input_wrapper">
              <input
                type="number"
                placeholder={"100,000,000"}
                onChange={(e) => setCount(e.target.value)}
                className="w-full rounded-md"
              />
            </div>
          </fieldset>
          <h3 className="title">Enter your monthly subscription price: </h3>
          <fieldset>
            <label>Subscription price</label>
            <div className="bg-white input_wrapper">
              <input
                placeholder={"100.00"}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                className="border border-r rounded-md subscription_price_input"
              />
              <span className="block h-full p-0 ">USD</span>
            </div>
          </fieldset>
        </div>
        <div className="input_range_wrapper mt-0 ">
          <div style={{ position: "relative" }}>
            <label
              htmlFor="percentage"
              id="percentage-label"
              style={{
                position: "absolute",
                top: "-0px",
                left: `${percentage}%`,
                transform:
                  percentage === 0 ? "translateX(0%)" : "translateX(-50%)",
              }}
              className="bg-white rounded-xl  px-2"
            >
              {percentage}%
            </label>
            <input
              type="range"
              id="percentage"
              name="percentage"
              min="1"
              max="100"
              step="1"
              defaultValue="0"
              className="input_range_wrapper"
              onChange={(e) => {
                const percentage = e?.target?.value
                setPercentage(percentage)
                updateWithRange(percentage)
              }}
            />
          </div>

          <p className="text-xl">
            Estimates based on monthly subscription price after fees.
          </p>
        </div>
      </EarningCalculatorWrapper>
    </RightSideContainer>
  )
}

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}
