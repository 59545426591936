import { request } from "./client"

const prefix = "/invitation"

export const sendInvite = (data) => request("post", `${prefix}/send`, data)

export const checkInvitation = (data) =>
  request("post", `${prefix}/check-invite`, data)

export const acceptInvite = (data) =>
  request("post", `${prefix}/accept-invite`, data)
