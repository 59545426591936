import { Options } from "components/icons"

import { UserPlaceHolder } from "components/icons/UserPlaceHolder"
import { useModal } from "context/modal"
import { useUser } from "context/user"
import useClickOutside from "hooks/useClickOutside"
import React from "react"

import { formatDate } from "utils/convertDate"
import { DropDownWrapper } from "../memberManagement.style"

export const UserSettingCard = ({
  index,
  user,
  setActiveDropDown,
  activeDropDown,
}) => {
  const { currentCommunity } = useUser()

  const { open } = useModal()

  const { ref, isVisible, setIsVisible } = useClickOutside()

  const openModal = (user, communityId, active) => {
    open("remove_user", { ...user, communityId, active })
  }

  return (
    <div className="profile">
      <div className="user_profile">
        {" "}
        <div className="user-info">
          <div className="flex  flex-row md:mr-5">
            {user?.userSummary?.userAvatar?.uri?.length > 1 ? (
              <img src={user?.userSummary?.userAvatar?.uri} alt="" />
            ) : (
              <UserPlaceHolder className="hidden m md:block" />
            )}
          </div>

          <div className="flex flex-col items-start">
            <span>{user?.userSummary.userDisplayName}</span>{" "}
            <span>{user?.userSummary?.userName}</span>
          </div>
        </div>
        <span>{user?.Status}</span>{" "}
        <span>{formatDate(user?.Joined?.seconds)}</span>
      </div>
      <div className="option_button">
        <Options
          onClick={() => {
            setActiveDropDown(index)
            setIsVisible(true)
          }}
        />
        {activeDropDown === index && isVisible && (
          <DropDown
            ref={ref}
            handleSelect={() => setActiveDropDown(false)}
            openModalFn={(active) =>
              openModal(user, currentCommunity.id, active)
            }
            user={user}
          />
        )}
      </div>
    </div>
  )
}

const DropDown = React.forwardRef(({ data, openModalFn, userId }, ref) => {
  return (
    <DropDownWrapper ref={ref}>
      <button onClick={() => openModalFn("remove")}>
        Remove from community
      </button>
      <button onClick={() => openModalFn("ban")}>Ban from community</button>
    </DropDownWrapper>
  )
})
