import * as React from "react"

function RedValid(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M11.76 23.52c1.609 0 3.123-.308 4.544-.925a11.739 11.739 0 003.743-2.538 12.077 12.077 0 002.548-3.745c.617-1.422.925-2.94.925-4.558 0-1.609-.308-3.12-.925-4.534a12.04 12.04 0 00-2.548-3.757A11.706 11.706 0 0016.293.925 11.287 11.287 0 0011.749 0C10.14 0 8.626.308 7.205.925A11.873 11.873 0 003.45 3.463 12.135 12.135 0 00.913 7.22C.304 8.633 0 10.145 0 11.754c0 1.617.304 3.136.913 4.558a12.075 12.075 0 002.548 3.745 11.87 11.87 0 003.755 2.538c1.42.617 2.935.925 4.544.925zm-1.252-6.17c-.233 0-.443-.05-.63-.147a1.81 1.81 0 01-.53-.474L6.63 13.458a2.252 2.252 0 01-.248-.418 1.263 1.263 0 01-.08-.44c0-.316.11-.582.328-.8a1.07 1.07 0 01.8-.339c.188 0 .357.041.508.124.15.075.3.207.45.395l2.087 2.617 4.543-7.265c.271-.428.606-.643 1.004-.643.3 0 .568.098.8.294.241.195.361.45.361.767 0 .143-.03.286-.09.428-.052.143-.12.279-.203.407l-5.265 8.144c-.136.196-.301.35-.496.463a1.248 1.248 0 01-.62.157z"
        fill="url(#prefix__pattern0)"
      />
      <defs>
        <pattern
          id="prefix__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use
            xlinkHref="#prefix__image0_1828_1551"
            transform="scale(.02222)"
          />
        </pattern>
        <image
          id="prefix__image0_1828_1551"
          width={45}
          height={45}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAACXBIWXMAAC4jAAAuIwF4pT92AAAClUlEQVRYhc2YUY6DMAxE7aiH26vt0fZSeD9IiT0eB7paQZAQaSBm/Gqno+rP17dJeZhov+7new7H+6nqxnBlcyob3NvGPdvCMyrbMW4zwfHqk0Dx4ubjOM4ZnDFxPZ41d/WJy5loCQLji/3RAyoKipQxRhQCCdkssVI0p+zHmoRgon6OEUYx/bNV5TUSK0nXlAnpU8oSYtSURVBgTkCY6DPK8ifKTGigbKxRo/gp6b9QZrsEo1kKUum1jAnlpgTRn1C2guxYhy/24lNjGiaMz465V35pFF5S1jGfG43vMEpjntVwvrYYGA9sJBfY8vNezDXKPlZVw5LiBdKcMklIx3y9F0cBnLKI0C2u/mERO0iz+jyjzHeCKJTFQ1F4D9fTLQ+bL9MKX7H6Ya7fOWUoGZvdJzVvrqZzGZw0kyKRmvJ0+3PNPKthTKyNIOIWCCxGijNThEKi+NA3dqGG36fzI403G6d8zRTNKLO+yLF4U454DcWuYD3TCa4vlMcq1pMlVPyMI3EUImk85hhhFNM/X7CeIY75e/u1RWHFrnGz9bz4Mx6FPm09I+WcsNun55TvtJ60rFyshkJXsJ65WX08k9eK1lPtDSVeXXn4RnKBH7SeHNSI9xovS+omi7PI/7SeMW5O9NinV7Ke0dfjN9obcTXrmUtwvE9N8BdxDes5YkhaJ2LSVrSeM8oiwTBlwp9TZn3BhHC6NPH3Pe+nGdmnrScHJse99hnle6zniNfXMj+9mvWcQdGD9GLWk1MeR7tK+U7rOaO81/SC1vOIQSiLkP+nV7CesxLroteznggKyzaQXsV67ktqkG1F6+m9PNsc3L+mObOnrCc/g+g4sYL1zFriUTbik9ZzRrmLZpnlBXdaz6glH79Wz0HdPiJbyQAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

export default RedValid
