import React from "react";
import { useNavigate } from "react-router";
// import Header from "components/common/onBoardingHeader";
import { NotFoundPageWrapper } from "./notfound.style";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* <Header /> */}

      <NotFoundPageWrapper>
        <h1>Sorry, the page you’re looking for isn’t available.</h1>
        <p>
          Perhaps the link was entered incorrectly, or the page has been moved.
        </p>
        <button onClick={() => navigate(-1)}>Go back</button>
      </NotFoundPageWrapper>
    </div>
  );
};

export default NotFound;
