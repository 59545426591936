import { logOut } from "api/auth";
import { Close } from "components/icons";
import { useModal } from "context/modal";
import { getDeviceName } from "utils";
import { Button } from "../Kit";
import { MySignOutModal } from "./logoutModal.styles";
import { useUser } from "context/user";

export default function LogOutModal() {
  const modal = useModal();
  const { signOut } = useUser();
  const handleSignOut = async (allDevices = false) => {
    modal.close("logOutModal");
    const { body } = await logOut({
      logoutOnAllDevices: allDevices,
      userAuthentication: {
        userName: JSON.parse(localStorage.getItem("userName")),
        deviceName: getDeviceName(),
        validationToken: JSON.parse(localStorage.getItem("auth")).access || "",
      },
    });
    if (body.isLoggedOut) {
      signOut();
      localStorage.removeItem("userName");
    }
  };
  return (
    <MySignOutModal>
      <div className="modal-header">
        <Close onClick={() => modal.close("logOutModal")} cursor="pointer" />
      </div>
      <div className="sign-out-container">
        <p>How would you like to sign out</p>
        <Button className="first-signOut" onClick={() => handleSignOut()}>
          Sign out
        </Button>
        <Button onClick={() => handleSignOut(true)}>
          Sign out from all devices
        </Button>
      </div>
    </MySignOutModal>
  );
}
