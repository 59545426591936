import { request } from "./client"

const authRequest = (method, path, data) =>
  request(method, `/auth/${path}`, data)

export const validateUserInfo = (data) =>
  authRequest("post", "/signup/validateUserInfo", data)

export const validatePhoneNumber = (data) =>
  authRequest("post", "signup/validatePhone", data)

export const generateSignupToken = (data) =>
  authRequest("post", "/signup/generateSignupToken", data, false)

export const createUser = (data) =>
  authRequest("post", "/signup/createUser", data)

export const logOut = (data) =>
  request("post", "/auth/logout/signOut", data, false)

export const singIn = (data) => request("post", "/auth/login", data, false)

export const signOut = () => request("post", "/auth/logout/signOut")
