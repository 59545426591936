import * as React from "react";

export function Search(props) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.969 17.188a7.219 7.219 0 100-14.438 7.219 7.219 0 000 14.438zM15.072 15.073l4.177 4.177"
        stroke="#828282"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
