import { useMutation } from "react-query"
import { NavLink, useLocation } from "react-router-dom"
import { MySideBar, ProfileWrapper, SettingsWrapper } from "./sidebar.styles"
import { useUser } from "context/user"
import roomLogoPlaceholder1 from "assets/pictures/roomLogoPlaceholder1.png"
import { signOut } from "api/auth"
import { adminDropDownList } from "constant"
import { useSettings } from "constant/DropDownList"
import { useFetchUserProfile } from "api/users/useFetchUserProfile"

export default function SideBar() {
  const { setStatus, setUser, user: profileDataResponse } = useUser()
  const user = JSON.parse(localStorage.getItem("user"))

  const location = useLocation()

  const { mutate: singOutFn } = useMutation(signOut)

  const profileData = profileDataResponse

  const settingsOptions =
    user?.userRoleName === "rantt.creator" ||
    user?.userRoleName === "rantt.admin"
      ? adminDropDownList
      : useSettings

  useFetchUserProfile({
    config: {
      onSuccess: (data) => {
        setUser(data?.user)
      },
    },
  })

  return (
    <MySideBar>
      <ProfileWrapper>
        <>
          {profileData?.avatar?.uri.length >= 1 ? (
            <img src={profileData.avatar.uri} alt={profileData?.displayName} />
          ) : (
            <img src={roomLogoPlaceholder1} alt="" />
          )}
        </>
        <div>
          <p>
            <span>{profileData?.displayName}</span>{" "}
            <span className="userName">@{profileData?.userName}</span>
          </p>
        </div>
      </ProfileWrapper>
      <SettingsWrapper>
        <ul>
          {settingsOptions?.map((setting) => (
            <li>
              <NavLink
                to={setting.value}
                className={({ isActive }) =>
                  isActive ||
                  setting?.subUrls?.find((url) => url === location?.pathname)
                    ? "active_link"
                    : ""
                }
              >
                {setting.title}
              </NavLink>
            </li>
          ))}
          <button
            className="logout"
            onClick={() => {
              singOutFn()
              localStorage.removeItem("auth")
              localStorage.removeItem("user")
              setStatus("auth")
              window.location.href = "/"
            }}
          >
            Log out
          </button>
        </ul>
      </SettingsWrapper>
    </MySideBar>
  )
}
