import { devices } from "constant"

const { default: styled } = require("styled-components")

export const ContactUsContainer = styled.div`
  .page-body {
    height: auto;
    margin-bottom: 10px;
  }

  /* Heading classes */
  .page-header {
    font-size: 64px;
    line-height: 64px;
    color: #fff;
    padding: 32px 0;
    flex-basis: 100%;
  }

  .page-subheader {
    font-size: 3.6rem;
    line-height: 5.6rem;
    font-weight: bold;
    padding: 2.4rem 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-bottom: 3.2rem;
  }

  /* Utilities */
  .container {
    max-width: 1260px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 16px;
  }

  .flex {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .flex-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* Navbar styling */
  .navbar {
    /* background: var(--rantt-gradient); */
    color: ${({ theme }) => theme.linearGradient};
    background: ${({ theme }) => theme.linearGradient};
    height: auto;
    margin-bottom: 70px;
  }

  .logo {
    max-width: 150px;
    max-height: 39.26px;
    margin-top: 16px;
  }

  /* Top Navigation */
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
    padding: 0;
    list-style: none;
    font-size: 15px;
  }

  /* Links */
  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: #fff;
  }

  .footer a:hover {
    color: inherit;
  }

  /* Footer */
  .footer {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.footerBackground};
  }

  .footer .container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 14px;
  }

  .footer p {
    font-size: 15px;
    line-height: 1.6rem;
    padding-bottom: 0;
  }

  .footer .nav-list {
    height: 100%;

    margin: 0;
  }

  @media ${devices.mobileXl} {
    .page-header {
      font-size: 40px;
    }
  }
  /* Mobile */
  /* @media (max-width: 500px) {
    body {
      margin-bottom: 0;
    }
    .navbar {
      height: auto;
      margin-bottom: 1.6rem;
    }

    .navbar .flex {
      flex-direction: column;
    }

    .navbar ul {
      padding: 10px;
    }

    .container {
      max-width: 100%;
      margin: 0 auto;
      overflow: auto;
      padding: 0 1.25re;
    }

    .page-header {
      font-size: 4rem;
      line-height: 4.8rem;
      color: #fff;
      font-weight: 700;
      padding: 1.6rem 0;
    }

    .page-subheader {
      font-size: 2rem;
      line-height: 2rem;
      padding: 1rem 0;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: #404040;
      padding-bottom: 1.6rem;
    }

    .footer {
      height: auto;
      position: relative;
      bottom: 0;
    }

    .footer .nav-list {
      gap: 1.6rem;
      font-size: 1.6rem;
    }

    .footer .container {
      overflow: visible;
    }

    .footer p {
      margin-top: 8px;
      right: 0px;
      font-size: 25px;
      line-height: 1.6rem;
    }
    .flex-nav {
      display: flex;
      justify-content: center;
      background-color: none;
    }
  } */

  /* Hide number input arrows */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #subscription-price {
    font-size: 16px;
    color: inherit;
    text-align: center;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
  }

  #followers {
    font-size: 16px;
    color: inherit;
    text-align: center;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
  }

  #percentage-label {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
`
