import { useState } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import BodyImage from "components/common/BodyImage"
import { MySignUp } from "./signup.styles.js"
import SignInInfo from "components/common/SigninInfo"
import { Spinner } from "components/common/Kit"
import * as authAPi from "api/auth"
import { useUser } from "context/user.jsx"
import { getDeviceName } from "utils/getDeviceName.js"
import useLocalStorage from "hooks/useLocalStorage.jsx"
import { promptUserToCreator } from "api/users.js"
import * as paths from "paths"

import { Helmet } from "react-helmet-async"
import { VerifyPhoneNumber } from "components/common/Auth/VerifyPhoneNumber/index.jsx"
import { PhoneNumber } from "components/common/Auth/SendPhoneNumber/index.jsx"
import { FirstFormCreatorInformation } from "components/common/Auth/FirstFormCreatorInformation/index.jsx"

export const useFormValues = () => useFormContext()

export default function SignUp({ back }) {
  const [step, setStep] = useState(1)
  const methods = useForm()
  const { signIn, setUser } = useUser()

  const [, setUserLocalStorage] = useLocalStorage("user", null)

  const { userName, userEmail, userDisplayName, password } = methods.getValues()

  const { mutate: promptUserToCreatorFn } = useMutation(promptUserToCreator, {
    onSuccess: (data) => {
      if (data?.success) {
        window.location.href = paths.createCommunity
      }
    },
  })

  const { mutateAsync: createUserFn, isLoading } = useMutation(
    authAPi.createUser,
    {
      onSuccess: (data) => {
        if (data?.errors?.length === 0) {
          setUser({ ...data, userName })
          setUserLocalStorage({
            ...data,
            userName,
            userRoleName: "rantt.creator",
          })
          signIn(data.validationToken)
          promptUserToCreatorFn({
            userToPromote: userName,
          })
        }
      },
    }
  )

  const [phoneNumber, setPhoneNumber] = useState()

  const onSubmit = async (data) => {
    const combinedData = {
      userCreate: {
        userName,
        userEmail,
        userPhoneNumber: phoneNumber,
        userDisplayName,
        userIsoCode: "en-US",
        userUtcOffset: offset,
      },
      password,
      signupTokenValue: data?.signupTokenValue,
      deviceName: getDeviceName(),
      deviceToken: "",
      deviceType: "web",
    }

    const results = await createUserFn(combinedData)

    if (results?.errors.length >= 1) {
      return methods.setError("signupTokenValue", {
        type: "manual",
        message: "validation token Invalid",
      })
    }
  }

  return (
    <MySignUp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up for Rantt</title>
        <meta
          name="description"
          content="Sign up and join a rantt community."
        />
      </Helmet>
      <BodyImage />
      <div className="form-wrapper">
        <div
          style={{
            height: "10rem",
            width: "100%",
          }}
        >
          {methods.isSubmitting && <Spinner />}
        </div>
        <FormProvider {...methods}>
          {step === 1 && (
            <FirstFormCreatorInformation
              step={step}
              setStep={setStep}
              creator
            />
          )}
          {step === 2 && (
            <PhoneNumber
              step={step}
              setStep={setStep}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          )}
          {step === 3 && (
            <VerifyPhoneNumber
              isLoading={isLoading}
              onSubmit={onSubmit}
              phoneNumber={phoneNumber}
              setStep={setStep}
            />
          )}
        </FormProvider>

        <SignInInfo
          toSignIn
          style={{
            width: "100%",
          }}
        />
      </div>
    </MySignUp>
  )
}

var offset = new Date().getTimezoneOffset()
