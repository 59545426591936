import React, { useState } from "react"
import PhoneInput from "react-phone-number-input"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { queryClient } from "app"
import { useUser } from "context/user"
import { Button } from "components/common/Kit"
import { updateUserPhoneNumber, verifyUserPhoneNumber } from "api/users"
import { TabHeader } from "../TabHeader"
import { RightSideContainer } from "../updateAccount.style"

export const UpdatePhoneNumber = ({ setActiveTab }) => {
  const [phoneNumber, setPhoneNumber] = useState()
  const [error, setCustomError] = useState()
  const [activeSteep, setActiveSteep] = useState("phoneForm")
  const [success, setSuccess] = useState()

  const { user } = useUser()
  const currentPhoneNumber = user?.contactInfo?.phoneNumber

  const { handleSubmit, register, errors } = useForm()

  const { mutateAsync: updateUserPhoneNumberFn, isLoading } = useMutation(
    updateUserPhoneNumber,
    {
      onSuccess: (data) => {
        if (data.success) {
          setActiveSteep("confirmCode")
        }
        if (data?.errors?.length >= 1 || !data?.success)
          setCustomError(data?.errors[0])
      },
    }
  )

  const { mutateAsync: verifyUserPhoneNumberFn } = useMutation(
    verifyUserPhoneNumber,
    {
      onSuccess: (data) => {
        if (data.success) {
          setSuccess("phone number updated successfully")
          queryClient.invalidateQueries("userProfile")
        }
        if (data?.errors?.length >= 1 || !data?.success)
          setCustomError(data?.errors[0])
      },
    }
  )

  const onSubmit = (data) => {
    if (activeSteep === "phoneForm") {
      setCustomError(null)
      updateUserPhoneNumberFn({
        userPhoneNumber: phoneNumber,
      })
    } else {
      verifyUserPhoneNumberFn({
        tokenValue: data.tokenValue,
      })
    }
  }

  return (
    <RightSideContainer>
      <form className="inputs_wrapper" onSubmit={handleSubmit(onSubmit)}>
        {activeSteep === "phoneForm" ? (
          <>
            <TabHeader
              title="Update phone number"
              setActiveTab={setActiveTab}
            />
            <span className="title">Update phone number</span>
            <fieldset className="default_values">
              <label htmlFor="update-email">Current phone number:</label>
              <input disabled defaultValue={currentPhoneNumber} />
            </fieldset>
            <fieldset className="">
              <label htmlFor="update-email">New phone number:</label>
              <PhoneInput
                defaultCountry="US"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                className="custom-phoneInput"
                withCountryCallingCode
              />
              {error && <span className="error_message">{error}</span>}
            </fieldset>
          </>
        ) : (
          <>
            <TabHeader
              title="Update phone number"
              onClick={() =>
                activeSteep !== "phoneForm"
                  ? setActiveSteep("phoneForm")
                  : undefined
              }
            />

            <fieldset>
              <label htmlFor="tokenValue">
                Enter the 6-digit code we sent to {phoneNumber}
                <span
                  className="ml-1 leading-relaxed text-primaryColor"
                  // onClick={requestNewValidationToken}
                >
                  Request a new one.
                </span>
              </label>
              <input
                {...register("tokenValue", {
                  required: {
                    value: true,
                    message: FIELD_ERROR,
                  },
                })}
              />
              {errors?.tokenValue && (
                <span className="error_message">
                  {errors?.tokenValue?.message}
                </span>
              )}
              {error && <span className="error_message">{error}</span>}
              {success && <span className="success_message">{success}</span>}
            </fieldset>
          </>
        )}

        <Button isLoading={isLoading}>Save changes</Button>
      </form>
    </RightSideContainer>
  )
}

const FIELD_ERROR = "This field is required"
