import { privacyPolicy, TermsOfService } from "constant/urls.js"
import { Link } from "react-router-dom"
import { MyFooter } from "./footer.styles.js"

export default function Footer({ className }) {
  return (
    <MyFooter className={className}>
      <div className="footer bottom-0">
        <div className="container flex-nav">
          <nav>
            <ul className="nav-list">
              <li>
                <a href={TermsOfService} target="_blank" rel="noreferrer">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href={privacyPolicy} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://rantt.com/" target="_blank" rel="noreferrer">
                  About
                </a>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </nav>
          <p className="brand_name">
            &copy; {new Date().getFullYear()} Rantt, Inc.
          </p>
        </div>
      </div>
    </MyFooter>
  )
}
