import * as React from "react"

export function Instagram(props) {
  return (
    <svg
      width={25}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.005 5.847A6.144 6.144 0 005.852 12a6.144 6.144 0 006.153 6.153A6.144 6.144 0 0018.16 12a6.144 6.144 0 00-6.154-6.153zm0 10.153c-2.2 0-4-1.794-4-4s1.794-4 4-4c2.207 0 4 1.794 4 4s-1.799 4-4 4zm7.84-10.405c0 .798-.642 1.435-1.435 1.435a1.435 1.435 0 111.435-1.435zm4.076 1.457c-.091-1.923-.53-3.626-1.939-5.029C20.58.62 18.876.181 16.954.084c-1.982-.112-7.92-.112-9.902 0-1.918.091-3.62.53-5.029 1.934C.615 3.42.181 5.124.084 7.046c-.112 1.982-.112 7.92 0 9.902.091 1.923.53 3.626 1.939 5.029 1.408 1.403 3.106 1.842 5.029 1.939 1.981.112 7.92.112 9.902 0 1.922-.091 3.625-.53 5.028-1.939 1.404-1.403 1.843-3.106 1.939-5.029.113-1.981.113-7.915 0-9.896zm-2.56 12.022a4.05 4.05 0 01-2.281 2.282c-1.58.626-5.329.482-7.075.482-1.746 0-5.5.139-7.074-.482a4.05 4.05 0 01-2.281-2.282c-.627-1.58-.482-5.328-.482-7.074 0-1.746-.14-5.5.482-7.074a4.05 4.05 0 012.28-2.282c1.58-.626 5.33-.482 7.075-.482 1.746 0 5.5-.139 7.075.482a4.05 4.05 0 012.281 2.282c.627 1.58.482 5.328.482 7.074 0 1.746.145 5.5-.482 7.074z"
        fill="#828282"
      />
    </svg>
  )
}
