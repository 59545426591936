import { LightCamera, UploadLogo as UploadLogoIcon } from "components/icons"
import { useRef, useState } from "react"
import { ImageUploadContainer } from "../communitySettings.style"

export const UpdateLogo = ({ logo, picRef }) => {
  const inputFileRef = useRef()

  const [uploadedImage, setUploadedImage] = useState(null)

  const updateImage = (e) => {
    if (e.target.files[0]) {
      const image = URL.createObjectURL(e.target.files[0])
      picRef.current = e.target.files[0]
      setUploadedImage(image)
    }
  }
  return (
    <div className="section_wrapper">
      <span className="title">Update your community logo</span>
      <ImageUploadContainer
        className={
          uploadedImage ? "uploadedImage cursor-pointer" : "cursor-pointer"
        }
        onClick={() => inputFileRef.current?.click()}
      >
        {uploadedImage || logo?.uri ? (
          <div>
            <img src={uploadedImage ? uploadedImage : logo?.uri} alt="room" />
            <LightCamera />
          </div>
        ) : (
          <>
            <UploadLogoIcon />
          </>
        )}
        <input
          id="files"
          type="file"
          accept=".png, .jpg, .jpeg"
          ref={inputFileRef}
          onChange={(e) => updateImage(e)}
        />
      </ImageUploadContainer>
    </div>
  )
}
