import * as React from "react";

export function ExternalLink(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 16H2a2 2 0 01-2-2V3a2 2 0 012-2h4v2H2v11h11v-4h2v4a2 2 0 01-2 2zM7.7 9.707L6.29 8.293 12.583 2H9V0h7v7h-2V3.415L7.7 9.707z"
        fill="#000"
      />
    </svg>
  );
}
