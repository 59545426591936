import { useFormCommunitySettings } from ".."

export const CommunityDescription = ({ description }) => {
  const { register } = useFormCommunitySettings({
    defaultValue: {
      about: "oewhilfliwhef",
    },
  })
  return (
    <div className="section_wrapper">
      <span className="title">About your community</span>
      <p>
        Tell members about yourself, what you aim to achieve with your Rantt
        community, and what members can expect by joining.
      </p>
      <textarea
        className="about_community_input"
        type="text"
        defaultValue={description}
        {...register("about")}
      />
    </div>
  )
}
