import { devices } from "constant"
import styled from "styled-components"

export const AccountSettingsContainer = styled.main`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
`

export const Container = styled.div`
  margin-top: 20px;
  padding: 4px 2rem;
  width: 75%;
  .title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid black;
    display: block;
    margin: 10px 0;
  }
  .save_button {
    margin-bottom: 8px;
    width: 146.28px;
    height: 39px;
    border-radius: 8px;
  }
  @media ${devices.tablet} {
    height: 100%;
    z-index: 5;
    /* position: fixed; */
    top: 0;
    background-color: white;
    width: 100%;
  }
`

export const InputsWrapper = styled.div`
  max-width: 400px;
  fieldset {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    input {
      border-radius: 7px;
      height: 36px;
      padding-left: 10px;
      border: 1px solid #828282;
    }
    label {
      font-size: 16px;
      font-weight: 400;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    textarea {
      border-radius: 7px;
      min-height: 100px;
      resize: none;
      padding: 8px;
      border: 1px solid #828282;
    }
  }

  .default_values {
    input {
      background: ${({ theme }) => theme.backgroundGrey};
      border: none;
    }
  }
  .custom_values {
    input {
      border: 1px solid black;
    }
  }
`

export const MangeSubscriptions = styled.div`
  margin-top: 10px;
  .title {
    margin-bottom: 24px;
  }
`

export const CommunityWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .community_info {
    display: flex;
    flex-direction: column;
    margin-right: 37px;
    margin-left: 6px;
    .community_name {
      color: black;
    }
    span {
      color: #828282;
    }
  }
  img {
    height: 45px;
    width: 45px;
    border-radius: 15px;
  }
  .edit_button {
    text-align: center;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 6px;
    padding: 4px 6px;
    min-width: 66px;
    font-weight: 600;
  }
`

export const RightSideContainer = styled.div`
  .title {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid black;
    display: block;
    margin: 10px 0;
  }
  .inputs_wrapper {
    width: 80%;
    margin: 0 auto;
    margin-top: 35px;
    form {
    }
    button {
      height: 38x !important;
      border-radius: 8px;

      margin: 0 auto;
      display: block;
    }
    fieldset {
      display: flex;
      flex-direction: column;
      margin: 25px 0;
      input {
        border-radius: 7px;
        height: 50px;
        padding-left: 10px;
        border: 1px solid #828282;
        text-align: left;
      }
      label {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
      textarea {
        border-radius: 7px;
        min-height: 100px;
        resize: none;
        padding: 8px;
        border: 1px solid #828282;
      }
    }

    .default_values {
      input {
        background: ${({ theme }) => theme.backgroundGrey};
        border: none;
      }
    }
    .custom_values {
      input {
        border: 1px solid black;
      }
    }
  }
`
