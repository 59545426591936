import {
  getSpecificCommunityByCreator,
  updateCommunity,
  updateCommunityLogo,
} from "api/communities"
import { useUser } from "context/user"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { createFormData, getDeviceName } from "utils"

export const useCommunitySettings = () => {
  const [isSettingsOpenInMobile, openSettingsInMobile] = useState(null)
  const [successMessage, setSuccessMessage] = useState()
  const picRef = useRef()

  const { user } = useUser()
  const { setCurrentCommunity } = useUser()

  const { data: Community, isLoading } = useQuery(
    ["communityByCreator", user?.id],
    () =>
      getSpecificCommunityByCreator({
        creatorUserName: user?.userName,
        getSubscriptions: true,
      }),
    {
      onSuccess: (data) => {
        setCurrentCommunity(data?.community)
      },
    }
  )

  const community = Community?.community
  const methods = useForm()

  const { mutateAsync: updateCommunityFn, isLoading: updateCommunityLoading } =
    useMutation(updateCommunity, {
      onSuccess: (data) => {
        if (data?.errors?.length === 0) {
          setSuccessMessage("Your community has been updated successfully.")
          setTimeout(() => {
            setSuccessMessage(null)
          }, 4000)
        }
      },
    })

  const {
    mutateAsync: updateCommunityLogoFn,
    isLoading: loadingCommunityLogo,
  } = useMutation(updateCommunityLogo)

  const closeRightPanelInMobileVersion = () => {
    openSettingsInMobile(false)
  }

  const updateAvatarFxn = async (image) => {
    const token = JSON.parse(localStorage.getItem("auth")) || ""
    const userAuthObj = {
      userAuthentication: {
        userName: JSON.parse(localStorage.getItem("user"))?.userName
          ? JSON.parse(localStorage.getItem("user"))?.userName
          : "",
        deviceName: getDeviceName(),
        validationToken: token ? token.access : "",
      },
    }
    if (picRef.current) {
      const formData = createFormData({
        file: picRef.current,
        communityId: community.id,
        userAuthentication: JSON.stringify(userAuthObj),
      })
      return updateCommunityLogoFn(formData)
    }
  }

  const communityLink = `rantt.app/${community?.communityLink}`

  const onSubmit = async (data) => {
    const { connectUrl } = await updateCommunityFn({
      communitySave: {
        countryName: "United States",
        defaultCurrencyCode: "usd",
        IsoCode: "en-US",
        country_id: 1,
        countryId: 1,
        id: community?.id,
        name:
          data.communityName?.length > 1 ? data.communityName : community.name,
        description:
          data.about?.length > 1 ? data.about : community?.description,

        link: communityLink,
        tagline: data.communityTagline
          ? data.communityTagline
          : community?.tagline,
      },
      country_id: 1,
      countryId: 1,
    })

    updateAvatarFxn()

    if (connectUrl?.length > 1) {
      window.location.href = connectUrl
    }
  }
  useEffect(() => {
    if (community) {
      methods.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community])
  return {
    loadingCommunityLogo,
    onSubmit,
    closeRightPanelInMobileVersion,
    updateCommunityLoading,
    methods,
    isLoading,
    successMessage,
    isSettingsOpenInMobile,
    openSettingsInMobile,
    community,
    picRef,
  }
}
