import { devices } from "constant"
import styled from "styled-components"

export const MyHeader = styled.div`
  height: 11rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(0deg, #ffffff, #ffffff), rgba(0, 0, 0, 0.06);
  /* border-bottom: 0.4px solid #828282; */

  border-bottom: ${({ theme }) => theme.addPostBorder};
  @media ${devices.mobileXl} {
    padding-left: 1rem;
  }
`

// export const DropDownContainer = styled.div`
//   background-color: white;
//   padding: 1.4rem 1rem;
//   position: absolute;
//   right: 0;
//   margin-top: 10px;
//   min-width: 225px;
//   background: #ffffff;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   border-radius: 6px;
//   z-index: 35;

//   li {
//     margin: 9px 0;
//     font-size: 18px;
//     list-style: none;
//   }
//   button {
//     border: none;
//     background: transparent;
//     color: ${({ theme }) => theme.secondaryColor};
//     cursor: pointer;
//   }
// `;

// export const MobileBurgerMenu = styled.div`
//   display: none;
//   @media ${devices.laptop} {
//     display: block;
//   }

//   .side_bar_wrapper {
//     cursor: pointer;
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     background-color: white;
//     top: 98px;
//     height: 100%;
//     z-index: 40;
//   }
// `;
