import * as React from "react"

function ApplePodcast(props) {
  return (
    <svg
      width={21}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.969 17.434a8.242 8.242 0 003.777-6.936 8.248 8.248 0 00-16.496 0 8.248 8.248 0 003.777 6.936c.056.81.187 1.781.337 2.676l.01.047C2.624 18.549 0 14.833 0 10.497 0 4.702 4.7 0 10.498 0c5.797 0 10.498 4.7 10.498 10.498a10.5 10.5 0 01-6.374 9.659l.01-.052c.145-.9.28-1.865.337-2.67zm-.108-1.786a3.027 3.027 0 00-.328-.759 3.191 3.191 0 00-1.026-1.05 4.489 4.489 0 001.49-3.341 4.5 4.5 0 00-8.998 0c0 1.326.576 2.521 1.49 3.341a3.191 3.191 0 00-1.026 1.05 3.027 3.027 0 00-.328.76 6.75 6.75 0 014.363-11.9 6.75 6.75 0 014.363 11.9zm-4.363-1.026c1.542 0 3 .403 3 2.053 0 1.546-.605 4.879-.966 6.228-.24.89-1.148 1.097-2.034 1.097-.886 0-1.79-.206-2.034-1.097-.366-1.335-.965-4.672-.965-6.223 0-1.645 1.457-2.053 2.999-2.053v-.005zm0-6.749a2.624 2.624 0 110 5.249 2.624 2.624 0 010-5.249z"
        fill="#828282"
      />
    </svg>
  )
}

export default ApplePodcast
