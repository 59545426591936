import { devices } from "constant";
import styled, { css } from "styled-components";

const signUpInputsStyles = css`
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  text-align: start;
  padding-left: 1.6rem;
`;

export const MySignUp = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  @media ${devices.tablet} {
    display: block;
  }

  .form-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 33%;
    @media ${devices.laptop} {
      width: 50%;
    }
    @media ${devices.tablet} {
      width: 100%;
    }
    form {
      width: 80%;

      .request-code {
        color: ${({ theme }) => theme.secondaryColor};
        cursor: pointer;
      }

      & label:not(:first-child) {
        display: inline-block;
        margin-top: 1.5rem;
      }
    }

    .form-title {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      .left-arrow {
        margin-left: -1rem;
        margin-right: 3rem;
        cursor: pointer;
      }
    }

    .custom-phoneInput {
      input {
        padding: 1rem;
        background-color: ${({ theme }) => theme.backgroundGrey};
        text-align: center;
        outline: none;
        border: none;
        border-radius: 1.5rem;
        width: 100%;
        height: 5rem;

        ::-webkit-input-placeholder {
          text-align: center;
        }

        ${({ error, theme }) =>
          error && `border: 1px solid ${theme.primaryColor}`}
        ${signUpInputsStyles}
      }
    }
  }

  input {
    ${signUpInputsStyles}
  }
`;
