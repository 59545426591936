import * as React from "react"

function HideEye(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.185 19.368l-2.421-2.422a5.251 5.251 0 01-6.711-6.712l-3.09-3.09C1.407 9.418 0 12 0 12s4.5 8.25 12 8.25a10.544 10.544 0 004.185-.882zM7.815 4.632A10.542 10.542 0 0112 3.75c7.5 0 12 8.25 12 8.25s-1.409 2.582-3.962 4.857l-3.093-3.093a5.251 5.251 0 00-6.71-6.711l-2.42-2.42v-.001z"
        fill="#000"
      />
      <path
        d="M8.287 11.469a3.75 3.75 0 004.244 4.244l-4.245-4.244h.001zm7.425 1.062L11.47 8.286a3.75 3.75 0 014.244 4.244v.001zm4.757 9l-18-18 1.062-1.062 18 18-1.062 1.062z"
        fill="#000"
      />
    </svg>
  )
}

export default HideEye
