import { Search } from "components/icons/Search";
import { forwardRef, useState } from "react";
import { DropDownContainer } from "./dropDown.style";

const DropDownReasons = (
  { data, handleSelect, styles, cssStyles, title, search },
  ref
) => {
  const [searchField, setSearchField] = useState("");
  const filteredData = data?.filter((item) =>
    item.name.toLowerCase().includes(searchField.toLowerCase())
  );
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  return (
    <DropDownContainer ref={ref} cssStyles={cssStyles}>
      <div className="dd-header">
        {search && (
          <div className="dd-search">
            <Search className="search-icon" />
            <input
              type="text"
              placeholder="search rooms"
              onChange={handleChange}
            />
          </div>
        )}

        {title && <p className="title">title</p>}
      </div>
      <ul className="channels-list">
        {filteredData?.map((item) => {
          item.selected = false;
          return (
            <li key={item.link} onClick={() => handleSelect(item.name)}>
              {item.name}
            </li>
          );
        })}
      </ul>
    </DropDownContainer>
  );
};

export default forwardRef(DropDownReasons);
