import {
  SocialTwitter,
  Github,
  Facebook,
  Instagram,
  WebsiteLink,
  LinkedIn,
  Youtube,
  Twitch,
  Spotify,
  TikTok,
  SoundCore,
} from "components/icons"
import ApplePodcast from "components/icons/ApplePodcast"
import { Mastodon } from "components/icons/Mastodon"

export const website = [{ name: "website", icon: WebsiteLink }]

export const Social = [
  {
    name: "instagram",
    icon: Instagram,
  },
  {
    name: "tiktok",

    icon: TikTok,
  },

  {
    name: "youtube",
    icon: Youtube,
  },
  {
    name: "twitter",
    icon: SocialTwitter,
  },
  {
    name: "facebook",
    icon: Facebook,
  },
  {
    name: "linkedin",
    icon: LinkedIn,
  },
  {
    name: "mastodon",
    icon: Mastodon,
  },
]

export const streaming = [
  {
    name: "spotify",

    icon: Spotify,
  },
  {
    name: "soundcloud",
    icon: SoundCore,
  },

  {
    name: "apple-podcast",
    icon: ApplePodcast,
  },
  {
    name: "twitch",
    icon: Twitch,
  },
]
export const SocialIcons = {
  spotify: <Spotify />,
  soundcloud: <SoundCore />,
  "apple.podcast": <ApplePodcast />,
  twitch: <Twitch />,
  mastodon: <Mastodon />,
  instagram: <Instagram />,
  tiktok: <TikTok />,
  youtube: <Youtube />,
  twitter: <SocialTwitter />,
  facebook: <Facebook />,
  linkedin: <LinkedIn />,
  website: <WebsiteLink />,
  github: <Github />,
  snapchat: "<Snapchat />",
}
export const misc = [{ name: "github", icon: Github }]

export const links = [...website, ...Social, ...streaming, ...misc]
