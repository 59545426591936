import * as React from "react";

export function Options(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.955 10.098a2 2 0 11-1.262 3.796 2 2 0 011.262-3.796zm-6.324.005a2 2 0 11-1.262 3.795 2 2 0 011.262-3.795zm-6.325.005a2 2 0 11-1.262 3.795 2 2 0 011.262-3.796z"
        fill="#000"
      />
    </svg>
  );
}
