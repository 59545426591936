import { useState, useEffect, useRef } from "react"

export default function useVisible(options) {
  const [isIntersecting, setIntersecting] = useState(false)
  const elementRef = useRef(null)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    options
  )

  useEffect(() => {
    if (elementRef?.current) {
      observer.observe(elementRef.current)
      return () => {
        observer.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current])

  return [elementRef, isIntersecting]
}
