import * as React from "react"

export function Youtube(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.902 5.816c-.261-1.108-1.032-1.981-2.011-2.278C19.116 3 12 3 12 3s-7.117 0-8.891.538c-.98.297-1.75 1.17-2.012 2.278-.476 2.01-.476 6.202-.476 6.202s0 4.193.476 6.202c.262 1.109 1.033 1.945 2.012 2.242C4.884 21 12 21 12 21s7.116 0 8.89-.538c.98-.297 1.75-1.133 2.012-2.242.476-2.01.476-6.202.476-6.202s0-4.192-.476-6.202zM9.672 15.825V8.212l5.948 3.806-5.947 3.807z"
        fill="#828282"
      />
    </svg>
  )
}
