import RedValid from "components/icons/RedValid"
import { convertCentsToDollar } from "utils/convertCentsToDollar"
import { cx } from "utils/cx"

export const PlansWrappers = ({
  subscriptions,
  setSelectedPlan,
  selectedPlan,
  isBestValueAvailable,
}) => {
  return (
    <div className="plans_wrapper">
      {/* monthly section */}
      {subscriptions?.map((subscription, index) => (
        <div
          onClick={() => setSelectedPlan(subscription)}
          className={cx(
            "plan transition-all cursor-pointer relative",
            selectedPlan === subscription && "!border-2 !border-secondaryColor"
          )}
          key={subscription?.name + index}
        >
          {isBestValueAvailable && subscription?.name === "year" ? (
            <div className="absolute -top-6 py-2 px-4 bg-secondaryColor text-white rounded-full font-normal text-xl">
              BEST VALUE
            </div>
          ) : null}
          {selectedPlan === subscription ? (
            <RedValid />
          ) : (
            <input type="checkbox" checked={selectedPlan === subscription} />
          )}

          <span>
            {`$${convertCentsToDollar(subscription?.price)}/${
              subscription?.name
            }`}
          </span>
          <p>{subscription?.name === "month" ? "Monthly" : "Annual"}</p>
        </div>
      ))}
    </div>
  )
}
