import * as React from "react"

export function Twitch(props) {
  return (
    <svg
      width={22}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.197 4.85h-1.811v5.142h1.81V4.85zm-4.977-.022h-1.81v5.145h1.81V4.828zM4.524 0L0 4.285v15.43h5.43V24l4.524-4.285h3.621L21.721 12V0H4.524zm15.387 11.145l-3.62 3.427h-3.62l-3.17 3v-3H5.43V1.715h14.48v9.43z"
        fill="#828282"
      />
    </svg>
  )
}
