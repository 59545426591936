import { updateuserCreditCard } from "api/subscriptions"
import { Button } from "components/common/Kit"
import { queryClient } from "app"
import { useMutation } from "react-query"
import { SectionContainer } from "../sectionContainer"
import { useUpdateCard } from "./useUpdateCard"

const {
  useStripe,
  useElements,
  Elements,
  CardElement,
} = require("@stripe/react-stripe-js")
const { loadStripe } = require("@stripe/stripe-js")
const { useState } = require("react")

export const UpdatePaymentCard = ({ selectedCommunity }) => {
  const { accountStId } = useUpdateCard({
    selectedCommunity,
  })

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUPLIC_KEY, {
    stripeAccount: accountStId,
  })

  return (
    <SectionContainer title="Update your card information">
      {/* this is input for card and i think we will handel this with  react stripe if we are going to use stripe  */}
      {accountStId && (
        <Elements stripe={stripePromise}>
          <UpdateCardForm selectedCommunity={selectedCommunity} />
        </Elements>
      )}
    </SectionContainer>
  )
}

const UpdateCardForm = ({ selectedCommunity }) => {
  const [successMessage, setSuccessMessage] = useState()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)

  const { lastFourDigitsCard, accountStId } = useUpdateCard({
    selectedCommunity,
  })

  const oldPaymentMethod = lastFourDigitsCard?.card?.data[0]?.id

  const last4Digits = lastFourDigitsCard
    ? lastFourDigitsCard?.card?.data[0]?.card?.last4
    : ""

  const { mutateAsync: updateUserCreditCard, isLoading: isLoadingUpdateCard } =
    useMutation(updateuserCreditCard, {
      onSuccess: () => {
        setSuccessMessage(
          "Your credit card info has been updated successfully."
        )
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
      },
    })

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardElement)
    const card = await stripe?.createPaymentMethod({
      type: "card",
      card: cardElement,
    })

    // const isOldCardBrandTheSameUsed =
    //   data.card.data[0]?.card?.exp_month !==
    //   card?.paymentMethod?.card?.exp_month
    // const isOldCardUsedLast4 =
    //   data.card.data[0]?.card?.last4 !== card?.paymentMethod?.card?.last4
    // const isOldCardUsedExpirationDate =
    //   data.card.data[0]?.card?.exp_year !== card?.paymentMethod?.card?.exp_year

    try {
      const response = await updateUserCreditCard({
        accountId: accountStId,
        customerId: selectedCommunity?.customerId,
        paymentMethodId: card.paymentMethod.id,
        oldPaymentMethod,
      })

      if (!response.ok) {
        throw new Error("Failed to update card")
      }

      if (response?.ok) {
        queryClient.invalidateQueries("last4digitscard")
      }

      if (error) {
        setError(error.message)
        return
      }
    } catch (error) {
      setError("something happening wrong")
    }
  }

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError(null)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="default-card">
        <label htmlFor="update-email">
          Update your current card ending in {last4Digits}
        </label>
        <input
          disabled
          value={`***************${last4Digits ? last4Digits : ""}`}
        />
      </fieldset>
      <fieldset className="updated-card">
        <label htmlFor="update-email">Enter card number:</label>
        <CardElement onChange={handleChange} className="input" />
      </fieldset>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {successMessage && (
        <span className="block mt-4 text-green-600">{successMessage}</span>
      )}
      <Button
        isLoading={isLoadingUpdateCard}
        type="submit"
        className="save_changes_button"
      >
        Save Changes
      </Button>
    </form>
  )
}
