import { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import BodyImage from "components/common/BodyImage"
import { MyForgotPassword } from "./forgotpassword.styles"
import { Button, Input } from "components/common/Kit"
import SignInInfo from "components/common/SigninInfo"
import { LeftArrow } from "components/icons"
import { useMutation } from "react-query"
import { resetUserPassword, sendEmailValidation } from "api/users"
import { useForm } from "react-hook-form"
import { getDeviceName } from "utils"
import * as paths from "paths"
import useLocalStorage from "hooks/useLocalStorage"
import { useUser } from "context/user"
import { singIn } from "api/auth"
import HideEye from "components/icons/EyeHide"
import ViewEye from "components/icons/EyeView.jsx"

export default function ForgotPassword() {
  const [step, setStep] = useState(1)
  const [isShownPassword, toggleIsPassword] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm()

  const { signIn, setUser } = useUser()
  const [, setUserLocalStorage] = useLocalStorage("user", null)

  const { mutateAsync: singInFunction, isLoading: loadingSigin } = useMutation(
    singIn,
    {
      onSuccess: (data) => {
        if (!data.isLoggedIn) {
          return setError("password", {
            type: "manual",
            message: "password or email is not correct!",
          })
        }
        setUser({ ...data })
        setUserLocalStorage({ ...data })
        signIn(data.validationToken)
        navigate(paths.welcomePage, { replace: true })
        window.location.reload()
      },
      onError: (data) => {
        setError("password", {
          type: "manual",
          message: "something went wrong",
        })
      },
    }
  )

  const singInFnAfterSuccessResetPassword = async (data) => {
    const toSend = {
      deviceName: getDeviceName(),
      deviceType: "web",
      ...data,
    }
    singInFunction(toSend)
  }
  const { mutate: resetUserPasswordFn, isLoading: loadingResetPassword } =
    useMutation(resetUserPassword, {
      onError: (error) => {},
      onSuccess: (data) => {
        if (data?.errors[0] === "InvalidTokenValueErr") {
          return setError("token", { message: "Invalid Token Value " })
        }
        if (data?.success) {
          const { emailOrUsername, password } = getValues()
          singInFnAfterSuccessResetPassword({
            userIdentifier: emailOrUsername,
            password: password,
          })
        }
      },
    })

  const { mutate: sendEmailValidationFn, isLoading: loadingSendToken } =
    useMutation(sendEmailValidation, {
      onError: (error) => {},
      onSuccess: (data) => {
        if (data?.errors[0] === "InvalidUserIdentifierErr") {
          return setError("emailOrUsername", {
            message: "Invalid user Identifier",
          })
        }

        if (data?.success) {
          return handleSteps()
        }
        setError("emailOrUsername", {
          message: "something wrong",
        })
      },
    })

  const handleSteps = () => {
    if (step < 2) {
      setStep((step) => step + 1)
    }
  }

  const onSubmit = (data) => {
    if (step === 1) {
      return sendEmailValidationFn({
        userIdentifier: data?.emailOrUsername,
      })
    }
    resetUserPasswordFn({
      token: data?.token,
      newPassword: data?.password,
      userIdentifier: data?.emailOrUsername,
    })
  }

  return (
    <MyForgotPassword>
      <BodyImage />
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="form-title">
            <LeftArrow
              className="left-arrow"
              onClick={() =>
                step === 1 ? navigate(-1) : setStep((step) => step - 1)
              }
            />
            {step === 1 && "Forgot Password"}
            {step === 2 && "Enter Confirmation Code"}
          </h3>
          {step === 1 && (
            <>
              <p className="instruction">
                Enter your username/email and we’ll send you a code to your
                phone number to get back into your account.
              </p>
              <label htmlFor="email-username">Username or Email</label>
              <Input
                name="email-username"
                {...register("emailOrUsername", {
                  required: {
                    value: true,
                    message: "this field is required ",
                  },
                })}
              />
              {errors?.emailOrUsername?.message && (
                <span className="error_message">
                  {errors?.emailOrUsername?.message}
                </span>
              )}
            </>
          )}
          {step === 2 && (
            <>
              <p className="request-text">
                Enter the 6-digit code we sent to your phone number.
              </p>
              <Input
                name="confirmation-code"
                placeholder="+1XXXX-XXXX"
                {...register("token", {
                  required: {
                    value: true,
                    message: "this field is required",
                  },
                })}
              />
              {errors?.token?.message && (
                <span className="error_message">{errors?.token?.message}</span>
              )}

              <label className="request-text">New password:</label>
              <div className="password_wrapper">
                <input
                  name="confirmation-code"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "password is required",
                    },
                  })}
                  type={isShownPassword ? "text" : "password"}
                  error={errors.password}
                />
                <div onClick={() => toggleIsPassword(!isShownPassword)}>
                  {isShownPassword ? <HideEye /> : <ViewEye />}
                </div>
              </div>

              {errors?.password?.message && (
                <span className="error_message">
                  {errors?.password?.message}
                </span>
              )}
            </>
          )}

          <Button
            type="submit"
            isLoading={loadingResetPassword || loadingSendToken || loadingSigin}
          >
            {step === 1 && "Next"}
            {step === 2 && "Confirm"}
          </Button>
        </form>
        <h3 className="new-user">
          New to Rantt? <Link to="/auth/signup">Create an account</Link>
        </h3>
        <SignInInfo
          toSignIn={false}
          style={{
            width: "100%",
          }}
        />
      </div>
    </MyForgotPassword>
  )
}
