import * as paths from "paths";

export const adminDropDownList = [
  { title: "Welcome", value: paths.welcomePage, subUrls: [] },
  { title: "Account info", value: paths.profileSetting, subUrls: [] },
  {
    title: "Community settings",
    value: paths.communitySettings,
    subUrls: [paths.subscriptionSettings],
  },
  {
    title: "User management",
    value: paths.communityMemberManagement,
    subUrls: [],
  },
  { title: "Support", value: paths.support },
];

export const useSettings = [
  { title: "Welcome", value: paths.welcomePage, subUrls: [] },
  {
    title: "Account info",
    value: paths.profileSetting,
    subUrls: [paths.profileManegeSubscription],
  },
  { title: "Support", value: paths.support, subUrls: [] },
  // { title: "About", value: "" },
];
