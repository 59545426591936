import { devices } from "constant";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 92px;
  border-bottom: 0.5px solid #d1d5db;
  padding: 6px 1.4rem;

  @media ${devices.mobileXl} {
    height: auto;
    padding: 2rem 1rem;
    button {
      display: none;
    }
  }
  div:first-child {
    display: flex;
    flex-direction: column;
    .back {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      svg {
        margin-right: 13px;
        display: none;
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
      }
      /* @media ${devices.mobileXl} {
        display: none;
      } */
    }
    span {
      font-size: 22px;
      font-weight: 400;
    }
  }
  button {
    background: ${({ theme }) => theme.linearGradient};
    color: white;
    width: 140px;
    padding: 6px 0;
    border-radius: 32px;
  }
`;
