import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "react-query"

import { Button, Spinner } from "components/common/Kit"
import { CustomHeader } from "components/common"
import SettingPageWrapper from "components/common/SettingWrapper"
import { RightSideContainer } from "components/common/shared/RightSideWrapper/rightSide.style"
import { cancelSubscription, getListUserSubscriptions } from "api/subscriptions"
import CommunitySubscription from "components/common/CommunitySubscription"

import {
  Container,
  SectionWrapper,
  CommunityWrapper,
  EmptyMessage,
} from "./mangerSubscription.style"
import { UpdatePaymentCard } from "./updateCard"
import { UpdatePlanSection } from "./updatePlane"

import { useFetchCommunityWithId } from "api/community/getCommunityWithId"
import { formatDate } from "utils/convertDate"
import { CommunityAvatar } from "components/common/CommunityAvatar"
import { queryClient } from "app"

const MangeSubscription = () => {
  const params = useParams()

  const { data: listUserSubscriptions, isLoading } = useQuery(
    "listUserSubscriptions",
    () => {
      return getListUserSubscriptions({
        requestedUser: JSON.parse(localStorage.getItem("user"))?.userName,
      })
    }
  )

  const selectedCommunity = listUserSubscriptions?.userSubscriptions?.find(
    (subscription) => subscription?.communityId === params?.communityId
  )

  return (
    <>
      <SettingPageWrapper pageTitle="Manage subscriptions">
        <CustomHeader
          title="Manage subscriptions"
          description="Review and make changes to your subscriptions"
        />
        {isLoading ? (
          <Spinner />
        ) : selectedCommunity ? (
          <Container>
            <CommunityWrapper>
              <div className="community">
                {selectedCommunity?.logo?.uri ? (
                  <img
                    src={selectedCommunity?.logo?.uri}
                    alt="community name"
                  />
                ) : (
                  <CommunityAvatar title={selectedCommunity?.communityName} />
                )}
                <div className="community_info">
                  <span className="community_name">
                    {selectedCommunity?.communityName}
                  </span>
                  <span>{formatDate(selectedCommunity?.renews.seconds)}</span>
                </div>
              </div>
            </CommunityWrapper>
            <UpdatePlanSection selectedCommunity={selectedCommunity} />
            <UpdatePaymentCard selectedCommunity={selectedCommunity} />
            <PaymentSection selectedCommunity={selectedCommunity} />
          </Container>
        ) : (
          <EmptyMessage>
            <h1>You don't have any subscription</h1>
          </EmptyMessage>
        )}
      </SettingPageWrapper>
      <RightSideContainer>
        <div className="w-full right_side_container">
          <h1 className="">My communities</h1>
          <MangeSubscriptionSection />
        </div>
      </RightSideContainer>
    </>
  )
}

const PaymentSection = ({ selectedCommunity }) => {
  const [successMessage, setSuccessMessage] = useState()
  const {
    mutate: cancelSubscriptionFn,
    isLoading,
    error,
  } = useMutation(cancelSubscription, {
    onSuccess: () => {
      setSuccessMessage("Your subscription has been canceled successfully..")
      setTimeout(() => {
        setSuccessMessage(null)
      }, 2000)
      queryClient.invalidateQueries("listUserSubscriptions")
    },
  })

  const { accountStId } = useFetchCommunityWithId({
    params: {
      key: selectedCommunity?.communityId,
      communityId: selectedCommunity?.communityId,
      getSubscriptions: true,
    },
    config: { enabled: !!selectedCommunity?.communityId },
  })

  const cancelFn = () => {
    cancelSubscriptionFn({
      communityId: selectedCommunity?.communityId,
      communityName: selectedCommunity?.communityName,
      isoCode: "en-US",
      userToCancel: JSON.parse(localStorage.getItem("user"))?.userName,
      stripeId: accountStId,
      customerId: selectedCommunity?.customerId,
      cancelImmediately: false,
      subscriptionId: selectedCommunity?.subscriptionReceipt,
    })
  }

  return (
    <SectionContainer title="Cancel your subscription">
      {selectedCommunity?.isCancelled ? (
        <p>
          You have canceled your subscription. You will lose access to this
          community on {formatDate(selectedCommunity?.renews?.seconds)}.
        </p>
      ) : (
        <p>
          Canceling will cause you to lose access to this community on{" "}
          {formatDate(selectedCommunity?.renews?.seconds)}
        </p>
      )}

      <p className="text-red-600">{error?.data.message}</p>
      {successMessage && (
        <span className="block mt-4 text-green-600">{successMessage}</span>
      )}
      <Button
        disabled={selectedCommunity?.isCancelled}
        cssStyles={{ borderRadius: "8px" }}
        onClick={cancelFn}
        isLoading={isLoading}
      >
        {selectedCommunity?.isCancelled
          ? "You have canceled your subscription"
          : "Cancel my subscription"}
      </Button>
    </SectionContainer>
  )
}

const SectionContainer = ({ children, title }) => {
  return (
    <SectionWrapper>
      <span className=" title">{title}</span>
      {children}
    </SectionWrapper>
  )
}

const MangeSubscriptionSection = () => {
  const { data: listUserSubscriptions } = useQuery(
    "listUserSubscriptions",
    () => {
      return getListUserSubscriptions({
        requestedUser: JSON.parse(localStorage.getItem("user"))?.userName,
      })
    }
  )

  return (
    <SectionContainer>
      {listUserSubscriptions?.userSubscriptions?.map((community) => (
        <CommunitySubscription community={community} />
      ))}
    </SectionContainer>
  )
}

export default MangeSubscription
