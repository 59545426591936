import * as React from "react";

export function LightCamera(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M15.667 4.22c1.052.419 1.374 1.877 1.804 2.346a2.02 2.02 0 001.387.628 3.278 3.278 0 013.278 3.277v6.037a4.397 4.397 0 01-4.396 4.395H7.261a4.396 4.396 0 01-4.396-4.395V10.47a3.278 3.278 0 013.278-3.277c.34 0 .956-.16 1.387-.628.43-.469.75-1.927 1.803-2.346 1.053-.419 5.282-.419 6.334 0z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.225 9.896h.01"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M15.811 13.675a3.311 3.311 0 10-6.622 0 3.311 3.311 0 006.622 0z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
