import * as React from "react";

export function Close(props) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.308 0L6 4.308 1.692 0 0 1.692 4.308 6 0 10.308 1.692 12 6 7.692 10.308 12 12 10.308 7.692 6 12 1.692 10.308 0z"
        fill="#000"
      />
    </svg>
  );
}
