import * as React from "react"

export function Spotify(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm4.873 17.657c-.204 0-.33-.064-.518-.175-3.02-1.82-6.532-1.896-10.002-1.185-.188.048-.435.126-.576.126a.753.753 0 01-.764-.765c0-.498.295-.735.658-.813 3.963-.876 8.013-.798 11.468 1.268.295.189.47.358.47.798 0 .44-.345.745-.736.745zm1.301-3.175c-.251 0-.42-.111-.595-.203-3.024-1.79-7.534-2.511-11.545-1.422-.232.062-.358.125-.576.125a.94.94 0 01-.939-.938c0-.518.252-.862.75-1.002 1.346-.377 2.72-.658 4.733-.658 3.14 0 6.174.779 8.564 2.201.392.233.547.533.547.954a.938.938 0 01-.939.943zm1.5-3.687c-.251 0-.406-.063-.624-.188-3.445-2.057-9.605-2.55-13.592-1.438-.174.049-.392.126-.624.126-.639 0-1.128-.498-1.128-1.142 0-.658.407-1.03.842-1.156 1.704-.499 3.61-.736 5.686-.736 3.532 0 7.234.736 9.939 2.313.377.218.624.518.624 1.094 0 .658-.532 1.127-1.123 1.127z"
        fill="#828282"
      />
    </svg>
  )
}
