import React from "react"
import styled from "styled-components"
import { devices } from "constant"
import { useNavigate } from "react-router"
import { LeftArrow } from "components/icons"

const SettingPageWrapper = ({ pageTitle, children }) => {
  const navigate = useNavigate()
  return (
    <SettingWrapper>
      <SettingHeader>
        <LeftArrow onClick={() => navigate(-1)} />
        <h1>{pageTitle}</h1>
      </SettingHeader>
      {children}
    </SettingWrapper>
  )
}

const SettingWrapper = styled.div`
  height: auto;
  width: 80%;
  overflow-y: scroll;
  padding-bottom: 2rem;
  .spinner {
    height: 100%;
    width: 100%;
  }
  @media ${devices.laptop} {
    height: 100%;
    z-index: 5;
    /* position: fixed; */
    top: 0;
  }

  @media ${devices.tablet} {
    height: 100%;
    z-index: 5;
    /* position: fixed; */
    top: 0;
    background-color: white;
    width: 100%;
  }
`

const SettingHeader = styled.div`
  display: none;
  /* @media ${devices.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4.5rem;
    margin-bottom: 2.4rem;
    h1 {
      font-size: 24px;
      font-weight: 400;
    }
    svg {
      position: absolute;
      left: 4%;
    }
  } */
`
export default SettingPageWrapper
