import { devices } from "constant";
import styled from "styled-components";

export const MobileBottomModalContainer = styled.div`
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 40;
  display: none;
  @media ${devices.mobileXl} {
    display: block;
  }

  .backdrop {
    background-color: ${({ theme }) => theme.backdrop};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }
  .modal {
    height: 88%;
    padding: 10px;
    overflow: hidden;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    .modal_header {
      display: flex;
      flex-direction: column;

      .modal_top_header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        svg {
          position: absolute;
          left: 20px;
        }
      }
    }
  }
`;
