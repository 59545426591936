import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom"
import "react-phone-number-input/style.css"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// import * as paths from "../paths";
// import { useUser } from "context/user";
import NotFound from "./404"
import * as paths from "paths"
import { SignIn, SignUp } from "./auth"
import InvitePage from "./auth/Invite"
import ForgotPassword from "./auth/forgotPassword"
import AuthLayout from "./auth/Layout"
import { useUser } from "context/user"
import { Layout } from "components/common"
import {
  CommunitySettings,
  SubscriptionSettings,
  MangeSubscription,
  MemberManagement,
} from "./settings"
import ProfileSettings from "./settings/accountSettings"
import Support from "./support"
import Welcome from "./Welcome"
import CreateCommunity from "./settings/CreateCommunity"
import SignUpInvite from "./auth/signUpInvite"
import Subscribe from "./subscribe"
import SignUpForSubscribers from "./auth/signUpforSubscribers"
import AboutPage from "./about"
import { Contact } from "./contactus"

export default function Router() {
  //
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />
        {AuthRoutes()}
        {settingsRoutes()}
      </Routes>
    </BrowserRouter>
  )
}

function AuthRoutes() {
  return (
    <>
      <Route path="/" element={<AuthLayout />}>
        <Route index path={paths.root} element={<SignIn />} />
        <Route path={paths.invite} element={<InvitePage />} />
        <Route
          path={paths.signUpForCreator}
          element={<SignUp back={false} />}
        />
        <Route path={paths.signUpForInvite} element={<SignUpInvite />} />
        <Route
          path={paths.SignUpForSubscribers}
          element={<SignUpForSubscribers />}
        />
        <Route path={paths.forgotPassword} element={<ForgotPassword />} />
      </Route>
      <Route path={`/:communityName`} element={<Subscribe />} />
    </>
  )
}

function settingsRoutes() {
  return (
    <Route>
      <Route path="/" element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route path={paths.welcomePage} element={<Welcome />} />
          <Route path={paths.profileSetting} element={<ProfileSettings />} />
          <Route
            path={`${paths.profileManegeSubscription}/:communityId`}
            element={<MangeSubscription />}
          />
          <Route path={paths.support} element={<Support />} />
        </Route>
      </Route>

      <Route element={<CreatorRoute />}>
        <Route path={paths.createCommunity} element={<CreateCommunity />} />
        <Route path="/" element={<Layout />}>
          <Route
            path={paths.subscriptionSettings}
            element={<SubscriptionSettings />}
          />
          <Route
            path={paths.communityMemberManagement}
            element={<MemberManagement />}
          />
          <Route
            path={paths.communitySettings}
            element={<CommunitySettings />}
          />
        </Route>
      </Route>
    </Route>
  )
}

function PrivateRoute() {
  const { isAuthenticated } = useUser()
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />
}

function CreatorRoute() {
  const { isAuthenticated } = useUser()
  const isCreator =
    JSON.parse(localStorage.getItem("user"))?.userRoleName !== "rantt.user"

  return isAuthenticated && isCreator ? <Outlet /> : <Navigate to="/" />
}
