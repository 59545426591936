import { isPlainObject } from "is-plain-object"
import { getDeviceName } from "./getDeviceName"

/**
 * @param {String} method the http verb you try to use
 * @param {String} path the path for the endpoint you trying to reach
 * @param {Object} data the data you want to send when performing POST | PUT | PATCH
 * @param {Boolean} auth a boolean value represent the authentication status requirement for the request
 * @returns fetch response
 */
export default async function request(method, path, data, auth = true) {
  const { access } = getAuth()
  const token = JSON.parse(localStorage.getItem("auth")) || ""
  const userAuthObj = {
    userAuthentication: {
      userName: JSON.parse(localStorage.getItem("user"))?.userName,
      deviceName: getDeviceName(),
      validationToken: token ? token.access : "",
    },
  }
  let res
  if (method.toLowerCase() === "post" && auth) {
    if (data instanceof FormData) {
      data.append("userAuthentication", JSON.stringify(userAuthObj))
      res = await send(method, path, data)
    } else {
      res = await send(method, path, {
        ...data,
        ...(access && userAuthObj),
      })
    }
  } else {
    res = await send(method, path, data)
  }

  const status = res.status

  const body = await res.json().catch(() => null)

  if (!res.ok) {
    if (status === 401) {
      localStorage.removeItem("auth")
    }

    throw new FetchError(body, status)
  }

  return { body, status }
}

export class FetchError extends Error {
  constructor(body, status) {
    super()
    this.body = body
    this.status = status
  }
}

const send = (method, path, data, headers) => {
  const isJSON =
    isPlainObject(data) || data instanceof Array || typeof data === "undefined"

  return fetch(`${path}`, {
    method: method.toUpperCase(),
    headers: {
      ...(isJSON && {
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      ...headers,
    },
    credentials: "include",
    body: isJSON ? JSON.stringify(data) : data,
  })
}

const getAuth = () => {
  try {
    return JSON.parse(localStorage.getItem("auth")) || {}
  } catch (err) {
    return {}
  }
}
