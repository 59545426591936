import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { MutatingDots } from "react-loader-spinner"
import { useEffect, useState } from "react"
import BodyImage from "components/common/BodyImage/index.jsx"
import { MySignIn } from "./signIn.styles.js"
import { Button, Input } from "components/common/Kit"
import SignInInfo from "components/common/SigninInfo/index.jsx"
import { useUser } from "context/user.jsx"
import useLocalStorage from "hooks/useLocalStorage.jsx"
import { css } from "styled-components"
import { getDeviceName } from "utils/getDeviceName.js"
import * as paths from "paths"
import { singIn } from "api/auth.js"
import { useMutation } from "react-query"
// import { HideEye } from "components/icons"
import ViewEye from "components/icons/EyeView.jsx"
import HideEye from "components/icons/EyeHide.jsx"
import { Helmet } from "react-helmet-async"

export default function SignIn() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm()
  const { signIn, isAuthenticated, setUser } = useUser()
  const [, setUserLocalStorage] = useLocalStorage("user", null)
  const [isShownPassword, toggleIsPassword] = useState(false)

  useEffect(() => {
    if (isAuthenticated) navigate(paths.welcomePage, { replace: true })
  }, [isAuthenticated, navigate])

  const { mutateAsync: singInFunction, isLoading } = useMutation(singIn, {
    onSuccess: (data) => {
      if (!data.isLoggedIn) {
        return setError("password", {
          type: "manual",
          message: "password or email is not correct!",
        })
      }
      setUser({ ...data })
      setUserLocalStorage({ ...data })
      signIn(data.validationToken)
      navigate(paths.welcomePage, { replace: true })
      window.location.reload()
    },
    onError: (data) => {
      setError("password", {
        type: "manual",
        message: "something went wrong",
      })
    },
  })

  const onSubmit = async (data) => {
    const toSend = {
      deviceName: getDeviceName(),
      deviceType: "web",
      ...data,
    }
    singInFunction(toSend)
  }

  return (
    <MySignIn>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rantt: Social Media for Communities</title>
        <meta
          name="description"
          content="Join a Rantt community today. Available on iOS and Android."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Rantt: Social Media for Communities"
        />
        <meta name="twitter:creator" content="@ranttmedia" />
        <meta name="twitter:site" content="@ranttmedia" />
        <meta
          name="twitter:description"
          content="Join a Rantt community today. Available on iOS and Android."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://rantt.app/" />
        <meta
          property="og:title"
          content="Rantt: Social Media for Communities"
        />
        <meta
          property="og:description"
          content="Join a Rantt community today. Available on iOS and Android."
        />
      </Helmet>
      <BodyImage />
      <div className="form-container">
        {isSubmitting && (
          <MutatingDots
            height="90"
            width="90"
            color="#E04646"
            secondaryColor="#C94889"
            ariaLabel="loading"
            radius={8}
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={false}>
          <h3 className="form-title">Sign in</h3>

          <label htmlFor="userIdentifier">Email or Username</label>
          <Input
            defaultValue=""
            {...register("userIdentifier", {
              required: "username is required",
            })}
            error={errors.password}
          />
          {errors.userName && (
            <p
              style={{
                color: "red",
                marginBottom: "0.5rem",
              }}
            >
              {errors.userName.message}
            </p>
          )}
          <label htmlFor="password">Password</label>
          <div className="password_wrapper">
            <input
              {...register("password", { required: "Email is required" })}
              type={isShownPassword ? "text" : "password"}
              error={errors.password}
            />
            <div onClick={() => toggleIsPassword(!isShownPassword)}>
              {isShownPassword ? <HideEye /> : <ViewEye />}
            </div>
          </div>

          {(errors.userName || errors.password) && (
            <p
              style={{
                color: "red",
                marginBottom: "0.5rem",
              }}
            >
              {errors.password.message}
            </p>
          )}
          <Link to="/auth/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>
          <Button
            cssStyles={css`
              margin-top: 2rem;
            `}
            isLoading={isLoading}
          >
            Login
          </Button>
        </form>

        <h3 className="new-user">
          New to Rantt? <Link to={paths.invite}>Create an account</Link>
        </h3>
        <SignInInfo
          toSignIn={false}
          style={{
            width: "100%",
          }}
        />
      </div>
    </MySignIn>
  )
}
