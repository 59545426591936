import styled from "styled-components";

export const NotFoundPageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 114px;
  text-align: center;

  h1 {
    font-size: 36px;
    line-height: 44px;
  }
  p {
    margin: 20px;
    font-size: 20px;
    line-height: 29px;
  }

  button {
    border-radius: 8px;
    width: 278px;
    height: 60px;
    color: white;
    background: ${({ theme }) => theme.linearGradient};
    font-size: 22px;
    line-height: 27px;
  }
`;
