import styled from "styled-components"

export const RightSideWrapper = styled.div`
  h1 {
    margin-top: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  ul {
    margin-top: 30px;
  }
  li {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #f6f6f6;
    padding: 14px 20px;
    cursor: pointer;
    a {
      padding: 0;
    }
  }
  .underline {
    text-decoration: underline;
    a {
      padding: 0;
    }
  }
`

export const TabHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 33px;
  svg {
    position: absolute;
    left: 4px;
    cursor: pointer;
  }
  h1 {
    margin: 0;
  }
`
