import styled from "styled-components"

export const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, disabled, bg, variant }) =>
    disabled
      ? theme.lightGrey
      : bg
      ? bg
      : variant === "secondary"
      ? "transparent"
      : theme.linearGradient};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "cursor")};
  border: ${({ theme, variant }) =>
    variant === "secondary" ? `1px solid ${theme.secondaryColor}` : ""};
  font-weight: bold; // 700
  color: ${({ theme, variant }) =>
    variant === "secondary" ? theme.secondaryColor : "white"};
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  height: 4.5rem;
  ${({ cssStyles }) => cssStyles}
`
