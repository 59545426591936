import React from "react"
import {
  CommunitySettingContainer,
  CommunitySettingPageContainer,
} from "./communitySettings.style"

import { CustomHeader, MiddleSpinner } from "components/common"
import SettingPageWrapper from "components/common/SettingWrapper"
import { Button } from "components/common/Kit"
import { FormProvider, useFormContext } from "react-hook-form"

import { Helmet } from "react-helmet-async"
import { RightSide } from "./rightSide"
import { UpdateLogo } from "./updateLogo"
import { CommunityLink } from "./communityLink"
import { CommunityDescription } from "./communityDescription"
import { CommunityName } from "./communityName"
import { CommunityTagLine } from "./communityTagLine"
import { useCommunitySettings } from "./useCommunitySettings"

export const useFormCommunitySettings = () => useFormContext()

const CommunitySettings = () => {
  const {
    closeRightPanelInMobileVersion,
    isLoading,
    isSettingsOpenInMobile,
    loadingCommunityLogo,
    methods,
    onSubmit,
    successMessage,
    updateCommunityLoading,
    openSettingsInMobile,
    community,
    picRef,
  } = useCommunitySettings()

  return (
    <CommunitySettingPageContainer>
      <Helmet>
        <title> Community settings | Rantt</title>
      </Helmet>
      <SettingPageWrapper pageTitle="Community settings">
        <CustomHeader
          title="Community settings"
          description="Update your community logo, name, and more."
          buttonTitle=""
        />
        <h1
          onClick={() => openSettingsInMobile(true)}
          className="setting_mobile_header"
        >
          Manage subscription plans{" "}
        </h1>
        {isLoading ? (
          <div className="spinner">
            <MiddleSpinner />
          </div>
        ) : (
          <FormProvider {...methods}>
            <CommunitySettingContainer
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {/* Upload Community logo 🌃 */}
              <UpdateLogo logo={community?.logo} picRef={picRef} />

              <CommunityName communityName={community?.name} />

              <CommunityTagLine tagLine={community?.tagline} />

              <CommunityDescription description={community?.description} />

              <CommunityLink communityLink={community?.communityUrl} />

              {/* SuccessMessage */}
              {successMessage && (
                <span className="mb-6 block text-green-500">
                  {successMessage}
                </span>
              )}

              <Button
                isLoading={updateCommunityLoading || loadingCommunityLogo}
              >
                Save changes
              </Button>
            </CommunitySettingContainer>
          </FormProvider>
        )}
      </SettingPageWrapper>
      <RightSide
        showRightPanelInMobile={isSettingsOpenInMobile}
        closeRightPanelInMobileVersion={closeRightPanelInMobileVersion}
      />
    </CommunitySettingPageContainer>
  )
}

export default CommunitySettings
