import { useFetchCommunityWithId } from "api/community/getCommunityWithId"
import { useState } from "react"

export const useSelectPlan = ({ selectedCommunity }) => {
  const [selectedPlan, serSelectedPlan] = useState()

  const { Community, accountStId } = useFetchCommunityWithId({
    communityId: selectedCommunity?.communityId,
    params: {
      communityId: selectedCommunity?.communityId,
      getSubscriptions: true,
    },
    config: {
      onSuccess: (data) => {
        serSelectedPlan(
          data?.community?.subscriptions?.find(
            (subscription) =>
              subscription?.id === selectedCommunity?.subscriptionId
          )
        )
      },
      enabled: !!selectedCommunity?.communityId,
    },
  })

  return {
    selectedPlan,
    serSelectedPlan,
    Community,
    accountStId,
  }
}
