import { privacyPolicy, TermsOfService } from "constant"
import { Link } from "react-router-dom"
import { MySignUpInfo } from "./signInInfo.styles"

export default function SignInInfo({ toSignIn }) {
  return (
    <MySignUpInfo>
      {toSignIn && (
        <p className="already-member">
          Already a member ?
          <Link to="/">
            <span> Sign In</span>
          </Link>
        </p>
      )}
      <p className="terms">
        By continuing, you agree to Rantt’s{" "}
        <strong>
          <a
            href={TermsOfService}
            target="_blank"
            className="mx-1"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </strong>
        and confirm that you have read Rantt’s{" "}
        <strong>
          <a href={privacyPolicy} target="_blank" rel="noreferrer">
            Privacy Policy.{" "}
          </a>
        </strong>
      </p>
    </MySignUpInfo>
  )
}
