import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import { Button, Input } from "components/common/Kit"
import { MyInvite } from "./invite.styles"
import { useMutation } from "react-query"
import { checkInvitation } from "api/invitation"

import { FIELD_ERROR } from "constant/formText"
import * as paths from "paths"
import { privacyPolicy, TermsOfService } from "constant"
import BodyImage from "components/common/BodyImage"

export default function InvitePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm()

  const { mutate, isLoading } = useMutation(checkInvitation, {
    onSuccess: (data) => {
      if (data.invitationExists) {
        const invitationCode = getValues("inviteCode")
        const email = getValues("email")

        localStorage.setItem(
          "ranttInvitaion",
          JSON.stringify({
            communityId: data?.communityId,
            inviteCode: invitationCode,
            email: email,
          })
        )

        window.location.href = "/" + paths.signUpForInvite
      }

      if (!data.invitationExists) {
        return setError("inviteCode", {
          message: "Email or Code is Invalid",
        })
      }
    },
    onError: () => {
      setError("inviteCode", {
        message: "something went Wrong ",
      })
    },
  })
  const onSubmit = (data) => {
    return mutate({
      invitationEmail: data?.email,
      invitationTokenValue: data?.inviteCode,
    })
  }

  return (
    <MyInvite>
      <BodyImage />
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="inviteCode" className="label">
            Enter your email
          </label>
          <Input
            name="email"
            placeholder=""
            className="invite-code-input"
            {...register("email", {
              required: {
                value: true,
                message: FIELD_ERROR,
              },
            })}
          />
          {errors?.email && (
            <span className="error_message">{errors?.email?.message}</span>
          )}
          <label htmlFor="inviteCode" className="label">
            Enter the invite code that was sent to you{" "}
          </label>
          <Input
            name="inviteCode"
            placeholder="XXXX-XXXX"
            className="invite-code-input"
            {...register("inviteCode", {
              required: {
                value: true,
                message: FIELD_ERROR,
              },
            })}
          />
          {errors?.inviteCode && (
            <span className="error_message">{errors?.inviteCode?.message}</span>
          )}
          <Button isLoading={isLoading}>Join Now</Button>
        </form>

        {/* </form> */}
        <div className="signUp-info">
          <p className="already-member">
            Already a member?
            <Link to="/">
              <span> Sign in </span>
            </Link>
          </p>
          <p className="terms">
            By continuing, you agree to Rantt’s{" "}
            <strong>
              <a
                href={TermsOfService}
                target="_blank"
                className="mx-1"
                rel="noreferrer"
              >
                Terms of Service{" "}
              </a>
            </strong>{" "}
            and confirm that you have read Rantt’s{" "}
            <strong>
              <a href={privacyPolicy} target="_blank" rel="noreferrer">
                Privacy Policy.{" "}
              </a>
            </strong>
          </p>
        </div>
      </div>
    </MyInvite>
  )
}
