import { devices } from "constant"
import styled from "styled-components"

export const InputsWrapper = styled.div`
  margin-left: 25px;
  margin-top: 20px;
  .select-reason {
    display: inline-flex;
    margin-left: 4px;
    align-items: center;
    color: ${({ theme }) => theme.secondaryColor};
  }
  .title {
    position: relative;
    display: block;
    font-weight: 600;
    svg {
      margin-left: 10px;
    }
  }
  .labelContainer {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-top: 15px;
  }
  span {
    font-size: 20px;
    line-height: 24px;
  }

  textarea {
    display: block;
    margin-top: 10px;
    border-radius: 7px;
    min-height: 230px;
    width: 88%;
    padding-left: 10px;
    padding-top: 10px;
    border: none;
    background: ${({ theme }) => theme.backgroundGrey};
    resize: none;
  }
  label {
    font-size: 18px;
    font-weight: 600;
    font-weight: 400;
    margin-bottom: 6px;
  }
  .error_wrapper {
    display: flex;
    flex-direction: column;
  }
  .success_message {
    color: green;
    display: block;
    margin: 3px 0;
    font-size: 15px;
  }
  .support_mobile {
    margin-top: 16px;
    margin-bottom: 25px;
    display: none;
    @media ${devices.tablet} {
      display: block;
    }
  }
`

export const SupportPageWrapper = styled.div`
  width: 80%;
  @media ${devices.tablet} {
    width: 100%;
  }
`

export const SupportRightSideWrapper = styled.div`
  p {
    text-align: center;
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
`
