import { updateUserEmail, verifyUserEmailCode } from "api/users"
import { Button, Input } from "components/common/Kit"
import { useUser } from "context/user"
import { queryClient } from "app"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { EMAIL_REGEX_PATTERN } from "utils"
import { TabHeader } from "../TabHeader"
import { RightSideContainer } from "../updateAccount.style"

const FIELD_ERROR = "This field is required"

export const UpdateEmail = ({ setActiveTab }) => {
  const [active, setActive] = useState("EmailForm")

  const methods = useForm()

  return (
    <RightSideContainer>
      <FormProvider {...methods}>
        <div className="inputs_wrapper">
          {active === "EmailForm" ? (
            <>
              <TabHeader
                title="Update email address"
                setActiveTab={setActiveTab}
              />
              <EmailForm setActive={setActive} />
            </>
          ) : (
            <>
              <TabHeader
                title="Update email address"
                onClick={() => setActive("EmailForm")}
              />
              <VerifyEmail />
            </>
          )}
        </div>
      </FormProvider>
    </RightSideContainer>
  )
}

const EmailForm = ({ setActive }) => {
  const { user } = useUser()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const { mutateAsync: updateUserEmailFn, isLoading } = useMutation(
    updateUserEmail,
    {
      onSuccess: () => {
        setActive("Verify")
      },
    }
  )

  const onSubmit = (data) => {
    updateUserEmailFn({
      userEmail: data?.userEmail,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <span className="title">Update email address</span>
      <fieldset className="default_values">
        <label htmlFor="update-email">Current email:</label>
        <input
          disabled
          key={user?.contactInfo?.email}
          defaultValue={user?.contactInfo?.email}
        />
      </fieldset>
      <fieldset className="custom_values">
        <label htmlFor="update-email">New email:</label>
        <Input
          {...register("userEmail", {
            required: FIELD_ERROR,
            pattern: {
              value: EMAIL_REGEX_PATTERN,
              message: "Email format is invalid",
            },
          })}
        />
        <span className="error_message">
          {errors.userEmail ? errors.userEmail?.message : null}
        </span>
      </fieldset>{" "}
      <Button isLoading={isLoading}>Save changes</Button>
    </form>
  )
}

const VerifyEmail = () => {
  const [successMessage, setSuccessMessage] = useState()
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext()

  const userEmail = getValues("userEmail")

  const { mutateAsync, isLoading } = useMutation(verifyUserEmailCode, {
    onSuccess: (data) => {
      if (data?.errors.length > 0) {
        const errorMessage = data?.errors[0]
        if (errorMessage === "InvalidTokenValueErr") {
          setError("verifyEmailCode", { message: "Invalid Token Value" })
        }
      }

      if (data?.success) {
        setSuccessMessage("Updated email successfully")
        queryClient.invalidateQueries("userProfile")
      }
    },
  })

  const {
    mutateAsync: updateUserEmailFn,
    isLoading: isLoadingUpdateUserEmail,
  } = useMutation(updateUserEmail, {})

  const onSubmit = (data) => {
    mutateAsync({
      tokenValue: data?.verifyEmailCode,
    })
  }

  const requestNewValidationToken = () => {
    updateUserEmailFn({
      userEmail: userEmail,
    })
  }

  useEffect(() => {
    setValue("verifyEmailCode", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldLoading = isLoading || isLoadingUpdateUserEmail
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <label htmlFor="tokenValue inline-block ">
            Enter the 6-digit code we sent to {userEmail}
            <span
              className="inline-block ml-1 leading-relaxed text-primaryColor"
              onClick={requestNewValidationToken}
            >
              Request a new one.
            </span>
          </label>
          <input className="mb-6" {...register("verifyEmailCode")} />
          {successMessage && (
            <span className="mb-6 text-green-500">{successMessage}</span>
          )}
          {errors?.verifyEmailCode?.message && (
            <span className="mt-0 mb-6 text-red-600">
              {errors?.verifyEmailCode?.message}
            </span>
          )}

          <Button isLoading={shouldLoading}>Confirm</Button>
        </fieldset>
      </form>
    </>
  )
}
