import React, { useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import {
  AccountSettingsContainer,
  Container,
  InputsWrapper,
  MangeSubscriptions,
} from "./updateAccount.style"

import { CustomHeader, SettingWrapper } from "components/common"
import { updateUser, updateUserAvatar } from "api/users"
import { useUser } from "context/user"
import { Button } from "components/common/Kit"
import { LightCamera, UploadLogo as UploadLogoIcon } from "components/icons"
import { ImageUploadContainer } from "components/common/shared/ImageUploadContainer/ImageUploadContainer.style"
import { AccountInfoRightSide } from "./RightSide"
import { createFormData, getDeviceName } from "utils"
import { queryClient } from "app"
import { getListUserSubscriptions } from "api/subscriptions"
import CommunitySubscription from "components/common/CommunitySubscription"
import { Helmet } from "react-helmet-async"

const ProfileSettings = () => {
  const { user } = useUser()
  const [sideOpen, toggleSide] = useState()
  const [uploadedImage, setUploadedImage] = useState()
  const [userName, setUserName] = useState()
  const [userBio, setUserBio] = useState()
  const [successMessage, setSuccessMessage] = useState()

  const picRef = useRef()
  const inputFileRef = useRef()

  const { data: listUserSubscriptions } = useQuery(
    "listUserSubscriptions",
    () => {
      return getListUserSubscriptions({
        requestedUser: JSON.parse(localStorage.getItem("user"))?.userName,
      })
    }
  )

  const userData = user

  const { mutateAsync: updateUserFn, isLoading: updateUserLoading } =
    useMutation(updateUser, {
      onSuccess: (data) => {
        if (data?.success) {
          setSuccessMessage("updated your account info successfully.")
          setTimeout(() => {
            setSuccessMessage(null)
          }, 4000)
        }
      },
    })

  const {
    mutateAsync: httpUpdateAvatarFn,
    isLoading: updateUserAvatarLoading,
  } = useMutation(updateUserAvatar)

  const updateAvatarFxn = async (image) => {
    if (picRef.current) {
      const token = JSON.parse(localStorage.getItem("auth")) || ""
      const userAuthObj = {
        userAuthentication: {
          userName: JSON.parse(localStorage.getItem("user"))?.userName
            ? JSON.parse(localStorage.getItem("user"))?.userName
            : "",
          deviceName: getDeviceName(),
          validationToken: token ? token.access : "",
        },
      }
      const formData = createFormData({
        file: picRef.current,
        userId: userData?.id,
        userAuthentication: JSON.stringify(userAuthObj),
      })
      return httpUpdateAvatarFn(formData)
    }
  }
  const updateNameAndBio = () => {
    return updateUserFn({
      userUpdate: {
        userId: userData?.id,
        userDisplayName: userName ? userName : userData?.displayName,
        userBio: userBio ? userBio : userData?.details?.bio,
      },
    })
  }
  const updateImage = (e) => {
    if (e.target.files[0]) {
      const image = URL.createObjectURL(e.target.files[0])
      picRef.current = e.target.files[0]
      setUploadedImage(image)
    }
  }

  const onSubmit = async () => {
    await updateAvatarFxn()
    await updateNameAndBio()
    queryClient.invalidateQueries("userProfile")
  }

  return (
    <AccountSettingsContainer>
      <Helmet>
        <title>Account info | Rantt</title>
      </Helmet>
      <SettingWrapper pageTitle={"Account Settings"}>
        <CustomHeader
          title="Account Settings"
          description="Review information about your account"
        />
        <h1 onClick={() => toggleSide(true)} className="setting_mobile_header">
          Account settings{" "}
        </h1>
        <Container>
          <span className="title">Profile</span>
          <ImageUploadContainer
            className={
              uploadedImage ? "uploadedImage cursor-pointer" : "cursor-pointer"
            }
            onClick={() => {
              inputFileRef.current?.click()
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
          >
            {uploadedImage || userData?.avatar?.uri ? (
              <div>
                <img
                  src={uploadedImage ? uploadedImage : userData?.avatar?.uri}
                  alt="room"
                />
                <LightCamera />
              </div>
            ) : (
              <>
                <UploadLogoIcon />
              </>
            )}
            <input
              id="files"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => updateImage(e)}
              ref={inputFileRef}
            />
          </ImageUploadContainer>

          <InputsWrapper>
            <fieldset className="custom_values">
              <label htmlFor="update-email">Display name</label>
              <input
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
                defaultValue={
                  userData?.displayName
                    ? userData?.displayName
                    : userName
                    ? userName
                    : ""
                }
              />
            </fieldset>
          </InputsWrapper>
          <InputsWrapper>
            <fieldset className="custom_values">
              <label htmlFor="update-email">Bio</label>
              <textarea
                defaultValue={
                  userData?.details?.bio
                    ? userData?.details?.bio
                    : userBio
                    ? userBio
                    : ""
                }
                onChange={(e) => {
                  setUserBio(e.target.value)
                }}
              />
            </fieldset>
          </InputsWrapper>

          <span className="block  min-h-7 mt-1 text-green-600">
            {successMessage}
          </span>

          <Button
            className="save_button mt-8"
            isLoading={updateUserLoading || updateUserAvatarLoading}
            onClick={onSubmit}
          >
            Save Changes
          </Button>
          <MangeSubscriptions>
            <span className="title">Manage subscriptions</span>
            {listUserSubscriptions?.userSubscriptions?.length >= 1 ? (
              listUserSubscriptions?.userSubscriptions?.map((community) => (
                <CommunitySubscription community={community} />
              ))
            ) : (
              <p>You currently aren’t subscribed to any communities.</p>
            )}
          </MangeSubscriptions>
        </Container>
      </SettingWrapper>
      <AccountInfoRightSide show={sideOpen} backFn={() => toggleSide(false)} />
    </AccountSettingsContainer>
  )
}

// const Community = ({ community }) => {
//   return (
//     <CommunityWrapper>
//       <img src={community?.logo?.uri} alt="community name" />
//       <div className="community_info">
//         <span className="community_name">{community?.communityName}</span>
//         <span className="renews_date">
//           Renews:
//           {moment.unix(community?.renews?.seconds).format("MMMM DD , YYYY")}
//         </span>
//       </div>

//       <Link
//         className="edit_button"
//         to={paths.profileManegeSubscription}
//         state={{ community }}
//       >
//         edit
//       </Link>
//     </CommunityWrapper>
//   )
// }

export default ProfileSettings
