import * as React from "react"

export function UserPlaceHolder(props) {
  return (
    <svg
      width={61}
      height={61}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_1_6)">
        <rect
          x={0.73}
          y={0.028}
          width={60}
          height={60}
          rx={30}
          fill="#E2E2E2"
        />
        <rect
          x={18.73}
          y={10.028}
          width={24}
          height={24}
          rx={12}
          fill="#D04873"
        />
        <rect
          x={-14.27}
          y={40.028}
          width={90}
          height={90}
          rx={45}
          fill="#D04873"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1_6">
          <rect
            x={0.73}
            y={0.028}
            width={60}
            height={60}
            rx={30}
            fill="#E2E2E2"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
