import bodyImage from "assets/pictures/rantt-body.png"
import { BodyImageWrapper } from "./bodyImage.styles"

export default function BodyImage() {
  return (
    <BodyImageWrapper className="max-w-[700px]">
      <img src={bodyImage} alt="Rant logo" />
    </BodyImageWrapper>
  )
}
