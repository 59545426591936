import { devices } from "constant"
import styled from "styled-components"

export const MySideBar = styled.div`
  z-index: 70;
  height: 100vh;
  flex: 2;
  width: 28rem;
  z-index: 35;
  background-color: white;

  .backdrop {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
  }

  a {
    display: block;
    padding-left: 0.5rem;
    line-height: 1.8;
  }
  .active {
    font-weight: 600;
    background: ${({ theme }) => theme.linearGradientOpacity};
    border-radius: 0.5rem;
  }
  @media ${devices.mobileXl} {
    display: none;
  }
`

export const ProfileWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: black;
  padding: 1.5rem;
  p {
    font-weight: 600;
  }
  .userName {
    color: ${({ theme }) => theme.lightGrey};
    font-weight: 400;
  }
  img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    margin-right: 15px;
  }
  button {
    color: ${({ theme }) => theme.secondaryColor};
    background: none;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
  }
`

export const SettingsWrapper = styled.div`
  max-width: 30rem;
  .side_bar_settings_container {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1rem;

    svg {
      position: absolute;
      left: 20px;
    }
  }
  ul {
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  li {
    font-size: 18px;
    list-style: none;
    border: 0.5px solid #f6f6f6;
    a {
      height: 100%;
      padding: 11px 0;
      padding-left: 2rem;
    }
    .active_link {
      border-left: 7px solid ${({ theme }) => theme.secondaryColor};
    }
  }

  .logout {
    cursor: pointer;
  }
  button {
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.secondaryColor};
    padding: 14px 0;
    padding-left: 2rem;
    border: 0.5px solid #f6f6f6;
    width: 100%;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
  }
`
