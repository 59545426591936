import { MutatingDots } from "react-loader-spinner";

export default function Spinner(props) {
  return (
    <MutatingDots
      height="90"
      width="90"
      color="#E04646"
      secondaryColor="#C94889"
      ariaLabel="loading"
      radius={8}
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
      }}
      {...props}
    />
  );
}
