import React from "react";
import { TabHeader } from "../TabHeader";

export const Privacy = ({ setActiveTab }) => {
  return (
    <>
      <TabHeader title="Privacy policy" setActiveTab={setActiveTab} />
    </>
  );
};
