import { useFormCommunitySettings } from ".."

export const CommunityTagLine = ({ tagLine }) => {
  const { register } = useFormCommunitySettings()
  return (
    <div className="section_wrapper" style={{ whiteSpace: "pre-wrap" }}>
      <span className="title">Community tagline</span>
      <textarea
        className="community_tagname_input"
        type="text "
        defaultValue={tagLine}
        {...register("communityTagline")}
      />
    </div>
  )
}
