export const theme = {
  primaryColor: "#E04646",
  secondaryColor: "#C94889",
  greyColor: "#50555C",
  lightGrey: "#828282",
  backgroundGrey: "#F6F6F6",
  myReactionBgColor: "#46c4e059",
  footerBackground: "rgba(0,0,0, 0.05)",
  backdrop: "#4c4a4aa1",
  // borderColor: "#8282828a",
  primaryBorder: "1px solid #8282828a",
  secondaryBorder: "1px solid #4444442e",
  layoutBorder: "6px solid #F6F6F6",
  addPostBorder: "1px solid #D1D5DB",
  linearGradient:
    "linear-gradient(90deg, rgba(201,72,137,1) 0%, rgba(224,70,70,1) 100%)",
  linearGradientOpacity:
    "linear-gradient(90deg, rgba(201,72,137,0.2) 0%, rgba(224,70,70,0.2) 100%)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};
