import styled from "styled-components"

export const Container = styled.div`
  max-width: 360px;
  margin-top: 15px;
  margin-left: 20px;
  p {
    margin: 6px 0;
  }
  .default-card {
    margin: 6px 0;

    input {
      background: ${({ theme }) => theme.backgroundGrey};
      border: none;
      width: 100%;
      height: 36px;
      border-radius: 7px;
      margin: 6px 0;
      padding-left: 13px;
    }
  }
  .updated-card {
    input {
      border: 1px solid black;
      width: 100%;
      height: 36px;
      border-radius: 7px;
      margin: 6px 0;
      padding-left: 13px;
    }
  }
  .input {
    border: 1px solid black;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    margin: 6px 0;
    padding-left: 13px;
  }
  .zero_subscription {
    display: flex;
    place-items: center;
    place-content: center;
    height: 100%;
    width: 100%;
  }
`

export const SectionWrapper = styled.div`
  margin: 12px 0;
  margin-top: 34px;
  .title {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #000000;
  }
  .save_changes_button {
    margin-top: 13px;
    width: 150px;
  }
`

export const UpdatePlanContainer = styled.div`
  .plans_wrapper {
    border: 1px solid #828282;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 105px;
    margin: 10px 0;
    justify-content: space-evenly;

    .plan {
      display: flex;
      align-items: center;
      width: 80%;
      input {
        margin-right: 15px;
        -webkit-appearance: none;
        background-color: white;
        border: 1px solid #828282;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        display: inline-block;
        position: relative;
        border-radius: 100px;
        overflow: hidden;

        &:checked {
          margin-right: 12px;

          padding: 6px;
          border: 4px solid ${({ theme }) => theme.secondaryColor};
        }
      }
      p {
        font-weight: 600;
        font-size: 19px;
        span {
          color: rgba(0, 0, 0, 0.58);
          margin-left: 10px;
        }
      }
    }
  }

  .update_plane_note {
    font-size: 16px;
    color: #828282;
  }
`

export const CommunityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  span {
    text-decoration-line: none;
    font-size: 17px;
    font-weight: 400;
  }
  .community {
    display: flex;
    align-items: center;
  }
  .community_info {
    display: flex;
    flex-direction: column;
    margin-right: 37px;
    margin-left: 6px;
    .community_name {
      color: black;
    }
    span {
      color: #828282;
    }
  }
  img {
    height: 45px;
    width: 45px;
    border-radius: 15px;
  }
  .edit_button {
    text-align: center;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 6px;
    padding: 4px 6px;
    min-width: 66px;
    font-weight: 600;
  }
  .community_name {
  }
  .renews_date {
  }
`

export const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
