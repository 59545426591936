import { Oval } from "react-loader-spinner"

export default function CircleSpinner(props) {
  return (
    <Oval
      height={props?.height ? props?.height : "25"}
      width={props?.width ? props?.width : "25"}
      color={props?.color ? props?.color : "#FFF"}
      secondaryColor="#C94889"
      ariaLabel="loading"
      radius={8}
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
      }}
      {...props}
    />
  )
}
