import { useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import PhoneInput from "react-phone-number-input"

import { Button, Text } from "components/common/Kit"
import * as authAPi from "api/auth"
import { LeftArrow } from "components/icons"
import { useState } from "react"

const useFormValues = () => useFormContext()

export const PhoneNumber = ({ step, setStep, phoneNumber, setPhoneNumber }) => {
  const [phoneNumberError, setError] = useState(false)
  const { isLoading, mutateAsync: verifyPhoneNumber } = useMutation(
    authAPi.validatePhoneNumber
  )
  const navigate = useNavigate()
  const {
    formState: { errors },

    getValues,
    handleSubmit,
  } = useFormValues()
  const data = getValues()

  const onSubmit = async () => {
    if (phoneNumber === undefined) {
      setError("Phone number is required")
      return
    }
    data.isoCode = "en-US"
    data.phoneNumber = phoneNumber
    const body = await verifyPhoneNumber(data)
    if (body.success) return setStep(3)
    if (!body.success) {
      return setError("phone is already taken! or invalid")
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-title">
        <LeftArrow
          className="left-arrow"
          onClick={() =>
            step === 1 ? navigate(-1) : setStep((step) => step - 1)
          }
        />
        You're almost there!
      </h3>
      <label htmlFor="phoneNumber">Phone Number</label>
      <PhoneInput
        name="phoneNumber"
        defaultCountry="US"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(e) => {
          setError(null)
          setPhoneNumber(e)
        }}
        className="custom-phoneInput"
        withCountryCallingCode
        style={{ textAlign: "center" }}
      />
      {phoneNumberError && <Text>{phoneNumberError}</Text>}
      <Button
        style={{
          marginTop: "2rem",
        }}
        isDisabled={errors.phoneNumber?.message?.length >= 1}
        isLoading={isLoading}
      >
        Send Code
      </Button>
    </form>
  )
}
