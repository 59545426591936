import styled from "styled-components";

export const DropDownContainer = styled.div`
  position: absolute;
  height: auto;
  width: 21rem;
  top: 3rem;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: white;
  overflow-y: scroll;

  .dd-header {
    padding: 1rem;
    .title {
      color: ${({ theme }) => theme.lightGrey};
      margin-top: 0.5rem;
    }
  }
  .dd-search {
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.backgroundGrey};
    padding: 0.5rem;
    border-radius: 0.6rem;

    .search-icon {
      margin-right: 0.6rem;
    }
    input {
      background: transparent;
      border: none;
      width: 90%;
    }
  }

  .channels-list {
    list-style: none;

    li {
      padding: 1rem;

      &:hover {
        background: ${({ theme }) => theme.linearGradientOpacity};
      }
    }
  }

  ${({ cssStyles }) => cssStyles}
`;
