import React from "react"
import { ThemeProvider } from "styled-components"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { QueryClient, QueryClientProvider } from "react-query"
import { HelmetProvider } from "react-helmet-async"
import GlobalCSS from "./styles/global.styles"
import { theme } from "./styles/theme"
import * as modal from "context/modal"
import * as user from "context/user"
import * as rightPanel from "context/rightPanel"

import "./index.css"
import Router from "./pages"
import { ReactQueryDevtools } from "react-query/devtools"
import ErrorBoundary from "services/ErrorBoundary"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  },
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalCSS />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <user.Provider>
            <modal.Provider>
              <rightPanel.Provider>
                <HelmetProvider>
                  <Router />
                </HelmetProvider>
              </rightPanel.Provider>
            </modal.Provider>
          </user.Provider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
