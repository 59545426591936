import styled from "styled-components";

export const MySignUpInfo = styled.div`
  text-align: center;

  .already-member {
    font-weight: 500;
    line-height: 1.7;
    margin-top: 1rem;

    span {
      color: ${({ theme }) => theme.secondaryColor};
      background: ${({ theme }) => theme.linearGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .terms {
    font-size: 1.5rem;
    margin-top: 1rem;
    line-height: 1.5;
    max-width: 37rem;
  }
`;
