import styled from "styled-components"

export const SubscriptionSettingsWrapper = styled.div`
  margin-top: 20px;
  padding: 4px 2rem;
  padding-bottom: 3rem;
  max-width: 526px;
  .note {
    font-weight: 400;
    font-size: 17px;

    span {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  .update_plan_button {
    width: 259.5px;
    margin: 22px 0;
  }

  .section_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .stripe_link {
      text-align: center;
      margin-top: 10px;
      display: inline-block;
      width: 167px;
      padding: 10px 0;
      background: #ffffff;
      border-radius: 8px;
      color: #635afb;
      border: 1px solid #635afb;
    }
    .plans {
    }
  }
  .title {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid black;
    color: #000000;
    margin-bottom: 10px;
  }
  .free_trails_input {
    display: flex;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 8px;
    span {
      font-size: 19px;
      font-weight: 600;
    }
    input {
      margin-right: 15px;
      -webkit-appearance: none;
      background-color: white;
      border: 1px solid #828282;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      border-radius: 100px;
      overflow: hidden;
      width: 20px;

      &:checked {
        margin-right: 12px;
        padding: 6px;
        border: 4px solid ${({ theme }) => theme.secondaryColor};
      }
    }
  }
  .earning_calculator_wrapper {
    border-radius: 6px;
    background: ${({ theme }) => theme.linearGradient};
    padding: 18px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
    }
    .earning_calculator_price {
      margin: 8px 0;
      color: white;
      p {
        font-weight: 600;
        font-size: 12px;
      }
      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
      }
    }
    .price_inputs {
      width: 85%;
      margin: 0 auto;
      fieldset:first-child {
        input {
          width: 100%;
          border: none;
        }
      }

      fieldset {
        display: flex;
        border: none;
        label {
          color: white;
        }
        div {
          width: 50%;
        }
        input {
          background-color: white;
        }
        span {
          background-color: white;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  fieldset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    div {
      width: 143px;
      height: 39px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      border: 1px solid #828282;
      overflow: hidden;

      input {
        width: 64%;
        height: 100%;
        border: none;
        border-right: 1px solid black;
        text-align: center;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        background-color: transparent;
      }
      span {
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        text-align: center;
        width: 36%;
      }
    }
  }
`

export const EarningCalculatorWrapper = styled.div`
  background: linear-gradient(
    -27.44deg,
    rgba(224, 70, 70, 0.2),
    rgba(201, 72, 137, 0.2)
  );

  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 37px 17px;
  .title {
    text-align: center;
    margin-top: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
  }
  .earning_calculator_price {
    text-align: center;
    margin: 34px 0;
    span {
      font-weight: 400;
      font-size: 64px;
      line-height: 78px;
    }
  }
  fieldset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    label {
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
    }
    input {
      height: 100%;
      height: 41px;
      text-align: center;
      border: none;
      background: "#000000";
    }
    .input_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #000000;
      border-radius: 5px;
      width: 50%;
      .subscription_price_input {
        width: 70%;
      }
      span {
        width: 30%;
        text-align: center;
      }
    }
    .currency {
      span {
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        text-align: center;
        width: 36%;
      }
    }
  }
  .input_range_wrapper {
    margin-top: 40px;
    input {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`
