export const root = "/"

/**
 * Auth
 */
export const signUp = "auth/signup"
export const signUpForInvite = "auth/signup"
export const signUpForCreator = "auth/signup/creator"
export const SignUpForSubscribers = "/auth/signup/subscriber"
export const forgotPassword = "auth/forgot-password"
export const invite = "/sign-up"

/**
 * Mobile pages
 */

export const mobileNotifications = "/m/notifications"
export const mobileConversations = "/m/conversations"
export const mobileFollowingAndFollowers = "/m/following"
export const Profile = "/profile"
export const ViewProfile = "/profile/:profilename"

/**
 * Home pages
 */

export const homepage = "/home"

export const messages = "/messages"

export const topConversations = "/room/top-conversations"
export const mobileTopConversation = "/room/m/top-conversations"
export const following = "/room/following"

/**
 * Room pages
 */
export const roomPage = "/room"
export const specifiedRoom = "/room/:pagename"
export const roomManagement = "/room-management"

/**
 * Support page
 */

export const support = "/support"

/**
 * Setting page
 */

export const createCommunity = "/community/create"

export const profileSetting = "/account"
export const subscriptionSettings = "/settings/subscriptions"
export const profileManegeSubscription = "/settings/mange-subscription"
export const communitySettings = "/community"
export const communitySubscribe = "/community/subscribe"
export const communityCreation = "/community/create"
export const communityCreationPayment = "/community/create/payment"
export const communityMemberManagement = "/member-management"

export const welcomePage = "/home"

//

export const subscriber = "/subscribe"
