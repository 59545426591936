import {
  createContext,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from "react"
import useLocalStorage from "hooks/useLocalStorage"
import { useFetchUserProfile } from "api/users/useFetchUserProfile"
import { useQuery } from "react-query"
import { getSpecificCommunityByCreator } from "api/communities"

const userContext = createContext(null)

export function Provider({ children }) {
  const userLocalStorage = JSON.parse(localStorage.getItem("user"))?.userName

  const [auth, setAuth] = useLocalStorage("auth", null)
  const [user, setUser] = useState(userLocalStorage ? userLocalStorage : null)
  const [currentCommunity, setCurrentCommunity] = useState()

  const [status, setStatus] = useState()

  const token = localStorage.getItem("auth")

  const reloadFn = () => {
    localStorage.removeItem("auth")
    localStorage.removeItem("user")
    setStatus("auth")
    window.location.href = "/"
  }

  useFetchUserProfile({
    config: {
      onSuccess: (data) => {
        setUser(data?.user)
      },
    },
  })

  useQuery(
    ["communityByCreator", user?.id],
    () =>
      getSpecificCommunityByCreator({
        creatorUserName: user?.userName,
        getSubscriptions: true,
      }),
    {
      onSuccess: (data) => {
        setCurrentCommunity(data?.community)
      },
      enabled: !!user?.userName,
    }
  )

  useLayoutEffect(() => {
    if (token) {
      return setStatus("")
    }
    if (!token && status === "") {
      setStatus("auth")
      reloadFn()
    }
  }, [token, status])

  useEffect(() => {
    setUser(userLocalStorage)
  }, [userLocalStorage])

  return (
    <userContext.Provider
      value={{
        status,
        setStatus,
        user,
        setUser,
        currentCommunity,
        setCurrentCommunity,
        isAuthenticated: !!auth,
        signIn: (access) => {
          setAuth({ access })
        },
        signOut: () => {
          setAuth(null)
          setUser(null)
        },
      }}
    >
      {children}
    </userContext.Provider>
  )
}
userContext.displayName = "user"
export const useUser = () => useContext(userContext)
