import * as React from "react"

export function BurgerMenu(props) {
  return (
    <svg
      width={30}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 20H0v-3.333h30V20zm0-8.333H0V8.333h30v3.334zm0-8.334H0V0h30v3.333z" />
    </svg>
  )
}
