import { request, requestWithImage } from "./client"

const prefix = "/community"

export const getCommunity = (data) => request("post", prefix, data)

export const getListCommunities = (data) =>
  request("post", `${prefix}/list`, data)

export const getCommunityListUsers = (data) => {
  return request("post", `${prefix}/list-users`, data)
}

export const getSpecificCommunityByCreator = (data) =>
  request("post", `${prefix}/creator`, data)

export const updateCommunity = (data) =>
  request("post", `${prefix}/update`, data)

export const updateCommunityLogo = (data) =>
  requestWithImage("post", `${prefix}/update-logo`, data, true)

export const deleteUserFromCommunity = (data) =>
  request("post", `${prefix}/delete-user  `, data)

export const banUserFromCommunity = (data) =>
  request("post", `${prefix}/ban-user  `, data)

export const retrieveCommunityByUrl = (data) =>
  request("post", `${prefix}/retrieve-byurl`, data)

export const getListUserCommunities = (data) =>
  request("post", `${prefix}/listUserCommunities`, data)

export const getLastFourDigitsCars = (data) =>
  request("post", `${prefix}/user-credit-card`, data)
