import { request } from "api/client"

import { useQuery } from "react-query"

const apiCall = (data) => request("post", `/user/profile`, data)

export const useFetchUserProfile = ({ config }) => {
  const user = JSON.parse(localStorage.getItem("user"))

  return useQuery(
    "userProfile",
    () =>
      apiCall({
        requestedUser: user?.userName,
      }),

    { refreshOnMount: false, ...config }
  )
}
