import { devices } from "constant"
import styled from "styled-components"

export const MyInvite = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 85vh;
  width: 90%;
  img {
    @media ${devices.laptop} {
      width: 50%;
    }
    @media ${devices.tablet} {
      display: none;
    }
  }

  .form-container {
    max-width: 40rem;
    @media ${devices.laptop} {
      width: 50%;
    }
    @media ${devices.tablet} {
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .error_message {
      margin-top: 0px;
      font-size: 16px;
      margin-bottom: 8px;
    }
    label {
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .invite-code-input {
      margin-bottom: 2rem;
    }
  }

  .signUp-info {
    text-align: center;

    .already-member {
      font-weight: 500;
      line-height: 1.7;
      margin-top: 1rem;

      span {
        color: ${({ theme }) => theme.secondaryColor};
      }
    }

    .terms {
      font-size: 1.5rem;
      margin-top: 1rem;
      line-height: 1.5;
    }
  }
`
