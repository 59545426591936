import { devices } from "constant"
import styled from "styled-components"

export const MyFooter = styled.footer`
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-bottom: 3.2rem;
  }

  /* Utilities */
  .container {
    max-width: 1260px;
    margin: 0 auto;
    overflow: auto;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .flex-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* Top Navigation */
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
    padding: 0;
    list-style: none;
    font-size: 15px;
  }

  /* Links */
  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: #fff;
  }

  .footer a:hover {
    color: inherit;
  }

  /* Footer */
  .footer {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.footerBackground};
  }

  .footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 14px;
  }

  .footer p {
    font-size: 15px;
    line-height: 1.6rem;
    padding-bottom: 0;
  }

  .footer .nav-list {
    height: 100%;
    margin: 0;
  }

  @media ${devices.mobileXl} {
    .flex-nav {
      text-align: center;
      justify-content: center;
    }

    .footer .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .brand_name {
      padding: 2rem;
    }

    ul {
      width: 100%;
      margin-bottom: 12px;
    }

    li {
      font-size: 13px;
    }
  }
`
