import React, { useState } from "react"
import DropDownReasons from "./elements/dropDown/dropDown"
import {
  InputsWrapper,
  SupportPageWrapper,
  SupportRightSideWrapper,
} from "./support.style"
import useClickOutside from "hooks/useClickOutside"
import { DownArrow, ExternalLink } from "components/icons"
import { CustomHeader } from "components/common"
import { RightSideContainer } from "pages/settings/communitySettings/communitySettings.style"
import { useMutation } from "react-query"
import { submitSupportEmail } from "api/meta"
import { useUser } from "context/user"
import { Helmet } from "react-helmet-async"

const reasonsForYourReport = [
  { name: "A problem with the platform", selected: false },
  { name: "An issue with billing", selected: false },
  { name: "Harassment or bullying", selected: false },
  { name: "Violent and graphic content", selected: false },
  { name: "Pornographic content", selected: false },
  { name: "Spam", selected: false },
  { name: "Other", selected: false },
]

const Support = () => {
  const [selectedChannel, setSelectedChannel] = useState()
  const [message, setMessage] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState(false)
  const { isVisible, setIsVisible, ref } = useClickOutside(false)
  const { user } = useUser()

  const handleSelect = (value) => {
    setIsVisible(false)
    setSelectedChannel(value)
  }

  const { mutate: submitSupportEmailFn, isLoading } = useMutation(
    submitSupportEmail,
    {
      onSuccess: () => {
        setSuccess(true)
      },
    }
  )

  const onSubmit = () => {
    if (!selectedChannel && !message) {
      return setError({
        reason: "Please Select reason",
        message: "Please write message",
      })
    }
    if (!selectedChannel) {
      return setError({
        reason: "Please Select reason",
      })
    }
    if (!message) {
      return setError({
        message: "Please Select reason",
      })
    }
    submitSupportEmailFn({
      submittingUserName: user?.userName,
      flagReasonName: selectedChannel,
      supportRequest: message,
    })
  }

  return (
    <>
      <Helmet>
        <title>Support | Rantt</title>
      </Helmet>
      <SupportPageWrapper>
        <CustomHeader
          title="Support"
          description="Report a problem or bring something to our attention"
          buttonTitle="Send report"
          buttonOnClick={() => onSubmit()}
          isLoading={isLoading}
        />
        <InputsWrapper>
          <div className="support_mobile">
            <h1>Get in touch!</h1>
            <p>Contact us at support@rantt.com</p>
          </div>
          <span className="title">
            FAQs <ExternalLink />
          </span>
          <div className="labelContainer">
            <label htmlFor="update-email">
              I want to report:
              <span
                className="select-reason"
                onClick={() => setIsVisible(!isVisible)}
              >
                {" "}
                {selectedChannel ? selectedChannel : "Select reason"}
                <DownArrow />
              </span>
            </label>{" "}
            {isVisible && (
              <DropDownReasons
                handleSelect={handleSelect}
                ref={ref}
                cssStyles={{ right: -50 }}
                data={reasonsForYourReport}
              />
            )}
          </div>
          {success && (
            <span className="success_message">Request Sent Successfully</span>
          )}
          {error?.reason && (
            <span className="error_message">{error?.reason}</span>
          )}
          <textarea
            type="text"
            placeholder="Start typing..."
            resize={false}
            onChange={(e) => setMessage(e.target.value)}
          />
          {error?.message && (
            <span className="error_message">{error?.message}</span>
          )}
        </InputsWrapper>
      </SupportPageWrapper>
      <SupportRightSide />
    </>
  )
}
const SupportRightSide = () => {
  return (
    <RightSideContainer>
      <SupportRightSideWrapper>
        <div className="right_side_container">
          <h1>Get in touch!</h1>

          <p>Contact us at support@rantt.com</p>
        </div>
      </SupportRightSideWrapper>
    </RightSideContainer>
  )
}

export default Support
