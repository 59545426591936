import { request } from "api/client"
import { useMutation } from "react-query"

export const apiCall = (data) => {
  return request("post", "/community/update-social-links", data)
}

export const useUpdateSocialLinks = ({ config = {} }) => {
  return useMutation(apiCall, { ...config })
}
