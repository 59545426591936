import React from "react"
import { useNavigate } from "react-router"
import { HeaderContainer } from "./customHeader.style"
import { BackArrow } from "components/icons/backArrow"
import { Button } from "../Kit"

export default function Header({
  title,
  description,
  buttonOnClick,
  buttonTitle,
  isLoading,
}) {
  const navigate = useNavigate()

  return (
    <HeaderContainer>
      <div className="">
        <div className="back" onClick={() => navigate(-1)}>
          <BackArrow />
          <h2>{title}</h2>
        </div>
        <span>{description}</span>
      </div>
      {buttonTitle && (
        <Button onClick={buttonOnClick} isLoading={isLoading}>
          {buttonTitle}
        </Button>
      )}
    </HeaderContainer>
  )
}
