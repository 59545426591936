import * as React from "react"

export function WebsiteLink(props) {
  return (
    <svg
      width={26}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.898 10.498a6.147 6.147 0 000-8.697 6.148 6.148 0 00-7.922-.655l-.068.047a1.357 1.357 0 00-.315 1.897 1.361 1.361 0 001.896.314l.069-.046a3.427 3.427 0 014.414 5.214L17.2 13.35a3.426 3.426 0 01-4.848 0 3.424 3.424 0 01-.365-4.414l.046-.068a1.36 1.36 0 00-.314-1.897 1.364 1.364 0 00-1.897.315l-.047.068a6.147 6.147 0 009.347 7.918l4.776-4.775zM1.802 9.502a6.147 6.147 0 000 8.697 6.148 6.148 0 007.922.655l.068-.047a1.357 1.357 0 00.315-1.897 1.361 1.361 0 00-1.896-.314l-.069.046a3.423 3.423 0 01-4.414-.365 3.432 3.432 0 010-4.852L8.5 6.649a3.43 3.43 0 015.214 4.418l-.048.068a1.36 1.36 0 002.212 1.582l.047-.068a6.147 6.147 0 00-9.347-7.922L1.802 9.502z"
        fill="#828282"
      />
    </svg>
  )
}
