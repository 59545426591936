import styled from "styled-components";

export default styled.input`
  padding: 1rem;
  background-color: ${({ theme }) => theme.backgroundGrey};
  text-align: center;
  outline: none;
  border: none;
  border-radius: 1.5rem;
  width: 100%;
  height: 5rem;

  ::-webkit-input-placeholder {
    text-align: center;
  }

  ${({ error, theme }) => error && `border: 1px solid ${theme.primaryColor}`}
`;
