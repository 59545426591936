import { devices } from "constant"
import styled from "styled-components"

export const SubscriberWrapper = styled.div`
  min-height: calc(100vh - 6rem);
  width: 100%;
  .contain_wrapper {
    max-width: 680px;
    margin: 0 auto;
    transform: translateY(-60px);
    padding: 15px 15px;
  }
  .section_wrapper {
    margin-top: 14px;
  }
  .title {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid black;
    color: #000000;
    margin-bottom: 16px;
  }
  .community_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 120px;
      max-height: 120px;
      border-radius: 8px;
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
    }
    p:last-child {
      margin-top: 25px;
    }
    .community_info_launch_members {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
      height: 35px;
      background: ${({ theme }) => theme.linearGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      .divider {
        display: block;
        width: 6px;
        height: 100%;
        margin: 0 14px;
        background: ${({ theme }) => theme.linearGradient};
      }
      .launch_community {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: ${({ theme }) => theme.secondaryColor};
      }
      .num_members_community {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: ${({ theme }) => theme.secondaryColor};
        font-size: 22px;
        line-height: 27px;
      }
      span {
        color: ${({ theme }) => theme.primaryColor};
        font-size: 12px;
        line-height: 14px;
      }
      p {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }
  .plans_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 27px 0;

    .plan {
      padding: 20px;
      border: 1px solid #828282;
      border-radius: 6px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      width: 48%;
      border: ${({ checked, theme }) =>
        checked ? "4px solid" + theme.secondaryColor : ""};

      input {
        margin-right: 15px;
        -webkit-appearance: none;
        background-color: white;
        border: 1px solid #828282;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        display: inline-block;
        position: relative;
        border-radius: 100px;
        overflow: hidden;
        &:checked {
          margin-right: 12px;
          padding: 6px;
          border: 4px solid ${({ theme }) => theme.secondaryColor};
        }
      }
      p {
        font-weight: 500;
        font-size: 19px;
      }
      span {
        color: rgba(0, 0, 0, 0.58);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 18px 0;
        display: block;
      }
    }
  }

  .note_about_subscription {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  @media ${devices.mobileXl} {
    form {
      max-width: unset;
    }
    .plans_wrapper {
      max-width: unset;
    }
    .community_info {
      .logo {
        height: 85px;
        width: 85px;
      }
    }
  }
`
