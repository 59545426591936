import { RightArrow } from "components/icons"
import { BackArrow } from "components/icons/backArrow"
import { Link } from "react-router-dom"
import {
  CommunityRightSideWrapper,
  RightSideContainer,
} from "../communitySettings.style"
import * as paths from "paths"
import React, { useState } from "react"
import { useUpdateSocialLinks } from "api/community/useUpdateSocialLinks"
import { useForm } from "react-hook-form"
import { Button } from "components/common/Kit"
import { WebsiteLink } from "components/icons"
import { useCommunitySettings } from "../useCommunitySettings"
import { misc, Social, streaming } from "constant/socialLinks"
import { useMutation } from "react-query"
import { updateCommunity } from "api/communities"

export const RightSide = ({
  showRightPanelInMobile,
  closeRightPanelInMobileVersion,
}) => {
  const [openLinks, setOpenLinks] = useState()
  return (
    <RightSideContainer show={showRightPanelInMobile}>
      <>
        {openLinks ? (
          <Links close={setOpenLinks} />
        ) : (
          <>
            <div className="header">
              {showRightPanelInMobile && (
                <BackArrow onClick={() => closeRightPanelInMobileVersion()} />
              )}
              <h1>More customization</h1>
            </div>
            <CommunityRightSideWrapper>
              <Link to={paths.subscriptionSettings}>
                <span>
                  Manage your subscription plans <RightArrow />
                </span>
              </Link>

              <span
                onClick={() => setOpenLinks(true)}
                className="cursor-pointer"
              >
                Link your profiles <RightArrow />
              </span>
            </CommunityRightSideWrapper>
          </>
        )}
      </>
    </RightSideContainer>
  )
}

const Links = ({ close }) => {
  const [successMessage, setSuccessMessage] = useState()
  const { community } = useCommunitySettings()

  const result =
    community &&
    community?.socialMediaLinks?.reduce(
      (obj, cur) => ({
        ...obj,
        [cur.socialMediaName === "apple.podcast"
          ? "apple-podcast"
          : cur.socialMediaName]: cur.profileUri,
      }),
      {}
    )

  const { handleSubmit, register, watch } = useForm({
    defaultValues: { ...result, website: community?.website },
  })

  const {
    mutateAsync: updateCommunityFn,
    isLoading: loadingUpdateSocialMedia,
  } = useMutation(updateCommunity, {})

  const { mutateAsync: updateSocialMediaLinks, isLoading } =
    useUpdateSocialLinks({
      config: {
        onSuccess: (data) => {
          const website = watch("website")

          if (website) {
            updateCommunityFn({
              communitySave: { ...community, website },
            })
          }
          setSuccessMessage("updated social media links successfully")
          setTimeout(() => {
            setSuccessMessage(null)
          }, [2000])
        },
      },
    })

  console.log(successMessage, "🥲")
  const onSubmit = (data) => {
    const dataResults = Object.entries(data)?.filter((v) => v[0] !== "website")
    updateSocialMediaLinks({
      communityId: community?.id,
      socialMediaLinks: dataResults?.map((va) => ({
        socialMediaName: va[0] === "apple-podcast" ? "apple.podcast" : va[0],
        profileUri: va[1],
      })),
    })
  }

  return (
    <div className="overflow-y-scroll h-full">
      <div className="cursor-pointer relative  flex justify-center items-center">
        <BackArrow onClick={() => close(false)} className="absolute left-5 " />
        <h1 className="">Links</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-10/12 mx-auto py-8">
        <h1 className="border-b-2 !text-left mb-8  border-black font-semibold !text-3xl">
          Website
        </h1>
        <Input {...register("website")} Icon={WebsiteLink} />

        <h1 className="border-b-2 !text-left mb-8  border-black font-semibold !text-3xl mt-14">
          Social
        </h1>
        {Social?.map((social) => (
          <Input
            Icon={social.icon}
            key={social.name}
            {...register(social?.name, {})}
          />
        ))}
        <h1 className="border-b-2 !text-left mb-8  border-black font-semibold !text-3xl mt-14">
          Streaming
        </h1>
        {streaming?.map((social) => (
          <Input
            Icon={social.icon}
            key={social.name}
            {...register(social?.name, {})}
          />
        ))}

        <h1 className="border-b-2 !text-left mb-8  border-black font-semibold !text-3xl mt-14">
          Misc
        </h1>
        {misc?.map((social) => (
          <Input
            Icon={social.icon}
            key={social.name}
            {...register(social?.name, {})}
          />
        ))}
        {successMessage && (
          <span className="success_message">{successMessage}</span>
        )}
        <Button isLoading={isLoading || loadingUpdateSocialMedia}>
          Submit
        </Button>
      </form>
    </div>
  )
}

const Input = React.forwardRef(({ Icon, ...rest }, ref) => {
  return (
    <div className="flex items-center border border-[#828282] rounded-lg  mb-7">
      <div className="p-2 border-r px-4 border-[#828282]">{<Icon />}</div>
      <input ref={ref} className="p-2 w-full pl-3" {...rest} />
    </div>
  )
})
