import { devices } from "constant"
import styled from "styled-components"

export const CommunitySettingPageContainer = styled.main`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
`
export const RightSideContainer = styled.div`
  height: 100%;
  width: 35%;
  border-left: 5px solid ${({ theme }) => theme.backgroundGrey};
  padding-top: 3rem;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  @media ${devices.tablet} {
    display: none;
  }
`
export const CommunitySettingContainer = styled.form`
  margin-top: 20px;
  padding: 4px 2rem;
  padding-bottom: 3rem;
  max-width: 526px;
  height: auto;

  .section_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      border-radius: 7px;
      border: 1px solid #828282;
      height: 36px;
      padding-left: 10px;
    }
    textarea {
      border-radius: 7px;
      border: 1px solid #828282;
      resize: none;
    }
    .community_tagname_input {
      min-height: 70px;
      padding: 5px;
    }
    .about_community_input {
      padding: 9px;
      min-height: 90px;
      margin-top: 12px;
    }
    .community_link {
      margin-top: 18px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      span {
        color: ${({ theme }) => theme.secondaryColor};
      }
      button {
        background: #ffffff;
        border-radius: 32px;
        color: ${({ theme }) => theme.secondaryColor};
        border: 1px solid ${({ theme }) => theme.secondaryColor};
        width: 107px;
        padding: 6px 2px;
      }
    }
  }
  .title {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid black;
    color: #000000;
    margin-bottom: 10px;
  }
`

export const ImageUploadContainer = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  input[type="file"] {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
  svg {
    position: absolute;
    z-index: 33;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CommunityRightSideWrapper = styled.div`
  margin-top: 20px;
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
`
