import * as React from "react"

export function SocialTwitter(props) {
  return (
    <svg
      width={30}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.512 5.981c.02.263.02.525.02.788C26.531 14.775 20.436 24 9.3 24c-3.431 0-6.619-.994-9.3-2.719.488.056.956.075 1.462.075a12.13 12.13 0 007.52-2.587 6.067 6.067 0 01-5.663-4.2c.375.056.75.093 1.144.093a6.41 6.41 0 001.593-.206A6.057 6.057 0 011.2 8.512v-.074a6.1 6.1 0 002.737.768 6.052 6.052 0 01-2.7-5.044c0-1.125.3-2.156.825-3.056A17.215 17.215 0 0014.55 7.444a6.839 6.839 0 01-.15-1.388C14.4 2.72 17.1 0 20.456 0c1.744 0 3.319.731 4.425 1.913A11.923 11.923 0 0028.725.45a6.04 6.04 0 01-2.663 3.337 12.14 12.14 0 003.488-.937 13.016 13.016 0 01-3.038 3.131z"
        fill="#828282"
      />
    </svg>
  )
}
