import * as React from "react"

export function TikTok(props) {
  return (
    <svg
      width={21}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 9.842a9.849 9.849 0 01-5.757-1.84v8.38a7.62 7.62 0 11-6.575-7.553v4.215a3.499 3.499 0 102.449 3.337V0h4.126c-.003.348.026.696.087 1.04a5.73 5.73 0 002.528 3.76 5.693 5.693 0 003.141.945v4.097z"
        fill="#828282"
      />
    </svg>
  )
}
