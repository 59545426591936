import React, { useState } from "react"

import { Close } from "components/icons"
import { useMutation } from "react-query"
import { banUserFromCommunity, deleteUserFromCommunity } from "api/communities"
import { UserPlaceHolder } from "components/icons/UserPlaceHolder"
import { queryClient } from "app"
import { Button } from "components/common/Kit"
import { RemoveModalUserWrapper } from "../memberManagement.style"

export const RemoveUserModal = ({ close, params }) => {
  const [error, setError] = useState()
  const { mutate: deleteUser, isLoading: removeLoading } = useMutation(
    deleteUserFromCommunity,
    {
      onSuccess: () => {
        close()
        queryClient.invalidateQueries("communityListUsers")
      },
    }
  )

  const { mutate: banUserFn, isLoading: banLoading } = useMutation(
    banUserFromCommunity,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("communityListUsers")
        close()
      },
      onError: (err) => {
        if (err) {
          setError(true)
        }
      },
    }
  )
  const user = params

  return (
    <RemoveModalUserWrapper>
      <div className="header_wrapper">
        <Close onClick={() => close()} />
        <p>{params?.active === "remove" ? "Remove" : "Ban"} member</p>
      </div>
      <div className="modal_content">
        <span className="text-xl text-red-400">
          Are you sure you want to{" "}
          {params?.active === "remove" ? "Remove" : "Ban"} this member?
        </span>
        <div className="user_profile">
          {user?.userSummary?.userAvatar?.uri?.length > 1 ? (
            <img
              src={user?.userSummary?.userAvatar?.uri}
              alt=""
              className="img"
            />
          ) : (
            <UserPlaceHolder className="avatar" />
          )}
          <div className="user-info">
            <div className="">
              <span className="name">{user?.userSummary.userDisplayName}</span>{" "}
              <span>{user?.userSummary?.userName}</span>
            </div>
          </div>
        </div>
        <p>
          {params?.active === "remove" ? "Removing" : "Ban"} a member from your
          community will result in the immediate termination of their
          subscription. This action can’t be undone.
        </p>

        {error && <span className="error_message">Something Went Wrong</span>}

        <div className="buttons_wrapper">
          <button className="close_button" onClick={close}>
            Cancel
          </button>{" "}
          <Button
            className="remove_button"
            onClick={() => {
              if (params?.active === "remove") {
                return deleteUser({
                  communityId: user?.communityId,
                  userToDelete: user?.userSummary?.userName,
                })
              }
              banUserFn({
                communityId: user?.communityId,
                bannedUserName: user?.userSummary?.userName,
              })
            }}
            isLoading={removeLoading || banLoading}
          >
            {params?.active === "remove" ? "Remove" : "Ban"}
          </Button>
        </div>
      </div>
    </RemoveModalUserWrapper>
  )
}
