import { devices } from "constant"
import styled from "styled-components"

export const CommunitySettingContainer = styled.div`
  margin-top: 20px;
  padding: 4px 2rem;
  padding-bottom: 30px;
  .wrapper {
  }

  .title {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #000000;
    margin-bottom: 5px;
  }
  .invite_section {
    margin-bottom: 20px;
    button {
      margin-top: 20px;
      width: 160px;
    }
    p {
      margin: 4px 0;
    }
    input {
      display: block;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 6px;
      width: 100%;
      height: 49px;
      padding-left: 8px;
      margin: 7px 0;
    }
  }
`

export const ExistingMembersContainer = styled.div`
  border: 0.5px solid #828282;
  border-radius: 6px;
  margin-top: 19px;
  padding: 6px 15px;
  input {
    background-color: red;
    border: none;
  }
  @media ${devices.mobileXl} {
    padding: 6px 8px;
  }
`
export const SearchContainer = styled.div`
  margin: 4px auto;
  margin-top: 19px;
  padding: 7px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.backgroundGrey};
  border-radius: 6px;
  svg {
    margin-right: 4px;
  }
  input {
    width: 90%;
    border: none;
    background-color: transparent;
  }
`

export const ContentNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 97%;

  span {
    display: block;
    text-align: center;
  }
  span:first-child {
    width: 40%;
    text-align: left;
  }
  span:last-child {
    width: 35%;
  }

  @media ${devices.mobileXl} {
    span:first-child {
      width: 35%;
      text-align: left;
    }
  }
`

export const ListUserContainer = styled.div`
  width: 100%;
  span {
    display: block;
    text-align: center;
  }
  span:last-child {
    width: 35%;
  }
  .option_button {
    position: relative;
    svg {
      cursor: pointer;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  .user_profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    .user-info {
      width: 40%;
      display: flex;
      img {
        border-radius: 100px;
        width: 60px;
        height: 60px;
      }
      span:last-child {
        color: ${({ theme }) => theme.lightGrey};
      }
    }

    span:last-child {
      width: 35%;
    }
  }

  @media ${devices.mobileXl} {
    font-size: 14px;
    .profile {
      border-bottom: 1px solid #f1f1f1;
      width: 100%;
      padding-bottom: 10px;
    }
    .user_profile {
      .user-info {
        width: fit;
        display: flex;
        flex-direction: column;
        width: 120px;
        align-items: flex-start;
        span {
          text-align: left;
          width: 100%;
        }
      }
    }
  }
`

export const DropDownWrapper = styled.div`
  min-width: 224px;
  position: absolute;
  top: 2rem;
  right: 0;
  border-radius: 4px;
  z-index: 3;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  li {
    list-style: none;
    font-size: 18px;
    margin-top: 9px;
    cursor: pointer;
  }
  button {
    color: ${({ theme }) => theme.primaryColor};
    margin-top: 9px;
    background-color: transparent;
  }
`

export const RemoveModalUserWrapper = styled.div`
  max-width: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .header_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #d1d5db;
    height: 55px;
    svg {
      position: absolute;
      left: 15px;
    }
  }
  .modal_content {
    width: 90%;
    margin: 0 auto;
  }
  span {
    margin: 9px 0;
    display: block;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
  }
  .buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 25px;
    button {
      height: 38px;
      font-weight: 600;
      min-width: 100px;
      border-radius: 8px;
    }
    .close_button {
      background: red;
      margin-right: 5px;
      border: 1px solid black;
      background-color: white;
      width: 45%;
    }
    .remove_button {
      margin-left: 5px;
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
      width: 45%;
    }
  }

  .user_profile {
    margin: 11px 0;
    display: flex;

    .img {
      height: 100px;
      width: 100px;
      border-radius: 55px;
      margin-right: 13px;
    }
    .avatar {
      margin-right: 13px;
      margin-top: 10px;
    }
    .user-info {
      margin-top: 10px;
    }

    .name {
      font-size: 18px;
      font-weight: 500;
    }
  }
`
