import React, { useEffect, useMemo, useState } from "react"

import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "react-query"

import { SubscriberWrapper } from "./subscribe.style"
// import Header from "components/common/onBoardingHeader"
import { retrieveCommunityByUrl } from "api/communities"
import { Spinner } from "components/common/Kit"

import Footer from "components/common/Footer"
import { ReactComponent as VerifiedIcon } from "assets/pictures/Verified.svg"

import { Header } from "./Header"
import { PlansWrappers } from "./PlansWrappers"
import { StripeComponent } from "./StripeComponent"
import { SocialIcons } from "constant/socialLinks"
import { cx } from "utils/cx"

const Subscribe = () => {
  const [selectedPlan, setSelectedPlan] = useState()
  const state = useLocation()
  const pathNameSpilled = state?.pathname?.split("/")
  const navigate = useNavigate()

  const {
    mutateAsync,
    data,
    isLoading: loadingRetrieveCommunityByUrl,
  } = useMutation(retrieveCommunityByUrl)
  const community = data?.community

  const creator = community?.creator

  useEffect(() => {
    mutateAsync({
      communityUrl: pathNameSpilled[pathNameSpilled?.length - 1],
    }).then((res) => {
      if (res?.community?.id === "0") {
        navigate("/")
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscriptions = data?.community?.subscriptions

  useEffect(() => {
    subscriptions && setSelectedPlan(subscriptions[0])
  }, [subscriptions])

  const convertTimeStampToDate = (date) => {
    const year = new Date(date * 1000).getFullYear()
    return isNaN(year) ? "-" : year
  }

  const isTrial = subscriptions?.find((item) => item.enableTrial === true)

  const monthlySubscription = useMemo(
    () => subscriptions?.find((subscription) => subscription?.name === "month"),
    [subscriptions]
  )

  const yearlySubscription = useMemo(
    () => subscriptions?.find((subscription) => subscription?.name === "year"),
    [subscriptions]
  )

  const isBestValueAvailable =
    monthlySubscription?.price * 12 > yearlySubscription?.price

  useEffect(() => {
    setSelectedPlan(monthlySubscription)
  }, [monthlySubscription])

  const links = community?.socialMediaLinks?.filter(
    (link) => link.profileUri !== ""
  )

  return (
    <>
      <SubscriberWrapper>
        <Header />
        {loadingRetrieveCommunityByUrl ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="h-[90px] md:h-56 w-full bg-[#F4F4F4]" />
            <main className="contain_wrapper ">
              <div className="community_info text-center">
                {community?.logo?.uri && (
                  <img
                    src={community?.logo?.uri}
                    alt=""
                    className="logo object-center bg-cover"
                  />
                )}
                <div className="flex items-center mt-6 ">
                  <h3 className="!text-[28px] leading-[36px]  md:!text-5xl mr-2   flex-wrap justify-center inline-flex items-center px-3 py-2 font-medium rounded  text-primary-100 ">
                    {community?.name}
                    {
                      <span className="flex items-center  ml-1">
                        <VerifiedIcon className="ml-1  h-[24px] w-[24px]  " />
                      </span>
                    }
                  </h3>
                </div>

                {/* Creator */}
                <div className="flex items-center flex-row mb-5">
                  <span className="font-normal !text-2xl">
                    Created by {creator?.userDisplayName}{" "}
                  </span>
                  {creator?.userAvatar?.uri && (
                    <img
                      alt={creator?.userDisplayName}
                      src={creator?.userAvatar?.uri}
                      className="h-10 ml-4 w-10 !rounded-full"
                    />
                  )}
                </div>
                {links?.length > 0 && (
                  <div
                    className={cx(
                      "  items-center justify-center gap-y-[20px] gap-x-[20px] mb-7",
                      cx(
                        links?.length < 6
                          ? "flex flex-row justify-center"
                          : "grid grid-cols-6 justify-center"
                      )
                    )}
                  >
                    {links.map(({ socialMediaName, profileUri }, i) => (
                      <a
                        key={profileUri + i}
                        href={
                          profileUri?.includes("http") ||
                          profileUri?.includes("https")
                            ? profileUri
                            : "https://" + profileUri
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {SocialIcons[socialMediaName]}
                      </a>
                    ))}
                  </div>
                )}

                {/* TagLine */}
                <p style={{ whiteSpace: "pre-wrap" }}>{community?.tagline}</p>

                {/* Community Numbers */}
                <div className="community_info_launch_members">
                  <div className="launch_community">
                    {/* date of creation of the community  */}
                    <p>
                      {convertTimeStampToDate(community?.createdDate?.seconds)}
                    </p>
                    <span>launched</span>
                  </div>
                  <span className="divider" />
                  <div className="num_members_community">
                    {/* Number of members in  the community  */}
                    <p>{community?.members}</p>
                    <span>members</span>
                  </div>
                </div>
              </div>

              {/* About */}
              <div className="section_wrapper mb-10">
                <span className="title">About this community</span>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {community?.description}
                </p>
              </div>

              {/* Plans */}
              <div className="section_wrapper" id="subscribe">
                <span className="title">Choose a plan</span>
                {isTrial ? (
                  <p className="font-semibold">
                    Start with a 30-day free trial, and choose a plan.
                  </p>
                ) : null}
                <PlansWrappers
                  isBestValueAvailable={isBestValueAvailable}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  subscriptions={subscriptions}
                />
                <StripeComponent
                  communityId={community?.id}
                  selectedPlan={selectedPlan}
                  community={community}
                  subscriptions={subscriptions}
                />
              </div>
            </main>
          </>
        )}
      </SubscriberWrapper>
      <Footer />
    </>
  )
}

export default Subscribe
