/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import React, { useEffect, useState } from "react"
import { SubscriptionSettingsWrapper } from "./subscriptionSettings.style"
import { CustomHeader } from "components/common"
import SettingPageWrapper from "components/common/SettingWrapper"
import { Button, Spinner } from "components/common/Kit"

import {
  createSubscription,
  getCreatorSubscription,
  updateSubscription,
  // deleteSubscription,
} from "api/subscriptions"
import { useUser } from "context/user"
import { queryClient } from "app"

import { getSpecificCommunityByCreator } from "api/communities"
import { convertCentsToDollar } from "utils/convertCentsToDollar"
import { RightSide } from "./RightSide"

const SubscriptionSettings = () => {
  const [successMessage, setSuccessMessage] = useState()
  const { user } = useUser()
  const userUserName = JSON.parse(localStorage.getItem("user"))?.userName

  const { data: communityUser } = useQuery(
    ["communityByCreator", userUserName],
    () =>
      getSpecificCommunityByCreator({
        creatorUserName: userUserName,
        getSubscriptions: true,
      }),
    {
      enabled: userUserName ? true : false,
    }
  )

  const currentCommunity = communityUser?.community

  const {
    mutate: createSubscriptionFm,
    isLoading: isLoadingCreateSubscription,
  } = useMutation(createSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("subscriptions")
    },
  })

  const {
    mutate: updateSubscriptionFn,
    isLoading: isLoadingUpdateSubscription,
  } = useMutation(updateSubscription, {
    onSuccess: (data) => {
      if (data?.success) {
        setSuccessMessage("Your subscription has been updated successfully.")
        setTimeout(() => {
          setSuccessMessage(null)
        }, 4000)
      }
    },
  })

  let numberOfCreates = 0

  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useQuery(
    ["subscriptions", currentCommunity?.id],
    () =>
      getCreatorSubscription({
        communityId: currentCommunity?.id,
      }),
    {
      enabled: currentCommunity?.id ? true : false,
    }
  )
  const { data: Community } = useQuery(["communityByCreator", user?.id], () =>
    getSpecificCommunityByCreator({
      creatorUserName: user?.userName,
      getSubscriptions: true,
    })
  )
  const accountStId =
    Community?.community?.communityMerchantAccounts[0]?.accountId

  const createMonthlySubscription = ({ price }) => {
    createSubscriptionFm({
      accountStId,
      communityId: currentCommunity?.id,
      communityName: currentCommunity?.name,
      subscription: {
        enableTrial: false,
        isActive: true,
        name: "month",
        price: price ? price : 100,
        subscriptionPeriod: { id: 1, name: "month" },
        currency,
        subscriptionMetadata: [],
      },
    })
  }

  const createYearlySubscription = ({ price = 10000 }) => {
    createSubscriptionFm({
      accountStId,
      communityId: currentCommunity?.id,
      communityName: currentCommunity?.name,
      subscription: {
        enableTrial: false,
        isActive: true,
        name: "year",
        price: price,
        subscriptionPeriod: { id: 2, name: "year" },
        currency,
        subscriptionMetadata: [],
      },
    })
  }

  const createSubscriptions = () => {
    numberOfCreates += 2
    if (subscriptions.subscriptions?.length <= 1 || numberOfCreates <= 1) {
      createMonthlySubscription({ price: 1000 })
      createYearlySubscription({ price: 10000 })
    }
  }

  useEffect(() => {
    if (subscriptions?.subscriptions) {
      createSubscriptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCommunity, subscriptions?.subscriptions])

  const updateSubscriptions = ({ price, period, trial, stripePriceId, id }) => {
    updateSubscriptionFn({
      accountStId: accountStId,
      communityId: currentCommunity?.id,
      communityName: currentCommunity?.name,
      subscription: {
        enableTrial: trial,
        id,
        isActive: true,
        name: "Yearly Subscription",
        price,
        subscriptionPeriod: { id: 1, name: period },
        currency,
        subscriptionMetadata: [],
        priceId: stripePriceId,
      },
    })
  }
  const monthlySubscription = subscriptions?.subscriptions?.find(
    (subscription) => subscription.subscriptionPeriod.name === "month"
  )
  const yearlySubscription = subscriptions?.subscriptions?.find(
    (subscription) => subscription.subscriptionPeriod.name === "year"
  )

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      trial: monthlySubscription?.enableTrial,
    },
  })

  useEffect(() => {
    if (yearlySubscription) {
      reset({
        yearly: convertCentsToDollar(yearlySubscription?.price),
        monthly: convertCentsToDollar(monthlySubscription?.price),
        trial:
          monthlySubscription?.enableTrial || yearlySubscription?.enableTrial,
      })
    }
  }, [yearlySubscription, monthlySubscription])

  const update = (data) => {
    if (!monthlySubscription) {
      createMonthlySubscription({ price: data?.monthly * 100 })
    } else {
      if (
        !(monthlySubscription?.price === data?.monthly * 100) ||
        monthlySubscription?.enableTrial !== data?.trial
      ) {
        updateSubscriptions({
          id: monthlySubscription?.id,
          price: data?.monthly * 100,
          period: "month",
          trial: data?.trial,
          stripePriceId: monthlySubscription?.subscriptionMetadata[0]?.priceId,
        })
      }
    }

    if (!yearlySubscription) {
      createYearlySubscription({ price: data?.yearly * 100 })
    } else {
      if (
        !(yearlySubscription?.price === data?.yearly * 100) ||
        yearlySubscription?.enableTrial !== data?.trial
      ) {
        updateSubscriptions({
          id: yearlySubscription?.id,
          price: data?.yearly * 100,
          period: "year",
          trial: data?.trial,
          stripePriceId: yearlySubscription?.subscriptionMetadata[0]?.priceId,
        })
      }
    }
  }

  if (isLoadingSubscriptions) {
    return <Spinner />
  }

  return (
    <>
      <SettingPageWrapper pageTitle="Manage subscriptions">
        <Helmet>
          <title>Subscription settings | Rantt</title>
        </Helmet>
        <CustomHeader
          title="Subscription set-up"
          description="Make adjustments to your subscriber plans."
          isLoading={isLoadingSubscriptions}
        />
        <SubscriptionSettingsWrapper>
          <form onSubmit={handleSubmit(update)}>
            {/*  */}
            <div className="section_wrapper">
              <h2 className="title">Stripe dashboard</h2>
              <p>Click the button below to visit your Stripe dashboard</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://dashboard.stripe.com/"
                className="stripe_link"
              >
                Go to Stripe{" "}
              </a>
            </div>

            {/*  */}
            <div className="section_wrapper">
              <h2 className="title">Manage your subscription plans</h2>
              <p>
                Determine how much you’ll charge members to join your community.
              </p>
              <div className="plans">
                <fieldset>
                  <label>Monthly subscription</label>
                  <div>
                    <input
                      type="number"
                      pattern="^\d*(\.\d{0,2})?$"
                      step=".01"
                      min="0"
                      {...register("monthly")}
                    />

                    <span>USD</span>
                  </div>
                </fieldset>

                <fieldset>
                  <label>Yearly subscription</label>
                  <div>
                    <input
                      type="number"
                      pattern="^\d*(\.\d{0,2})?$"
                      step=".01"
                      min="0"
                      {...register("yearly")}
                    />
                    <span>USD</span>
                  </div>
                </fieldset>
              </div>
              <div className="free_trails_input">
                <input type="checkbox" {...register("trial")} />
                <span>Enable 30-day free trials</span>
              </div>

              <p>
                Offer a 30-day free trial to give your members a chance to
                experience your community before being billed.{" "}
              </p>
            </div>
            {successMessage && (
              <span className="block mt-4 text-green-600">
                {successMessage}
              </span>
            )}
            <Button
              className="update_plan_button"
              isLoading={
                isLoadingUpdateSubscription || isLoadingCreateSubscription
              }
            >
              Update plan
            </Button>
          </form>
          <p className="note">
            PLEASE READ:{"  "}
            <span>
              Rate changes will only apply to new subscribers. Existing
              subscribers will continue to pay the rate they originally signed
              up for.
            </span>
          </p>
        </SubscriptionSettingsWrapper>
      </SettingPageWrapper>
      <RightSide />
    </>
  )
}

export default SubscriptionSettings

const currency = {
  isoCodes: [],
  id: 1,
  code: "USD",
  symbol: "$",
  countryCode: "US",
}
