import { devices } from "constant"
import styled from "styled-components"

export const FormElementsStyle = styled.form`
  form {
    max-width: 380px;
    .submit_button {
      border-radius: 8px;
      background: ${({ theme }) => theme.linearGradient};
      color: white;
      width: 100%;
      height: 60px;
      margin-top: 21px;
    }
  }
`

export const InputsWrapper = styled.div`
  width: 100%;
  margin: auto;
  margin: 10px 0;

  #redirect_message {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    margin: 16px 0;
  }

  label {
    display: block;
    margin-bottom: 6px;
  }
  .email {
    width: 100%;
  }
  .card_number {
    display: flex;
    flex-direction: column;
  }

  .card {
    padding: 15px 60px;
    border-radius: 6px;
  }

  input {
    margin-top: 4px;
    padding: 15px 60px;
    font-family: "Figtree", sans-serif;
    font-size: 14px;
  }
  input::placeholder {
    color: #828282;
    font-family: "Figtree", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  @media ${devices.mobileXl} {
    padding: 0;
    input {
      padding: 10px 15px;
    }
    .card {
      padding: 10px 15px;
    }
  }
`
