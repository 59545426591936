import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { Helmet } from "react-helmet-async"

import BodyImage from "components/common/BodyImage"
import { MySignUp } from "./signup.styles.js"
import SignInInfo from "components/common/SigninInfo"
import { Spinner } from "components/common/Kit"
import * as authAPi from "api/auth"
import { useUser } from "context/user.jsx"
import { getDeviceName } from "utils/getDeviceName.js"
import useLocalStorage from "hooks/useLocalStorage.jsx"
import { acceptInvite } from "api/invitation.js"
import { VerifyPhoneNumber } from "components/common/Auth/VerifyPhoneNumber/index.jsx"
import { FirstFormCreatorInformation } from "components/common/Auth/FirstFormCreatorInformation/index.jsx"
import { PhoneNumber } from "components/common/Auth/SendPhoneNumber/index.jsx"

export default function SignUpInvite({ back }) {
  const inviteObject = JSON.parse(localStorage.getItem("ranttInvitaion"))
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const methods = useForm({ defaultValues: { userEmail: inviteObject?.email } })

  const { setError, getValues, setValue } = methods
  const { userName, userEmail, userDisplayName, password } = getValues()

  const { signIn, setUser, isAuthenticated } = useUser()
  const [phoneNumber, setPhoneNumber] = useState()
  const [, setUserLocalStorage] = useLocalStorage("user", null)

  const { mutateAsync: createUser, isLoading } = useMutation(
    authAPi.createUser,
    {
      onSuccess: (data) => {
        setUser({ ...data, userName })
        setUserLocalStorage({ ...data, userName })
        signIn(data.validationToken)

        acceptInvite({
          communityId: inviteObject?.communityId,
          invitationVectorValue: inviteObject?.email,
          invitationToken: inviteObject?.inviteCode,
          invitationStatusName: "accepted",
        })
        window.location.href = "/"
      },
    }
  )

  var offset = new Date().getTimezoneOffset()

  const onSubmit = async (data) => {
    const { phoneNumber } = getValues()

    if (step === 3) {
      const combinedData = {
        userCreate: {
          userName: userName,
          userEmail:
            inviteObject?.email?.length > 1 ? inviteObject?.email : userEmail,
          userPhoneNumber: phoneNumber,
          userDisplayName,
          userIsoCode: "en-US",
          userUtcOffset: offset,
        },
        password,
        signupTokenValue: data?.signupTokenValue,
        deviceName: getDeviceName(),
        deviceToken: "",
        deviceType: "web",
      }
      const results = await createUser(combinedData)
      if (results.errors.length >= 1) {
        return setError("signupTokenValue", {
          type: "manual",
          message: "validation token Invalid",
        })
      }
    }
  }

  useEffect(() => {
    setValue("phoneNumber", phoneNumber)
  }, [phoneNumber, setValue])

  useEffect(() => {
    if (
      !inviteObject?.email?.length > 1 ||
      !inviteObject?.inviteCode?.length > 1
    ) {
      window.location.href = "/"
    }
  }, [inviteObject])

  if (isAuthenticated) navigate("/", { replace: true })
  return (
    <MySignUp>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up for Rantt</title>
        <meta
          name="description"
          content="Sign up and join a rantt community."
        />
      </Helmet>
      <BodyImage />
      <div className="form-wrapper">
        <div
          style={{
            height: "10rem",
            width: "100%",
          }}
        >
          {methods.isSubmitting && <Spinner />}
        </div>
        <FormProvider {...methods}>
          {step === 1 && (
            <FirstFormCreatorInformation
              userEmail={inviteObject?.email}
              step={step}
              setStep={setStep}
            />
          )}
          {step === 2 && (
            <PhoneNumber
              step={step}
              setStep={setStep}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          )}
          {step === 3 && (
            <VerifyPhoneNumber
              step={step}
              phoneNumber={phoneNumber}
              setStep={setStep}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          )}
        </FormProvider>

        <SignInInfo
          toSignIn
          style={{
            width: "100%",
          }}
        />
      </div>
    </MySignUp>
  )
}
