import { useFormContext } from "react-hook-form"
import { useMutation } from "react-query"

import { Button, Input, Text } from "components/common/Kit"
import * as authAPi from "api/auth"
import { useState } from "react"
import { EMAIL_REGEX_PATTERN } from "utils"
import { FIELD_ERROR } from "constant/formText"
import HideEye from "components/icons/EyeHide"
import ViewEye from "components/icons/EyeView.jsx"
import { useLocation } from "react-router-dom"
import { cx } from "utils/cx"

const useFormValues = () => useFormContext()

export const FirstFormCreatorInformation = ({
  setStep,
  userEmail,
  creator = false,
}) => {
  const location = useLocation()

  const [isShownPassword, toggleIsPassword] = useState(false)

  const { mutateAsync: verifyUserInfo, isLoading: isloadingVerifyUserInfo } =
    useMutation(authAPi.validateUserInfo)

  const emailFromState = decodeURIComponent(
    location?.search?.split("=")[1]?.replace("%40", "@")
  )

  const {
    register,
    getValues,
    setError,
    formState: { errors },
    handleSubmit,
  } = useFormValues({
    defaultValues: {
      userEmail: userEmail ? userEmail : emailFromState,
      userName: undefined,
    },
  })

  const onSubmit = async (data) => {
    const userEmail = getValues("userEmail")
    const userName = getValues("userName")

    const body = await verifyUserInfo({ userName, userEmail })
    if (body.userNameValid && body.userEmailValid)
      return setStep((step) => step + 1)
    if (!body.userNameValid) {
      setError("userName", {
        type: "manual",
        message: "Username is already taken! or invalid",
      })
    }
    if (!body.userEmailValid) {
      setError("userEmail", {
        type: "manual",
        message: "Email is already taken! or invalid!",
      })
    }
  }

  console.log(errors)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3
        className={cx(
          "form-title ",
          creator ? "text-[20px]  font-semibold leading-[33px]" : ""
        )}
      >
        {creator
          ? "Create your account to begin the community creation process."
          : "Create Your account"}
      </h3>
      <label htmlFor="userDisplayName">Display name</label>
      <Input
        {...register("userDisplayName", {
          required: FIELD_ERROR,
        })}
        error={errors.userDisplayName}
      />
      {errors.userDisplayName && <Text>{errors.userDisplayName.message}</Text>}
      <label htmlFor="userName">Username</label>
      <Input
        {...register("userName", {
          required: {
            value: true,
            message: FIELD_ERROR,
          },
        })}
        error={errors.userName}
      />
      {errors.userName && <Text>{errors.userName.message} </Text>}
      <label htmlFor="userEmail">Email</label>
      <Input
        {...register("userEmail", {
          required: {
            value: true,
            message: FIELD_ERROR,
          },
          pattern: {
            value: EMAIL_REGEX_PATTERN,
            message: "Email format is invalid",
          },
        })}
        disabled={location?.search?.length >= 1 || userEmail?.length > 0}
        error={errors.userEmail}
      />
      {errors?.userEmail && <Text>{errors?.userEmail.message} </Text>}
      <label htmlFor="password" type="password">
        Password
      </label>
      <div className="password_wrapper">
        <input
          type={isShownPassword ? "text" : "password"}
          error={errors.password}
          {...register("password", {
            required: { value: true, message: FIELD_ERROR },
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters ",
            },
          })}
        />
        <div onClick={() => toggleIsPassword(!isShownPassword)}>
          {isShownPassword ? <HideEye /> : <ViewEye />}
        </div>
      </div>

      {errors.password && <Text>{errors.password.message} </Text>}
      <Button
        style={{
          marginTop: "2rem",
        }}
        isLoading={isloadingVerifyUserInfo}
      >
        Next
      </Button>
    </form>
  )
}
