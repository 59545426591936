import * as React from "react"

export function Facebook(props) {
  return (
    <svg
      width={25}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.146 12.073C24.146 5.403 18.742 0 12.073 0 5.403 0 0 5.404 0 12.073 0 18.099 4.415 23.093 10.187 24v-8.437H7.12v-3.49h3.067v-2.66c0-3.025 1.8-4.697 4.56-4.697 1.32 0 2.702.236 2.702.236v2.97h-1.522c-1.5 0-1.968.93-1.968 1.885v2.266h3.349l-.536 3.49H13.96V24c5.772-.907 10.187-5.901 10.187-11.927z"
        fill="#828282"
      />
    </svg>
  )
}
