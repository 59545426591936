import { LeftArrow } from "components/icons"
import { TabHeaderWrapper } from "../RightSide/rightSide.style"

export const TabHeader = ({ setActiveTab, onClick, title }) => {
  return (
    <TabHeaderWrapper>
      <LeftArrow onClick={() => (onClick ? onClick() : setActiveTab(null))} />{" "}
      <h3>{title}</h3>
    </TabHeaderWrapper>
  )
}
