import { devices } from "constant"
import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  *:focus {
    outline: 0;
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box
  }
  body {
    font-size: 1.7rem;
    font-family: 'Figtree', sans-serif;
    
    a {
      text-decoration: none;
      color : inherit;
    }
  }

  button {
    border:none
  }
  fieldset{
    border:none
  }
  input, button, textarea, select {
  font: inherit;
}
.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.empty_state_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    h1 {
      margin: 3rem 0;
      font-size: 18px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }
  }
.error_message{
  display:block;
  margin-top:8px;
  margin-bottom:4px;
  color:red;
  font-size:12px
}
.setting_mobile_header{
  display: none;
}
@media ${devices.tablet} {
  .setting_mobile_header{
    display: block;
    color:${({ theme }) => theme.secondaryColor};
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    padding:2rem 2rem;
    padding-bottom: 0;
    cursor: pointer;
    }
}



.password_wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0;
  padding-right: 1.5rem;
  background-color: ${({ theme }) => theme.backgroundGrey};
  text-align: center;

  border-radius: 1.5rem;
  width: 100%;
  height: 5rem;
  margin-bottom: 1.5rem;
  margin-top: 7px;
  ::-webkit-input-placeholder {
    text-align: center;
  }

  ${({ error, theme }) => error && `border: 1px solid ${theme.primaryColor}`}

  input {
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundGrey};
    outline: none;
    border: none;
    margin: 0 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  svg {
    width: 24px;
  }
}
.success_message {
  color: green;
  display: block;
  margin: 3px 0;
  font-size: 15px;
}
`
