import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { updateUserPassword } from "api/users"
import { Button } from "components/common/Kit"
import React, { useState } from "react"

import { TabHeader } from "../TabHeader"
import { RightSideContainer } from "../updateAccount.style"

export const UpdatePassword = ({ setActiveTab }) => {
  const FIELD_ERROR = "This field is required"
  const [error, setCustomError] = useState()
  const [success, setSuccess] = useState()
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(FIELD_ERROR),
    password: Yup.string()
      .required(FIELD_ERROR)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)

  const { mutateAsync: updateUserPasswordFn, isLoading } =
    useMutation(updateUserPassword)

  const onSubmit = (data) => {
    setCustomError(null)
    updateUserPasswordFn(
      {
        oldPassword: data?.currentPassword,
        newPassword: data?.password,
      },
      {
        onSuccess: (body) => {
          if (body.success) {
            setSuccess("Password updated successfully")
          }
          if (body?.errors?.length >= 1 || !body?.success)
            if (body?.errors[0] === "InvalidPasswordErr") {
              setCustomError("Invalid Password")
            }
          if (body?.errors[0] === "InvalidPasswordFormatErr") {
            setCustomError("Invalid Password Format")
          }
        },
      }
    )
  }
  return (
    <RightSideContainer>
      <TabHeader title="Update password" setActiveTab={setActiveTab} />
      <form className="inputs_wrapper" onSubmit={handleSubmit(onSubmit)}>
        <span className="title">Update email address</span>
        {success && <span className="success_message">{success}</span>}
        <fieldset className="custom_values">
          <label htmlFor="currentPassword">Current password:</label>
          <input name="currentPassword" {...register("currentPassword")} />
          <span className="error_message">
            {errors.currentPassword
              ? errors.currentPassword?.message
              : error === "InvalidPasswordErr"
              ? "Wrong password"
              : null}
          </span>
        </fieldset>
        <fieldset className="custom_values">
          <label htmlFor="password">New password:</label>
          <input name="password" {...register("password")} />
          <span className="error_message">
            {errors.password ? errors.password?.message : null}
          </span>
        </fieldset>
        <fieldset className="custom_values">
          <label htmlFor="confirmPassword">Confirm password:</label>
          <input name="confirmPassword" {...register("confirmPassword")} />
          <span className="error_message">
            {errors.confirmPassword ? errors.confirmPassword?.message : null}
          </span>
          {error && <span className="error_message">{error}</span>}
        </fieldset>
        <Button isLoading={isLoading}>Save changes</Button>
      </form>
    </RightSideContainer>
  )
}
