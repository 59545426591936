import { getCommunityListUsers } from "api/communities"
import { sendInvite } from "api/invitation"

import { useUser } from "context/user"
import { useState } from "react"
import { useInfiniteQuery, useMutation } from "react-query"

export const useMemberManagement = ({ emails: email }) => {
  const { currentCommunity, user } = useUser()

  const emailsInvitations =
    email?.split(",").length === 1
      ? [
          {
            invitationVectorValue: email,
            invitationVector: {
              name: "email",
            },
          },
        ]
      : email?.split(",")?.map((email) => ({
          invitationVectorValue: email?.replace(",", "").trim(),
          invitationVector: {
            name: "email",
          },
        }))

  const [users, setUsers] = useState([])
  const [filteredList, setFilteredList] = useState(users)
  const [error, setError] = useState([])
  const [successMessage, setSuccessMessage] = useState()

  console.log("email", email)
  const {
    data: listUsers,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["communityListUsers", currentCommunity?.id],
    ({ pageParam = 0, ...props }) => {
      return getCommunityListUsers({
        offset: pageParam,
        communityId: currentCommunity?.id,
      })
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.communityUsers?.length === 50) {
          const nextPage = allPages?.length * 50
          return nextPage
        }

        return null
      },

      onSuccess: ({ ...communityUsers }) => {
        const results = communityUsers?.pages
          ?.map((ite) => ite.communityUsers)
          .flat()

        setUsers(results)
        setFilteredList(
          results?.filter((i) => i?.userSummary?.userId !== user?.id)
        )
      },

      enabled: !!currentCommunity?.id && currentCommunity?.id !== "0",
    }
  )

  const { mutate: sendInviteFn, isLoading: isLoadingSendInvite } = useMutation(
    sendInvite,
    {
      onSuccess: ({ errors, ...data }) => {
        setError([])
        if (errors.length === 0) {
          setSuccessMessage(
            emailsInvitations?.length > 1
              ? "Invitations sent"
              : "Invitation sent"
          )
        }
        const emailsNotValidd = errors?.filter(
          (item) => item.messageKey === "InvalidEmailErr"
        )
        const emailsDuplicated = errors?.filter(
          (item) => item.messageKey === "DuplicateInvitationErr"
        )

        for (let item of emailsNotValidd) {
          if (item) {
            setError((prev) =>
              prev.concat({
                error: `${item.invitationVectorValue} emails not valid`,
              })
            )
          }
        }

        for (let item of emailsDuplicated) {
          if (item) {
            setError((prev) =>
              prev.concat({
                error: `${item.invitationVectorValue} emails is already invited`,
              })
            )
          }
        }
      },
      onError: (errors) => {
        setError("Something went wrong")
      },
    }
  )

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value

    // Create copy of item list
    let filteredList = [...users]
    // Include all elements which includes the search query
    filteredList = filteredList.filter((user) =>
      user?.userSummary?.userDisplayName
        .toLowerCase()
        .includes(query.toLowerCase())
    )
    // Trigger render with updated values
    setFilteredList(filteredList)
  }

  const SubmitInvites = () => {
    if (email && email.length >= 1) {
      sendInviteFn({
        communityId: currentCommunity?.id,
        invitations: emailsInvitations,
        isoCode: "en-US",
      })
    }
  }

  return {
    successMessage,
    filterBySearch,
    SubmitInvites,
    error,
    filteredList,
    setError,
    setSuccessMessage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    listUsers,
    isLoadingSendInvite,
  }
}

// const { data: usersFounded } = useFindUsers({
//   params: {
//     email,
//     communityId: currentCommunity?.id,
//   },
//   config: {
//     getNextPageParam: (lastPage, allPages) => {
//       console.log(
//         "lastPage?.length",
//         allPages,
//         lastPage?.userSummaries?.length
//       )
//       if (lastPage?.userSummaries?.length >= 4) {
//         const nextPage = allPages?.length * 4
//         return nextPage
//       }

//       return null
//     },

//     onSuccess: ({ communityUsers }) => {
//       setUsers(communityUsers)
//       setFilteredList(
//         communityUsers?.filter((i) => i?.userSummary?.userId !== user?.id)
//       )
//     },

//     refetchOnMount: false,
//     enabled: !!currentCommunity?.id && currentCommunity?.id !== "0",
//   },
// })
