import ranttLogo from "assets/pictures/Rantt-logo.png"
import { Link } from "react-router-dom"

export const Header = () => {
  return (
    <div className="p-6 flex justify-between md:px-36 border-b">
      <Link to="/">
        <img src={ranttLogo} alt="Rantt Logo" className="logo h-14" />
      </Link>
      <a
        href="#subscribe"
        className="bg-[#121212] scroll-smooth transition-all text-white rounded-md py-2 px-6 font-medium text-2xl flex justify-center items-center"
      >
        Subscribe
      </a>
    </div>
  )
}
