import React, { useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useForm } from "react-hook-form"

import {
  CommunitySettingContainer,
  CommunitySettingPageContainer,
  ImageUploadContainer,
} from "./communitySettings.style"

import { LightCamera, UploadLogo as UploadLogoIcon } from "components/icons"
import { MiddleSpinner } from "components/common"
import SettingPageWrapper from "components/common/SettingWrapper"
import { useUser } from "context/user"
import {
  getSpecificCommunityByCreator,
  updateCommunityLogo,
} from "api/communities"
import { Button } from "components/common/Kit"
import { updateCommunity } from "api/communities"
import { createFormData, getDeviceName } from "utils"
import { FIELD_ERROR } from "constant/formText"

const CreateCommunity = () => {
  const [uploadedImage, setUploadedImage] = useState(null)
  const { user } = useUser()
  const { setCurrentCommunity } = useUser()
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm()
  const inputFileRef = useRef()
  const picRef = useRef()

  const { data: Community, isLoading } = useQuery(
    ["communityByCreator", user?.id],
    () =>
      getSpecificCommunityByCreator({
        creatorUserName: user?.userName,
        getSubscriptions: true,
      }),
    {
      onSuccess: (data) => {
        setCurrentCommunity(data?.community)
      },
    }
  )

  const { mutateAsync: updateCommunityFn, isLoading: updateCommunityLoading } =
    useMutation(updateCommunity, {
      onSuccess: (data) => {
        if (data?.errors?.find((err) => err === "CommunityNameExistsErr")) {
          setError("communityName", {
            message: "Community name already exists",
          })
        }
        if (data?.errors?.find((err) => err === "CommunityTaglineEmpty")) {
          setError("communityTagline", {
            message: "Community tagline required",
          })
        }
        if (data?.errors?.length === 0) {
          if (data) {
            updateAvatarFxn({ communityId: data?.communityId })
          }

          if (data?.connectUrl?.length > 1) {
            window.location.href = data?.connectUrl
          } else {
            // window.location.href = "/"
          }
        }
      },
      onError: (error) => {
        if (error?.data?.details) {
          setError("about", {
            message: "something went wrong",
          })
        }
      },
    })
  const {
    mutateAsync: updateCommunityLogoFn,
    isLoading: loadingCommunityLogo,
  } = useMutation(updateCommunityLogo)

  const community = Community?.community

  const updateAvatarFxn = async ({ communityId }) => {
    const token = JSON.parse(localStorage.getItem("auth")) || ""
    const userAuthObj = {
      userAuthentication: {
        userName: JSON.parse(localStorage.getItem("user"))?.userName
          ? JSON.parse(localStorage.getItem("user"))?.userName
          : "",
        deviceName: getDeviceName(),
        validationToken: token ? token.access : "",
      },
    }
    if (picRef.current && communityId !== "0") {
      const formData = createFormData({
        file: picRef.current,
        communityId: communityId,
        userAuthentication: JSON.stringify(userAuthObj),
      })
      return updateCommunityLogoFn(formData)
    }
  }

  const onSubmit = async (data) => {
    await updateCommunityFn({
      communitySave: {
        countryName: "United States",
        defaultCurrencyCode: "usd",
        isoCode: "en-US",
        country_id: 1,
        countryId: 1,
        id: community?.id,
        name:
          data.communityName?.length > 1 ? data.communityName : community.name,
        description:
          data.about?.length > 1 ? data.about : community?.description,
        link: data?.communityName?.toLowerCase()?.split(" ").join("-"),
        tagline:
          community?.tagline.length > 1
            ? community?.tagline
            : data.communityTagline,
      },

      returnUrl: "https://rantt.app/settings/subscriptions",
      refreshUrl: "https://rantt.app/settings/subscriptions",
      merchantTypeName: "business",
      country_id: 1,
      countryId: 1,
      website: "",
    })
  }

  const updateImage = (e) => {
    if (e.target.files[0]) {
      const image = URL.createObjectURL(e.target.files[0])
      picRef.current = e.target.files[0]
      setUploadedImage(image)
    }
  }

  return (
    <CommunitySettingPageContainer>
      <SettingPageWrapper pageTitle="Community settings">
        {isLoading ? (
          <div className="spinner">
            <MiddleSpinner />
          </div>
        ) : (
          <CommunitySettingContainer onSubmit={handleSubmit(onSubmit)}>
            {/* Upload Community logo 🌃 */}
            <div className="section_wrapper">
              <span className="title">Upload your community logo</span>
              <ImageUploadContainer
                className={uploadedImage ? "uploadedImage" : ""}
                onClick={() => inputFileRef.current?.click()}
              >
                {uploadedImage || Community?.community?.logo?.uri ? (
                  <div>
                    <img
                      src={
                        uploadedImage
                          ? uploadedImage
                          : Community?.community?.logo?.uri
                      }
                      alt="room"
                    />
                    <LightCamera />
                  </div>
                ) : (
                  <>
                    <UploadLogoIcon />
                  </>
                )}
                <input
                  id="files"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={inputFileRef}
                  onChange={(e) => updateImage(e)}
                />
              </ImageUploadContainer>
            </div>
            {/* Community name */}
            <div className="section_wrapper">
              <span className="title">Community name</span>
              <input
                type="text "
                defaultValue={community?.name}
                {...register("communityName", {
                  required: {
                    value: true,
                    message: FIELD_ERROR,
                  },
                })}
              />
              <ErrorMessage errors={errors} name="communityName" />
            </div>
            {/* Community tagline */}
            <div className="section_wrapper">
              <span className="title">Community tagline</span>
              <textarea
                className="community_tagname_input"
                type="text "
                defaultValue={community?.tagline}
                {...register("communityTagline", {})}
              />
              <ErrorMessage errors={errors} name="communityTagline" />
            </div>
            {/* About Community  */}
            <div className="section_wrapper">
              <span className="title">About your community</span>
              <p>
                Tell members about yourself, what you aim to achieve with your
                Rantt community, and what members can expect by joining.
              </p>
              <textarea
                className="about_community_input"
                type="text"
                defaultValue={community?.description}
                {...register("about")}
              />
              <ErrorMessage errors={errors} name="about" />
            </div>
            {/*Community link */}
            <div className="section_wrapper">
              <span className="title">Community link</span>
              <p className="link_message">
                Your community link will generate based on your community name.
                This link can’t be changed after its created.
              </p>
            </div>

            <Button isLoading={updateCommunityLoading || loadingCommunityLogo}>
              Create Community{" "}
            </Button>
            <p className="text-lg mt-5">
              By clicking “Create Community,” you will be redirected to our
              payment processing partner Stripe to set up your Stripe Connect
              account so you can accept subscriptions for your community.
            </p>
          </CommunitySettingContainer>
        )}
      </SettingPageWrapper>
    </CommunitySettingPageContainer>
  )
}

export default CreateCommunity

const ErrorMessage = ({ errors, name }) => {
  return (
    <>
      {errors[name] ? (
        <p className="text-red-500 mb-2">{errors[name]?.message}</p>
      ) : null}
    </>
  )
}
