import { Outlet } from "react-router-dom"
// import { Helmet } from "react-helmet-async";

import Footer from "components/common/Footer"
import Header from "components/common/onBoardingHeader"

export default function AuthLayout() {
  return (
    <>
      {/* <Helmet>
        <title>Rantt | auth</title>
      </Helmet> */}
      <Header />
      <main
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className=" min-h-[calc(100vh-185px)] mb-auto p-4"
      >
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
