import * as React from "react";

export function UploadLogo(props) {
  return (
    <svg
      width={91}
      height={88}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.186 28.469c.904-1.583 1.888-2.641 3.348-3.2 2.675-1.025 12.257-1.025 14.93 0 1.46.559 2.443 1.617 3.349 3.2l1.017 1.878.064.098.086.114c.464.486 1.24.78 1.752.78 4.842 0 8.768 3.78 8.768 8.441v12.556c0 5.946-5.008 10.769-11.182 10.769H32.681c-6.176 0-11.182-4.822-11.182-10.769V39.78c0-4.661 3.926-8.44 8.768-8.44.51 0 1.287-.295 1.752-.781a.541.541 0 00.036-.044l.116-.17 1.014-1.876zm18.67 1.567c-.562-.983-1.07-1.53-1.64-1.748l-.269-.086c-2.315-.63-10.37-.602-12.165.086-.57.218-1.077.764-1.639 1.747l-.866 1.613-.213.371a4.516 4.516 0 01-.56.739c-1.139 1.193-2.824 1.831-4.236 1.831l-.369.012c-2.806.183-5.024 2.433-5.024 5.18v12.555c0 4.152 3.496 7.519 7.807 7.519h22.636c4.31 0 7.807-3.368 7.807-7.519V39.78c0-2.866-2.415-5.19-5.393-5.19l-.392-.017c-1.324-.105-2.812-.731-3.846-1.817a4.506 4.506 0 01-.648-.889l-.261-.471-.729-1.36z"
        fill="#130F26"
      />
      <path
        d="M56.385 36.417c1.242 0 2.25.97 2.25 2.167 0 1.11-.87 2.027-1.988 2.152l-.262.014c-1.263 0-2.27-.97-2.27-2.166 0-1.112.868-2.027 1.987-2.152l.283-.015z"
        fill="#130F26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.16 46.444c0-4.702 3.956-8.512 8.84-8.512 4.882 0 8.84 3.81 8.84 8.512 0 4.703-3.958 8.513-8.84 8.513-4.884 0-8.84-3.81-8.84-8.513zm14.305 0c0-2.907-2.447-5.262-5.466-5.262s-5.465 2.355-5.465 5.262c0 2.908 2.446 5.263 5.465 5.263 3.02 0 5.466-2.355 5.466-5.263z"
        fill="#130F26"
      />
      <path
        clipRule="evenodd"
        d="M50.84 26.778c2.272.871 2.968 3.905 3.897 4.88.93.975 2.259 1.306 2.995 1.306 3.91 0 7.08 3.053 7.08 6.816v12.556c0 5.049-4.252 9.144-9.494 9.144H32.681c-5.244 0-9.495-4.095-9.495-9.144V39.78c0-3.763 3.17-6.816 7.081-6.816.734 0 2.063-.331 2.995-1.306.93-.975 1.622-4.009 3.895-4.88 2.274-.87 11.41-.87 13.682 0z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.365 38.583h.02"
        stroke="#130F26"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M51.152 46.444c0-3.804-3.202-6.887-7.153-6.887-3.95 0-7.152 3.083-7.152 6.887 0 3.805 3.201 6.888 7.152 6.888s7.153-3.083 7.153-6.888z"
        stroke="#130F26"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={44}
        cy={44}
        r={43}
        stroke="#000"
        strokeWidth={2}
        strokeDasharray="6 6"
      />
      <circle
        cx={78}
        cy={13}
        r={13}
        fill="url(#prefix__paint0_linear_2559_6026)"
      />
      <path
        d="M78.99 13.79h5.64v-1.8h-5.64V5.9h-1.98v6.09h-5.64v1.8h5.64v6.3h1.98v-6.3z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_2559_6026"
          x1={69}
          y1={20}
          x2={86}
          y2={3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C94889" />
          <stop offset={1} stopColor="#E04646" />
        </linearGradient>
      </defs>
    </svg>
  );
}
