import * as Sentry from "@sentry/react"
import { Button } from "components/common/Kit"

const ErrorPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center px-6 pt-4 pb-4 text-center">
        <span className="mt-5 mb-6 text-2xl font-semibold">
          <p>Something went wrong</p>
        </span>
        <Button onClick={() => (document.location.href = "/")}>
          <p>Return to homepage</p>
        </Button>
      </div>
    </div>
  )
}

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>{children}</Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
