/* eslint-disable no-unreachable */
import React from "react"
import { WelcomePageContainer, WelcomePageWrapper } from "./welcomePage.style"
import appstore from "assets/pictures/appstore.png"
import googlePlayStore from "assets/pictures/google-play-badge.png"
import { Helmet } from "react-helmet-async"
import { useUser } from "context/user"

const Welcome = () => {
  const { user } = useUser()

  const gettingStartedList = [
    "Download the app",
    "Sign in with your account",
    "Enter your community",
  ]

  return (
    <WelcomePageContainer>
      <Helmet>
        <title>Welcome | Rantt</title>
      </Helmet>
      <WelcomePageWrapper>
        <div className="Welcome_page_banner">
          <h1> Welcome to Rantt, {user?.displayName}</h1>
          <p>The best community experience online.</p>
        </div>
        <div className="welcome_page_info">
          <div className="getting_started">
            <h3>Getting started</h3>
            {gettingStartedList.map((item, index) => (
              <div className="item">
                <span className="num">{index + 1}</span>{" "}
                <h4 className="text-3xl ">{item}</h4>
              </div>
            ))}
            <div className=" download_links w-[370px]">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/us/app/rantt/id1531760063"
              >
                <img src={appstore} alt="Download on the App Store" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.rantt&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img alt="Get it on Google Play" src={googlePlayStore} />
              </a>
            </div>
          </div>
          {/* <div className="divider"></div>
          <div className="payment_info_wrapper">
            <h2>Payment</h2>
            <p>
              Your next payment is due on{" "}
              <span className="date">4/21/2022</span> for the amount of{" "}
              <span className="price">10.00</span>{" "}
            </p>
          </div> */}
        </div>
      </WelcomePageWrapper>
    </WelcomePageContainer>
  )
}

export default Welcome
