const { useEffect, useState } = require("react");

export const useMedia = (query) => {
  const [matches, setMatches] = useState(false);
  const media = window.matchMedia(query);

  useEffect(() => {
    const listeners = () => setMatches(media.matches);
    listeners();
    media.addEventListener("change", listeners);

    return media.removeEventListener("change", listeners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, matches]);

  return matches;
};
