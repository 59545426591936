import { getLastFourDigitsCars } from "api/communities"
import { useFetchCommunityWithId } from "api/community/getCommunityWithId"
import { useQuery } from "react-query"

export const useUpdateCard = ({ selectedCommunity }) => {
  const { Community, accountStId } = useFetchCommunityWithId({
    params: {
      key: selectedCommunity?.communityId,
      communityId: selectedCommunity?.communityId,
      getSubscriptions: true,
    },
    config: { enabled: !!selectedCommunity?.communityId },
  })

  const { data: lastFourDigitsCard } = useQuery(
    "last4digitscard",
    () =>
      getLastFourDigitsCars({
        accountId:
          Community?.community?.communityMerchantAccounts[0]?.accountId,
        customerId: selectedCommunity?.customerId,
      }),
    { enabled: !!Community }
  )

  return {
    Community,
    lastFourDigitsCard,
    accountStId,
  }
}
