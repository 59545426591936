import * as React from "react"

export function Mastodon(props) {
  return (
    <svg
      width={23}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.388 7.88c0-5.207-3.412-6.733-3.412-6.733-3.349-1.537-12.242-1.521-15.56 0 0 0-3.412 1.526-3.412 6.733 0 6.197-.354 13.894 5.658 15.485 2.17.573 4.034.696 5.534.61 2.722-.15 4.249-.97 4.249-.97l-.091-1.976s-1.945.611-4.13.541c-2.165-.075-4.447-.235-4.802-2.892a5.492 5.492 0 01-.048-.745c4.587 1.12 8.498.488 9.575.36 3.005-.36 5.624-2.213 5.957-3.905.525-2.668.482-6.508.482-6.508zm-4.023 6.706h-2.498V8.469c0-2.662-3.428-2.764-3.428.37v3.347H9.957V8.838c0-3.133-3.428-3.032-3.428-.37v6.117H4.026c0-6.54-.278-7.922.987-9.373 1.387-1.548 4.275-1.65 5.561.327l.621 1.044.622-1.044C13.108 3.55 16 3.675 17.378 5.212c1.27 1.462.986 2.839.986 9.373z"
        fill="#828282"
      />
    </svg>
  )
}
