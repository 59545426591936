import { Link } from "react-router-dom"
import styled from "styled-components"
import * as paths from "paths"
import { CommunityAvatar } from "../CommunityAvatar"
import { formatDate } from "utils/convertDate"

const CommunitySubscription = ({ community }) => {
  return (
    <CommunityWrapper>
      {community?.logo?.uri ? (
        <img src={community?.logo?.uri} alt="community name" />
      ) : (
        <CommunityAvatar title={community?.communityName} />
      )}
      <div className="flex flex-col ml-2 mr-auto community_info">
        <span className="community_name">{community?.communityName}</span>
        <span className="renews_date">
          Renews: {formatDate(community?.renews?.seconds)}
        </span>
      </div>

      <Link
        className="edit_button"
        to={`${paths.profileManegeSubscription}/${community?.communityId}`}
      >
        edit
      </Link>
    </CommunityWrapper>
  )
}

export const CommunityWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .community_info {
    .community_name {
      color: black;
    }
    span {
      color: #828282;
    }
  }
  img {
    height: 45px;
    width: 45px;
    border-radius: 15px;
  }
  .edit_button {
    text-align: center;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 6px;
    padding: 4px 6px;
    min-width: 66px;
    font-weight: 600;
  }
`
export default CommunitySubscription
