import * as React from "react"

import { Link } from "react-router-dom"
import { MyHeader } from "./header.styles"

import RanttBlackGradiantLogo from "components/icons/RanttBlackGradiantLogo"
import { useMedia } from "hooks/useMedia"
import { devices } from "constant"

export default function Header() {
  const isMobile = useMedia(`${devices.mobileXl}`)

  return (
    <>
      <MyHeader>
        <Link to="/">
          <RanttBlackGradiantLogo width={isMobile ? 190 : 250} />
        </Link>
      </MyHeader>
    </>
  )
}

// import React, { useState } from "react"

// import { useQuery } from "react-query"
// import { Link, useNavigate } from "react-router-dom"

// import { DropDownContainer, MobileBurgerMenu, MyHeader } from "./header.styles"
// import ranttLogo from "assets/pictures/rantt-body.png"
// import useClickOutside from "hooks/useClickOutside"
// import { useRightPanel } from "context/rightPanel"
// import { SideBar } from ".."
// import { adminDropDownList, devices } from "constant"
// import { useMedia } from "hooks/useMedia"
// import * as paths from "paths"

// import roomLogoPlaceholder1 from "assets/pictures/roomLogoPlaceholder1.png"
// import { useModal } from "context/modal"
// import { useUser } from "context/user"
// import { useSettings } from "constant/DropDownList"
// import { Burger } from "components/icons/Burger"

// export default function Header() {
//   const { user } = useUser()
//   const { ref, isVisible, setIsVisible } = useClickOutside(false)
//   const [sideBar, setSideBar] = useState(false)
//   const { switchTab } = useRightPanel()
//   const isMobile = useMedia(`${devices.mobileXl}`)
//   const navigate = useNavigate()

//   return (
//     <MyHeader>
//       <MobileBurgerMenu>
//         <Burger onClick={() => setSideBar(!sideBar)} />
//         <div className="side_bar_wrapper">
//           {sideBar && <SideBar closeSideBar={() => setSideBar(!sideBar)} />}
//         </div>
//       </MobileBurgerMenu>
//       <Link to={paths.welcomePage}>
//         <img src={ranttLogo} alt="Rantt Logo" className="logo" />
//       </Link>
//       {/* <div className="search-wrapper">
//         <button type="submit" className="searchButton">
//           <SearchIcon className="search-icon" />
//         </button>
//         <input
//           type="text"
//           className="searchTerm"
//           placeholder="What are you looking for?"
//         />
//       </div> */}
//       <div className="burger-wrapper">
//         <>
//           {user?.avatar?.uri.length >= 1 ? (
//             <img
//               src={user?.avatar.uri}
//               alt={user?.displayName}
//               className="profile-img"
//               onClick={() =>
//                 isMobile ? navigate(paths.Profile) : switchTab("profile")
//               }
//             />
//           ) : (
//             <img
//               src={roomLogoPlaceholder1}
//               className="profile-img"
//               alt=""
//               onClick={() =>
//                 isMobile ? navigate(paths.Profile) : switchTab("profile")
//               }
//             />
//           )}
//         </>{" "}
//         <div className="menu-wrapper">
//           <Burger onClick={() => setIsVisible(!isVisible)} />
//           <DropDown
//             dropDownOpen={isVisible}
//             ref={ref}
//             closeButton={() => setIsVisible(!setIsVisible)}
//           />
//         </div>
//       </div>
//     </MyHeader>
//   )
// }

// const DropDown = React.forwardRef(({ dropDownOpen, closeButton }, ref) => {
//   const modal = useModal()

//   const { currentCommunity } = useUser()

//   const handleSignOut = () => {
//     closeButton()
//     modal.open("logOutModal")
//   }

//   const settings = currentCommunity?.userRoleName?.includes("creator")
//     ? adminDropDownList
//     : useSettings

//   return (
//     <>
//       {dropDownOpen && (
//         <DropDownContainer ref={ref}>
//           <div>
//             <ul className="ml-4">
//               {settings?.map((li, key) => (
//                 <li key={key} onClick={closeButton}>
//                   <Link to={li?.value}>{li?.title}</Link>
//                 </li>
//               ))}
//             </ul>
//             <button onClick={handleSignOut}>sign Out</button>
//           </div>
//         </DropDownContainer>
//       )}
//     </>
//   )
// })
