import { RightSideContainer } from "components/common/shared/RightSideWrapper/rightSide.style"
import { RightArrow } from "components/icons"
import { useState } from "react"
import { Privacy } from "../Privacy"
import { UpdatePassword } from "../UpdatePassword"
import { UpdatePhoneNumber } from "../UpdatePhoneNumber"
import { RightSideWrapper, TabHeaderWrapper } from "./rightSide.style"
import { UpdateEmail } from "../UpdateEmail"
import { privacyPolicy, TermsOfService } from "constant"
import { BackArrow } from "components/icons/backArrow"

export const AccountInfoRightSide = ({ show, backFn }) => {
  const [activeTab, setActiveTab] = useState()
  return (
    <RightSideContainer show={show}>
      <RightSideWrapper>
        {!activeTab && (
          <>
            <TabHeaderWrapper className="header">
              <BackArrow onClick={backFn} /> <h1>Account settings</h1>
            </TabHeaderWrapper>
            <ul>
              {optionsList.map((option, index) => (
                <li
                  key={option.title + index}
                  onClick={() => setActiveTab(option.tab)}
                >
                  {option.title} <RightArrow />
                </li>
              ))}
              <li className="underline">
                <a href={TermsOfService} target="_blank" rel="noreferrer">
                  Terms of Service{" "}
                </a>
              </li>
              <li className="underline">
                <a href={privacyPolicy} target="_blank" rel="noreferrer">
                  Privacy policy
                </a>
              </li>
            </ul>
          </>
        )}
        {switchBetweenTabs(activeTab, setActiveTab)}
      </RightSideWrapper>
    </RightSideContainer>
  )
}

const switchBetweenTabs = (activeTab, setActiveTab) => {
  if (activeTab) {
    switch (activeTab) {
      case "email":
        return <UpdateEmail setActiveTab={setActiveTab} />
      case "password":
        return <UpdatePassword setActiveTab={setActiveTab} />
      case "phoneNumber":
        return <UpdatePhoneNumber setActiveTab={setActiveTab} />

      case "Privacy":
        return <Privacy setActiveTab={setActiveTab} />
      default:
        break
    }
  }
}

const optionsList = [
  { title: "Update email address ", tab: "email" },
  { title: "Update password", tab: "password" },
  { title: "Update phone number", tab: "phoneNumber" },
]
