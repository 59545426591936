import styled from "styled-components";

export const MyForgotPassword = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  form {
    width: 35rem;

    .form-title {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      .left-arrow {
        margin-left: -1rem;
        margin-right: 3rem;
        cursor: pointer;
      }
    }

    .or-separator {
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
    }
    input,
    .instruction {
      margin-bottom: 2rem;
    }
    input {
      margin-top: 0.7rem;
      margin-bottom: 2rem;
      text-align: start;
      padding-left: 1.6rem;

      ::-webkit-input-placeholder {
        text-align: start;
      }
    }

    .request-text {
      text-align: center;
      margin-bottom: 0.5rem;
      span {
        background: ${({ theme }) => theme.linearGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
  }

  .new-user {
    font-weight: 500;
    text-align: center;
    margin-top: 3rem;

    a {
      background: ${({ theme }) => theme.linearGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
