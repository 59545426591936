import styled from "styled-components";

export const MySignOutModal = styled.div`
  height: 20vh;
  padding: 1rem;
  .modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .sign-out-container {
    text-align: center;
    button {
      width: 60%;
      cursor: pointer;
    }
    .first-signOut {
      margin: 2rem 0;
    }
  }
`;
