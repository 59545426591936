import { useFormCommunitySettings } from ".."

export const CommunityName = ({ communityName }) => {
  const { register } = useFormCommunitySettings()
  return (
    <div className="section_wrapper">
      <span className="title">Community name</span>
      <input
        type="text "
        defaultValue={communityName}
        {...register("communityName")}
      />
    </div>
  )
}
