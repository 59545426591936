import { useState } from "react"

export const CommunityLink = ({ communityLink }) => {
  const [isTextCopied, setCopied] = useState(null)
  const link = `rantt.app/${communityLink}`

  return (
    <div className="section_wrapper">
      <span className="title">Community link</span>
      <p>This is the link to your Rantt Community’s sign up page.</p>
      <div className="community_link">
        <span className="">{link}</span>
        <button
          type="button"
          onClick={() =>
            navigator.clipboard.writeText("https://" + link).then(
              () => {
                setCopied(true)
              },
              (err) => {
                console.error("Async: Could not copy text: ", err)
              }
            )
          }
        >
          {isTextCopied ? "Copied" : "Copy"}
        </button>
      </div>
    </div>
  )
}
