import { devices } from "constant"
import styled from "styled-components"

export const WelcomePageWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  border: 1px solid #c4c4c4;
  height: auto;
  .Welcome_page_banner {
    padding: 40px;
    color: white;
    background: ${({ theme }) => theme.linearGradient};
    font-style: normal;
    h1 {
      font-weight: 600;
      font-size: 64px;
      line-height: 78px;
    }

    p {
      font-size: 36px;
      margin: 7px 0;
    }
  }
  .welcome_page_info {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px;
    margin-top: 47px;
    .download_links {
      margin-top: 25px;
      display: flex;
      align-items: center;
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-left: 24px;
      }
    }

    .divider {
      display: block;
      width: 1px;
      height: 280px;
      background-color: black;
    }
    .getting_started {
      width: 100%;
      h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
      }
      .item {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 24px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid black;
          border-radius: 60px;
          width: 40px;
          height: 40px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          margin-right: 20px;
        }
        h4 {
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
        }
      }
    }
    .payment_info_wrapper {
      h3 {
        margin-bottom: 25px;
      }

      width: 45%;
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  @media ${devices.mobileXl} {
    width: 97%;
    .Welcome_page_banner {
      padding: 3rem 1.5rem;
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
      }
      p {
        margin-top: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .welcome_page_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 4px 10px;

      .getting_started {
        width: 100%;

        .item {
          h4 {
            font-size: 24px;
          }
        }
      }
      .divider {
        height: 1px;
        width: 100%;
        margin: 10px 0;
      }
      .payment_info_wrapper {
        width: 100%;
        p {
          margin: 10px 0;
        }
      }
    }
  }
`
export const WelcomePageContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`
