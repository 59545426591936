import styled from "styled-components"

export const MySignIn = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;

  form {
    width: 35rem;
    .form-title {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;
      .left-arrow {
        margin-left: -1rem;
        margin-right: 3rem;
        cursor: pointer;
      }
    }
    label {
      display: block;
      margin-top: 1.5rem;
    }

    input {
      margin-top: 0.7rem;
      text-align: start;
      padding-left: 1.6rem;
    }
    .forgot-password {
      margin-bottom: 3rem;
      color: ${({ theme }) => theme.lightGrey};

      &:hover {
        background: ${({ theme }) => theme.linearGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .new-user {
    font-weight: 500;
    text-align: center;
    margin-top: 3rem;

    a {
      background: ${({ theme }) => theme.linearGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`

export const PasswordWrapper = styled.div``
